const buttonStyle = theme => ({
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: theme.colors.common.secondary,
    color: theme.colors.common.primary,
    border: 'none',
    borderRadius: '5px',
    position: 'relative',
    padding: '14px 10px',
    margin: '.3125rem 10px',
    letterSpacing: '0.355556px',
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
    willChange: 'box-shadow, transform',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '& span': {
      textOverflow: 'ellipsis',
      //overflow: 'hidden',
      //margin: '0 5px'
    },
    '& .fab,& .fas,& .far,& .fal, &.material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginTop: '-1em',
      marginBottom: '-1em',
      //fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginRight: 5,
      verticalAlign: 'middle',
      lineHeight: '1.42857143'
    },
    '& svg:nth-child(2)': {
      marginLeft: '10px',
      marginRight: '0px',
    },
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        marginTop: '0px',
        position: 'absolute',
        width: '100%',
        transform: 'none',
        left: '0px',
        top: '0px',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
      },
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`
    },
  },
  primary: {
    color: theme.colors.common.primary,
    backgroundColor: theme.colors.common.secondary,
    border: 'none',
    borderRadius: '5px',
    height: 'min-content',
    '&:hover, &:focus': {
      backgroundColor: theme.colors.common.secondaryHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
    '& a': {
      color: '#FFFFFF',
    },

  },
  danger: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.danger,
    '&:hover,&:focus': {
      backgroundColor: theme.colors.dangerHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  disabled: {
    backgroundColor: theme.colors.borderColor,
    pointerEvents: 'none',
    '& span, & svg': {
      color: theme.colors.disabledtext,
    },
    '&:hover, &:focus': {
      backgroundColor: theme.colors.borderColor,
    },
  },
  small: {
    padding: '7px 9px 6px 9px',
    fontSize: '0.6875rem',
    lineHeight: '1.5',
    borderRadius: '0.2rem',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  medium: {
    '& svg': {
      width: '24px',
      height: '24px',
    }
  },
  large: {
    fontSize: '32px',
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  hidden: {
    visibility: 'hidden'
  },
  round: {
    borderRadius: '30px',
  },
  block: {
    width: '100% !important',
  },
  fullWidth: {
    width: '100%'
  },
  fullWidthLeft: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: 0,
    '& svg:nth-child(2)': {
      position: 'absolute',
      right: 7,
      top: 15
    },
  },
  marginTop: {
    margin: '38px 0 0 0',
  },
  inputHeight: {
    height: '62px !important'
  },
  inputAlign: {
    marginTop: 45
  },
  secondary: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    '&:hover,&:focus': {
      backgroundColor: theme.colors.linkButtonHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  tertiary: {
    border: `1px solid ${theme.colors.borderColor} !important`,
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    '&:hover,&:focus': {
      backgroundColor: theme.colors.linkButtonHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  darkButton: {
    backgroundColor: theme.colors.darkButton,
    color: `${theme.colors.darkButtonText}!important`,
    '&:hover,&:focus': {
      backgroundColor: theme.colors.darkButtonHover,
    },
    '& span, & svg': {
      color: `${theme.colors.darkButtonText}!important`,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  secondaryDanger: {
    color: theme.colors.common.danger,
    '&:hover,&:focus': {
      color: theme.colors.common.danger
    },
  },
  solid: {
    border: `1px solid ${theme.colors.common.secondary}`
  },
  Border: {
    border: `1px solid ${theme.colors.borderColor} !important`,
  },
  dashed: {
    border: `1px dashed ${theme.colors.common.secondary}`
  },
  dashedSecondary: {
    border: `1px dashed ${theme.colors.primary}`
  },
  marginSmall: {
    margin: '5px 3px'
  },
  link: {
    color: theme.colors.primary,
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      backgroundColor: theme.colors.linkButtonHover,
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  mini: {
    '&': {
      padding: '6px 12px 6px 0',
      backgroundColor: 'transparent',
      color: theme.colors.primary,
      border: `1px solid ${theme.colors.borderColor} !important`,
    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    },
    '&:hover,&:focus': {
      backgroundColor: theme.colors.customListbg,
      color: theme.colors.primary,
    }
  },
  primaryMini: {
    '&': {
      padding: '6px 12px 6px 0',
      color: theme.colors.common.primary,
      backgroundColor: theme.colors.common.secondary,
    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    },
    '&:hover,&:focus': {
      backgroundColor: theme.colors.common.secondaryHover,
      color: theme.colors.common.primary,
    }
  },
  dots: {
    '&,&:hover,&:focus': {
      color: theme.colors.primary,
      backgroundColor: 'transparent',
    }
  },
  dotsSecondary: {
    '&,&:hover,&:focus': {
      color: theme.colors.common.secondary,
      backgroundColor: 'transparent',
    }
  },
  dense: {
    margin: '2px 2px',
    padding: '8px 16px'
  },
  spaceRight: {
    marginRight: 15,
  },
  spaceLeft: {
    marginLeft: '15px!Important',
  },
  dropdownSelect: {
    border: `1px solid ${theme.colors.borderColor} !important`,
    padding: '12px 10px',
    fontSize: 11,
    color: theme.colors.primary,
    textTransform: 'uppercase',
    '&:hover,&:focus': {
      color: theme.colors.primary,
    },
    '& span': {
      //flexDirection: 'column',
      alignItems: 'center',
      whiteSpace: 'normal'
    },
    '& svg': {
      height: 16,
      width: 16,
      display: 'block',
      marginRight: 0,
      marginLeft: 12
    }
  },
  dropdownSelectAvtive: {
      color: theme.colors.primaryInverse,
      backgroundColor: theme.colors.primary,
    '&:hover,&:focus': {
      color: theme.colors.primaryInverse,
      backgroundColor: theme.colors.primary,
    },
  },
  card: {
    border: `1px solid ${theme.colors.borderColor} !important`,
    fontSize: 11,
    color: theme.colors.primary,
    textTransform: 'none',
    '& span': {
      flexDirection: 'column',
      alignItems: 'center',
      whiteSpace: 'normal'
    },
    '& svg': {
      height: 24,
      width: 24,
      display: 'block',
      marginBottom: 5,
      marginRight: 0
    }
  },
  iconDark: {
    color: `${theme.colors.common.primary}!Important`,
    '&:hover,&:focus': {
      color: `${theme.colors.common.primary}!Important`
    }
  },
  justIcon: {
    color: `${theme.colors.primary}!Important`,
    fontSize: '10px',
    height: '31px',
    minWidth: '31px',
    width: '31px',
    '&:hover,&:focus': {
      color: `${theme.colors.primary}`,
      backgroundColor: 'transparent',
    },
    '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
      marginRight: '0px',
    },
    '&$large': {
      height: '40px',
      minWidth: '40px',
      width: '40px',
      lineHeight: '40px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '13px',
        lineHeight: '56px',
      },
      '& svg': {
        width: '32px',
        height: '32px',
      },
    },
    '&$medium': {
      height: '35px',
      minWidth: '35px',
      width: '35px',
      lineHeight: '35px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '15px',
        lineHeight: '42px',
      },
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
    '&$small': {
      height: '30px',
      minWidth: '30px',
      width: '30px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '17px',
        lineHeight: '29px',
      },
      '& svg': {
        width: '17px',
        height: '17px',
      },
    },
  },
  justIconSolid: {
    //paddingLeft: '3px',
    //paddingRight: '3px',
    fontSize: '10px',
    //height: '30px',
    //minWidth: '30px',
    //width: '30px',
    border: 'none!Important',
    '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
      marginRight: '0px',
    },
    '&$large': {
      height: '40px',
      minWidth: '40px',
      width: '40px',
      lineHeight: '40px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '13px',
        lineHeight: '56px',
      },
      '& svg': {
        width: '32px',
        height: '32px',
      },
    },
    '&$medium': {
      height: '35px',
      minWidth: '35px',
      width: '35px',
      lineHeight: '35px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '15px',
        lineHeight: '42px',
      },
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
    '&$small': {
      height: '35px',
      minWidth: '35px',
      width: '35px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '17px',
        lineHeight: '29px',
      },
      '& svg': {
        width: '66px',
        height: '17px',
      },
    }
  },
  noBorder: {
    color: theme.colors.primary,
    border: '0px solid red',
    position: 'relative',
    left: '-10px',
    top: '-5px',
    '& svg' :{
      height: '25px Important',
    }
  },
  minWidth: {
    minWidth: 120,
  },
  noMargin: {
    margin: 0,
  },
  noMarginRight: {
    marginRight: '0!Important',
  },
  marginBottomTop: {
    marginTop: 15,
    marginBottom: 35,
  },
  rightRound: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&': {
      padding: '9px 9px 9px 0',
      margin: '0px 0 0 2px'
    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    }
  },
  leftRound: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    '&': {
      padding: '7px 12px 6px 0',
      margin: '0px 0 0 2px'
    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    }
  },
  fullRound: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    '&': {
      padding: '8px 12px 8px 0',
      margin: '0px 0 0 2px'
    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    }
  },
  customSelectType: {
    '&': {
      //padding: '6px 12px 6px 0',
      height: 45,
      backgroundColor: 'transparent',
      color: theme.colors.primary,
      border: `1px solid ${theme.colors.borderColor} !important`,

    },
    '& svg': {
      paddingLeft: 10,
      paddingRight: 3,
    },
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    }
  },
  smallTop: {
    top: '16px!Important'
  },
  largeMarginTop: {
    marginTop: 38
  },
  marginUnset: {
    margin: 'unset'
  },
  floatRight: {
    float: 'right'
  },
  noCapitalize: {
    textTransform: 'none'
  },
  misuse: {
    color: theme.colors.misusecheckChips,
  },
})

export default buttonStyle
