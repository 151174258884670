import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'
// apollo
import { compose, graphql } from 'react-apollo'
// core components
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import DateFormat from 'components/Info/DateFormat'
import { CustomCheckbox } from 'components/Form/Form'
import Tooltip from 'components/Tooltip/Tooltip'

import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { setTableSettings } from 'actions/table'

import { NewResultStats } from 'views/TestSessions/helper.jsx'
import { NewResultIcon }  from 'views/TestSessions/helper.jsx'
import { TestSessionType } from 'views/TestSessions/helper.jsx'

import {
  TESTSESSIONS_PAGINATED_QUERY,
  TESTSESSIONS_COUNT_PAGINATED_QUERY,
  DeleteTestSessionListsFromCache,
  CANCEL_TESTSESSION,
  RETRY_TESTSESSION_GDPR,
  RETRY_TESTSESSION_MISUSECHECKER,
  RETRY_TESTSESSION_FACTCHECKER, RefetchTestSessionQueries
} from 'views/TestSessions/gql'
import {CANCEL_PERFORMANCETESTSESSION} from '../PerformanceTestSessions/gql'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle'
import {RefetchTestProjectQueriesOnNewTestSession, START_TESTPROJECT} from 'views/TestProjects/gql'
import { urlToTestTypeFilter } from 'views/TestProjects/helper'
import { hasPermission, canWriteNamespace, canReadNamespace } from 'botium-box-shared/security/permissions'

import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import { getNamespaceWhereClause } from '../helper'
import { isDarkmode } from 'components/Darkmode/helper'
import LinkButton from 'components/Button/LinkButton'
import {setDashboardSettings} from 'actions/dashboard'

class TestSessionsEmbeddedTable extends React.Component {
  constructor(props) {
    super(props)
    this.e2eContainerNames = props.settings.allconnectors.filter(c => c.features.e2eTesting).map(c => c.name)
    this.state = {
      filtersTestResultsExpanded: false,
      filtersTestTypeExpanded: false,
      checkedCheckboxValue: 2,
      columns: {
        name: true,
        namespace: true,
        lastChange: true,
        result: true,
        actions: true
      }
    }
  }

  _assignSessionTypes({ dataTestSessions, dataPerfTestSessions, testTypes }) {
    const typedTestSessions = []
    dataTestSessions && dataTestSessions.testsessions && dataTestSessions.testsessions.forEach(ts => {
      const initialLength = typedTestSessions.length
      if (ts.performanceTestSession) {
        if (!testTypes || testTypes.length === 0 || testTypes.includes('PERFORMANCE')) {
          typedTestSessions.push(Object.assign({}, ts, { type: 'performance' }))
        }
      } else {
        if (ts.securityCheck) {
          if (!testTypes || testTypes.length === 0 || testTypes.includes('SECURITY')) {
            typedTestSessions.push(Object.assign({}, ts, { type: 'security' }))
          }
        }
        if (ts.gdprTesting) {
          if (!testTypes || testTypes.length === 0 || testTypes.includes('GDPR')) {
            typedTestSessions.push(Object.assign({}, ts, { type: 'gdpr' }))
          }
        }
        if (ts.factCheckerTesting) {
          if (!testTypes || testTypes.length === 0 || testTypes.includes('FACTCHECKER')) {
            typedTestSessions.push(Object.assign({}, ts, { type: 'factchecker' }))
          }
        }
        if (ts.misuseCheckerTesting) {
          if (!testTypes || testTypes.length === 0 || testTypes.includes('MISUSECHECKER')) {
            typedTestSessions.push(Object.assign({}, ts, { type: 'misusechecker' }))
          }
        }
        if (ts.nlpAnalytics) {
          if (!testTypes || testTypes.length === 0 || testTypes.includes('NLP')) {
            typedTestSessions.push(Object.assign({}, ts, { type: 'nlp' }))
          }
        }
        // E2E and Regression test is weaker than the other tests types, so it can't be mixed with the stronger tests types.
        // But other tests types can be mixed with each other (NLP and Security, legacy projects).
        if (typedTestSessions.length === initialLength) {
          if (ts.chatbot && this.e2eContainerNames.includes(ts.chatbot.containermode)) {
            if (!testTypes || testTypes.length === 0 || testTypes.includes('E2E')) {
              typedTestSessions.push(Object.assign({}, ts, { type: 'e2e' }))
            }
          } else {
            if (!testTypes || testTypes.length === 0 || testTypes.includes('REGRESSION')) {
              typedTestSessions.push(Object.assign({}, ts, { type: 'regression' }))
            }
          }
        }
      }
    })
    if (!testTypes || testTypes.length === 0 || testTypes.includes('PERFORMANCE')) {
      dataPerfTestSessions && dataPerfTestSessions.performancetestsessions && dataPerfTestSessions.performancetestsessions.forEach(pts => {
        if (pts.testSession) {
          pts.testSession.performanceTestSession = pts
          typedTestSessions.push(Object.assign({}, pts.testSession, { type: 'performance' }))
        }
      })
    }
    return typedTestSessions
  }

  render() {
    const { classes, variables, name, history, skip, first, user, columns, mutateStartTestProject, mutateRetryTestSessionGdpr, mutateRetryTestSessionFactChecker, mutateRetryTestSessionMisuseChecker, mutateCancelTestSession, mutateCancelPerformanceTestSession, ...rest } = this.props
    const { tableSettings, setTableSettings, namespace, setDashboardSettings } = this.props

    const showColumns = { ...this.state.columns, ...columns }

    const _handleTestStatusChange = (teststatus, enabled, onRefresh, updateTableSettings = true) => {
      let testStatusFilter = tableSettings.testStatusFilter || []
      if (teststatus === 'ALL') {
        if (enabled) {
          testStatusFilter = ['PENDING', 'RUNNING', 'DONE', 'CANCELLED']
        } else {
          testStatusFilter = []
        }
      } else {
        if (enabled) {
          testStatusFilter.push(teststatus)
        } else {
          testStatusFilter = testStatusFilter.filter(t => t !== teststatus)
        }
      }
      testStatusFilter = _.uniq(testStatusFilter)

      if(updateTableSettings) {
        setTableSettings(name, {
          ...tableSettings,
          page: 0,
          testStatusFilter
        })
      }
      if (onRefresh) setTimeout(onRefresh, 0)
      return testStatusFilter
    }

    const _handleTestTypeChange = (testtype, enabled, onRefresh, updateTableSettings = true) => {
      let testTypeFilter = tableSettings.testTypeFilter || []
      if (testtype === 'ALL') {
        if (enabled) {
          testTypeFilter = ['NLP', 'REGRESSION', 'SECURITY', 'PERFORMANCE', 'E2E', 'GDPR', 'FACTCHECKER', 'MISUSECHECKER']
        } else {
          testTypeFilter = []
        }
      } else {
        if (enabled) {
          testTypeFilter.push(testtype)
        } else {
          testTypeFilter = testTypeFilter.filter(t => t !== testtype)
        }
      }
      testTypeFilter = _.uniq(testTypeFilter)

      if(updateTableSettings) {
        setTableSettings(name, {
          ...tableSettings,
          page: 0,
          testTypeFilter
        })
      }
      if (onRefresh) setTimeout(onRefresh, 0)
      return testTypeFilter
    }

    const _gqlVariables = (filterString) => {
      const where = { AND: [] }
      if (variables) {
        where.AND.push({ ...variables })
      }
      if (filterString) {
        where.AND.push({ name_contains: filterString })
      }
      if (namespace?.selected?.name) {
        where.AND.push(getNamespaceWhereClause(namespace?.selected?.name))
      }
      if(tableSettings.testTypeFilter && tableSettings.testTypeFilter.length > 0) {
        const testTypeOr = []
        const testTypeAnd = []
        if (tableSettings.testTypeFilter.includes('NLP')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({nlpAnalytics: true})
          }
        }
        if (tableSettings.testTypeFilter.includes('SECURITY')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({securityCheck: true})
          }
        }
        if (tableSettings.testTypeFilter.includes('GDPR')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({gdprTesting: true})
          }
        }
        if (tableSettings.testTypeFilter.includes('FACTCHECKER')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({factCheckerTesting: true})
          }
        }
        if (tableSettings.testTypeFilter.includes('MISUSECHECKER')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({misuseCheckerTesting: true})
          }
        }
        if (tableSettings.testTypeFilter.includes('PERFORMANCE')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({performanceTesting: true})
          }
        } else {
          testTypeAnd.push({OR: [{performanceTesting: null}, {performanceTesting_not: true}]})
        }
        if (tableSettings.testTypeFilter.includes('E2E')) {
          if (!tableSettings.testTypeFilter.includes('REGRESSION')) {
            testTypeOr.push({chatbot: {containermode_in: this.e2eContainerNames}})
          }
        } else {
          if (tableSettings.testTypeFilter.includes('SECURITY')) {
            testTypeAnd.push({OR: [{chatbot: {containermode_not_in: this.e2eContainerNames}}, {securityCheck: true}]})
          } else if (!tableSettings.testTypeFilter.includes('FACTCHECKER')) {
            testTypeAnd.push({chatbot: {containermode_not_in: this.e2eContainerNames}})
          }
        }

        if (testTypeOr.length > 1) {
          where.AND.push({ OR: testTypeOr })
        } else if (testTypeOr.length === 1) {
          where.AND.push(testTypeOr[0])
        }
        if (testTypeAnd.length > 0) {
          where.AND.push(...testTypeAnd)
        }
      }
      if(tableSettings.testStatusFilter) {
        if (tableSettings.testStatusFilter.length > 0) {
          const statuses = []
          for (const status of tableSettings.testStatusFilter) {
            if (status === 'DONE') {
              // DONE is a virtual status, we need to filter for READY and FAILED
              statuses.push('READY')
              statuses.push('FAILED')
            } else {
              statuses.push(status)
            }
          }
          where.AND.push({status_in: statuses})
        }
      }
      return { where }
    }

    const _pageVariables = () => {
      if (_.isNil(skip) && _.isNil(first)) return {}

      const pageVar = {}
      if (!_.isNil(skip)) pageVar.skip = skip
      if (!_.isNil(first)) pageVar.first = first
      return pageVar
    }
    const _link = (ts) => {
      if (ts.type === 'nlp') {
        return ts?.testProject?.id ? `/nlp/projects/view/${ts.testProject.id}/results/${ts.id}` : ''
      } else if (ts.type === 'security') {
        return ts?.testProject?.id ? `/security/projects/view/${ts.testProject.id}/results/${ts.id}` : ''
      } else if (ts.type === 'gdpr') {
        return ts?.testProject?.id ? `/gdpr/projects/view/${ts.testProject.id}/results/${ts.id}` : ''
      } else if (ts.type === 'factchecker') {
        return ts.factCheckerSession?.project?.id ? `/factcheck/projects/view/${ts.factCheckerSession.project.id}/results/${ts.id}` : ''
      } else if (ts.type === 'misusechecker') {
        return ts.misuseCheckerSession?.project?.id ? `/misusecheck/projects/view/${ts.misuseCheckerSession.project.id}/results/${ts.id}` : ''
      } else if (ts.type === 'performance' && ts.performanceTestSession) {
        return ts?.testProject?.id ? `/performance/projects/view/${ts.testProject.id}/results/${ts.performanceTestSession.id}` : ''
      } else if (ts.type === 'e2e') {
        return ts?.testProject?.id ? `/e2e/projects/view/${ts.testProject.id}/results/${ts.id}/dashboard` : ''
      }
      return ts?.testProject?.id ? `/regression/projects/view/${ts.testProject.id}/results/${ts.id}/dashboard` : ''
    }
    return <ServerSidePagingTable
      key={`${name}_${namespace?.selected?.name}_${isDarkmode() ? 'dark' : 'light'}`}
      name={name}
      tableHeaderColor="primary"
      gqlQuery={{
        query: TESTSESSIONS_PAGINATED_QUERY,
        variables: _pageVariables(),
        notifyOnNetworkStatusChange: true
      }}
      gqlCountQuery={{
        query: TESTSESSIONS_COUNT_PAGINATED_QUERY,
        countValue: data => data.testsessionsCount,
        variables: {}
      }}
      gqlVariables={(filterString) => _gqlVariables(filterString)}

      customActionsRight={({ onRefresh }) => (
        <>
          <GridContainer >
            <GridItem md={12} right>
              <Button
                className={classes.testStatusButtonPosition}
                onClick={() => this.setState({
                  filtersTestResultsExpanded: !this.state.filtersTestResultsExpanded,
                  filtersTestTypeExpanded: false
                })}

                label="Chatbot"
                dropdownSelectAvtive={this.state.filtersTestResultsExpanded}
                dropdownSelect
                data-unique="btnFilterViewResultsExpanded"
              >
                Test Status {tableSettings.testStatusFilter.length > 0 ? <span className={classes.valuebuble}>{tableSettings.testStatusFilter.length}</span> : <span className={classes.valuebubleempty}>0</span>}
                <ShowIcon icon={this.state.filtersTestResultsExpanded ? 'caret-up' : 'caret-down'} />
              </Button>
            </GridItem>
            <GridItem>
              <GridContainer>
                {this.state.filtersTestResultsExpanded && <GridItem className={classes.filtersPosition}>
                  <CustomCheckbox data-unique="chkFilterViewPending"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.filterswitch,
                      checked: classes.filterswitch
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestStatusChange('PENDING', e.target.checked, onRefresh),
                      checked: tableSettings.testStatusFilter && tableSettings.testStatusFilter.includes('PENDING')
                    }}
                    label="PENDING"
                  />
                  <CustomCheckbox data-unique="chkFilterViewRunning"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.filterswitch,
                      checked: classes.filterswitch
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestStatusChange('RUNNING', e.target.checked, onRefresh),
                      checked: tableSettings.testStatusFilter && tableSettings.testStatusFilter.includes('RUNNING')
                    }}
                    label="RUNNING"
                  />
                  <CustomCheckbox data-unique="chkFilterViewDone"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.filterswitch,
                      checked: classes.filterswitch
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestStatusChange('DONE', e.target.checked, onRefresh),
                      checked: tableSettings.testStatusFilter && tableSettings.testStatusFilter.includes('DONE')
                    }}
                    label="DONE"
                  />
                  <CustomCheckbox data-unique="chkFilterViewCancelled"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.filterswitch,
                      checked: classes.filterswitch
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestStatusChange('CANCELLED', e.target.checked, onRefresh),
                      checked: tableSettings.testStatusFilter && tableSettings.testStatusFilter.includes('CANCELLED')
                    }}
                    label="CANCELLED"
                  />
                </GridItem>}
                {this.state.filtersTestTypeExpanded && <GridItem >
                  <CustomCheckbox data-unique="chkFilterViewNLP"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.nlptext,
                      checked: classes.nlptext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('NLP', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('NLP')
                    }}
                    label="NLP"
                  />
                  <CustomCheckbox data-unique="chkFilterViewSecurity"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.securitytext,
                      checked: classes.securitytext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('SECURITY', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('SECURITY')
                    }}
                    label="SECURITY"
                  />
                  <CustomCheckbox data-unique="chkFilterViewRegression"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.regressiontext,
                      checked: classes.regressiontext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('REGRESSION', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('REGRESSION')
                    }}
                    label="REGRESSION"
                  />
                  <CustomCheckbox data-unique="chkFilterViewPerformance"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.performancetext,
                      checked: classes.performancetext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('PERFORMANCE', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('PERFORMANCE')
                    }}
                    label="PERFORMANCE"
                  />
                  <CustomCheckbox data-unique="chkFilterViewE2E"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.e2etext,
                      checked: classes.e2etext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('E2E', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('E2E')
                    }}
                    label="E2E"
                  />
                  <CustomCheckbox data-unique="chkFilterViewGDPR"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.gdprtext,
                      checked: classes.gdprtext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('GDPR', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('GDPR')
                    }}
                    label="GDPR"
                  />
                  <CustomCheckbox data-unique="chkFilterViewFactChecker"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.factcheckertext,
                      checked: classes.factcheckertext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('FACTCHECKER', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('FACTCHECKER')
                    }}
                    label="FACTCHECKER"
                  />
                  <CustomCheckbox data-unique="chkFilterViewMisuseChecker"
                    formControlProps={{ className: classes.testtypefilter }}
                    switchClasses={{
                      label: classes.misusecheckertext,
                      checked: classes.misusecheckertext,
                    }}
                    useCheckbox
                    input={{
                      onChange: e => _handleTestTypeChange('MISUSECHECKER', e.target.checked, onRefresh),
                      checked: tableSettings.testTypeFilter && tableSettings.testTypeFilter.includes('MISUSECHECKER')
                    }}
                    label="MISUSECHECKER"
                  />
                </GridItem>}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </>
      )}
      tableHead={[

        showColumns.name ? { name: 'Date', nameColumn: true, width: 'large' } : undefined,
        showColumns.result ? 'Test Type' : undefined,
        showColumns.result ? 'Results' : undefined,
        showColumns.actions ? { name: 'Actions', right: true } : undefined,
      ].filter(t => !_.isUndefined(t))}
      tableData={(data, refresh) => {
        const sessions = this._assignSessionTypes({
          dataTestSessions: data,
          testTypes: tableSettings.testTypeFilter
        })

        return sessions.map((ts, tsIndex) => {
          const _canRead = (namespace) => canReadNamespace(user, user.namespacePermissions, namespace)
          const _canWrite = (namespace) => canWriteNamespace(user, user.namespacePermissions, namespace)
          return [
            showColumns.name ? {value: <><Tooltip title={ts.name}>
                <LinkButton key={`${tsIndex}`}
                            onClick={() => {
                              setDashboardSettings('keepTestSessionIds', false)
                              history.push(_link(ts))
                            }}
                            data-unique={`btnFilterViewMoveto_${tsIndex}_${ts.type}`}
                            className={classes.resultListLink}>
                  <DateFormat>{ts.updatedAt}</DateFormat>
                </LinkButton>
              </Tooltip></>}: undefined,
            showColumns.result ? {value: <><div className={classes.resultListTestType}><TestSessionType ts={ts} /></div> <div className={classes.newResultIcon}><NewResultIcon ts={ts} /></div></>}: undefined,
            showColumns.result ? {value: <div className={classes.resultListResult}><NewResultStats ts={ts} /></div>}: undefined,
            showColumns.actions ? {value: <DropdownButton
            dots
            aria-label="Actions"
            tooltipTitle="Actions"
            items={[
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) ? {
                id: 'repeat',
                icon: 'redo',
                name: 'Repeat Test Session',
                onClick: () => {
                  if (ts.type === 'gdpr') {
                    mutateRetryTestSessionGdpr({
                      variables: { id: ts.id }
                    })
                  } else if (ts.type === 'misusechecker') {
                    mutateRetryTestSessionMisuseChecker({
                      variables: { id: ts.id }
                    })
                  } else if (ts.type === 'factchecker') {
                    mutateRetryTestSessionFactChecker({
                      variables: { id: ts.id }
                    })
                  } else {
                    mutateStartTestProject({
                      variables: { id: ts.testProject.id, debug: false }
                    })
                  }
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && !['performance', 'gdpr', 'factchecker', 'misusechecker'].some(t => ts.type === t) ? {
                id: 'repeat_logging',
                icon: 'bug',
                name: 'Repeat Test Session (Extended Logging)',
                onClick: () => {
                  mutateStartTestProject({
                    variables: { id: ts.testProject.id, debug: true }
                  })
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && ts.type === 'gdpr' ? {
                id: 'repeat_gdpr',
                icon: 'bug',
                name: 'Repeat Test Session (Extended Logging))',
                onClick: () => {
                  mutateRetryTestSessionGdpr({
                    variables: { id: ts.id, debug: true }
                  })
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && ts.type === 'factchecker' ? {
                id: 'repeat_factchecker',
                icon: 'bug',
                name: 'Repeat Test Session (Extended Logging))',
                onClick: () => {
                  mutateRetryTestSessionFactChecker({
                    variables: { id: ts.id, debug: true }
                  })
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && ts.type === 'misusechecker' ? {
                id: 'repeat_misusechecker',
                icon: 'bug',
                name: 'Repeat Test Session (Extended Logging))',
                onClick: () => {
                  mutateRetryTestSessionMisuseChecker({
                    variables: { id: ts.id, debug: true }
                  })
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && ts.type !== 'performance' && (ts.status === 'PENDING' || ts.status === 'RUNNING') ? {
                id: 'cancel',
                icon: 'power-off',
                name: 'Cancel Test Session',
                onClick: () => {
                  mutateCancelTestSession({
                    variables: { id: ts.id }
                  })
                }
              } : null,
              hasPermission(user, 'TESTSESSIONS_CREATE') && _canWrite(ts.namespace) && ts.type === 'performance' && ts.performanceTestSession.jobs.findIndex(j => j.status === 'RUNNING' || j.status === 'PENDING') >= 0 ? {
                id: 'cancel',
                icon: 'power-off',
                name: 'Cancel Test Session',
                onClick: () => {
                    mutateCancelPerformanceTestSession({
                      variables: { id: ts.performanceTestSession.id }
                    })
                }
              } : null,
              ts.testProject && !['e2e', 'regression', 'performance'].some(t => ts.type === t) && _canRead(ts.namespace) && {
                id: 'testsessionbase',
                icon: 'magnifying-glass',
                name: 'Open Base Test Session',
                onClick: () => history.push(`/regression/projects/view/${ts.testProject.id}/results/${ts.id}`)
              },
              ts.factCheckerSession?.project?.id && _canRead(ts.namespace) && {
                id: 'testsessionbase',
                icon: 'magnifying-glass',
                name: 'Open Base Test Session',
                onClick: () => history.push(`/factcheckregression/projects/view/${ts.factCheckerSession.project.id}/results/${ts.id}`)
              },
              ts.testProject && _canRead(ts.testProject.namespace) && {
                id: 'testproject',
                icon: 'infinity',
                name: `Open Test Project "${ts.testProject.name}"`,
                onClick: () => history.push(`${this.getRootPath()}/projects/view/${ts.testProject.id}`)
              },
              ts.chatbot && _canRead(ts.chatbot.namespace) && {
                id: 'chatbot',
                icon: 'robot',
                name: `Open Chatbot "${ts.chatbot.name}"`,
                onClick: () => history.push(`/chatbots/view/${ts.chatbot.id}`)
              },
              ...(ts.testSets || []).filter(t => _canRead(t.namespace)).map((t, index) => ({
                id: `testset${index}`,
                icon: 'folder-open',
                name: `Open Test Set "${t.name}"`,
                onClick: () => history.push(`/testsets/view/${t.id}`)
              }))
            ].filter(m => m)}>
              </DropdownButton>} : undefined
          ].filter(t => !_.isUndefined(t))

        })

      }}
      {...((!_.isNil(skip) || !_.isNil(first)) ? { disableFooter: true } : {})}
      {...rest}
    />
  }
}

export default compose(
  withRouter,
  withStyles(testsessionsStyle),
  connect(
    (state, ownProps) => ({
      user: state.token.user,
      license: state.settings.license,
      settings: state.settings,
      namespace: state.namespace,
      tableSettings: {
        // we do not display test type filter anymore, because we have only one.
        // but the filtering is still active, we put the only one type into it as default
        testTypeFilter: urlToTestTypeFilter(ownProps.location.pathname) ? [urlToTestTypeFilter(ownProps.location.pathname)] : ['NLP', 'REGRESSION', 'SECURITY', 'PERFORMANCE', 'E2E', 'GDPR', 'FACTCHECKER', 'MISUSECHECKER'],
        testStatusFilter: [],
        ...(state.table[ownProps.name] || {})
      }
    }),
    { setAlertSuccessMessage, setAlertErrorMessage, setTableSettings, setDashboardSettings }
  ),
  graphql(START_TESTPROJECT, {
    props: ({ mutate }) => ({
      mutateStartTestProject: args => mutate(args),
    }),
    options: (props) => ({
      update: DeleteTestSessionListsFromCache,
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Test Session started...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startTestProject.testProject.id),
        ...RefetchTestSessionQueries(data.startTestProject.id)
      ],
    })
  }),
  graphql(RETRY_TESTSESSION_GDPR, {
    props: ({ mutate }) => ({
      mutateRetryTestSessionGdpr: args => mutate(args),
    }),
    options: (props) => ({
      update: DeleteTestSessionListsFromCache,
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Test Session started...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.retryTestSessionGdpr.id)
      ],
    })
  }),
  graphql(RETRY_TESTSESSION_FACTCHECKER, {
    props: ({ mutate }) => ({
      mutateRetryTestSessionFactChecker: args => mutate(args),
    }),
    options: (props) => ({
      update: DeleteTestSessionListsFromCache,
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Test Session started...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      }
    })
  }),
  graphql(RETRY_TESTSESSION_MISUSECHECKER, {
    props: ({ mutate }) => ({
      mutateRetryTestSessionMisuseChecker: args => mutate(args),
    }),
    options: (props) => ({
      update: DeleteTestSessionListsFromCache,
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Test Session started...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      }
    })
  }),
  graphql(CANCEL_TESTSESSION, {
    props: ({ mutate }) => ({
      mutateCancelTestSession: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Sent cancellation request to Test Session')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
      }
    })
  }),
  graphql(CANCEL_PERFORMANCETESTSESSION, {
    props: ({ mutate }) => ({
      mutateCancelPerformanceTestSession: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setParentState && props.setParentState({ newTestSessionCount: Math.floor(Math.random() * 1000) + 1 })
        props.setAlertSuccessMessage('Sent cancellation request to Performance Test Session')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Sending cancellation request to Performance Test Session failed', error)
      }
    })
  })
)(TestSessionsEmbeddedTable)
