import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import * as Sentry from '@sentry/react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import { Form } from 'react-final-form'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
// apollo
import { Mutation, compose, graphql, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Divider from 'components/Divider/Divider'
import ShowIcon from 'components/Icon/ShowIcon'
import Field from 'components/Form/OptionalField'
import { renderTextField, renderPasswordField, required } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import loginPageStyle from 'assets/jss/material-dashboard-react/components/loginPageStyle.jsx'

import { setLogin } from 'actions/token'

import config from '../../config'
import Text from '../Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { decodeURIComponentWeak } from 'views/helper'

const LOGIN_USER_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(name: $email, email: $email, password: $password) {
      expiryDate
      user {
        id
        name
        email
        roles { id name permissions }
        namespacePermissions { id namespace canRead canWrite }
      }
      passwordResetToken
    }
  }
`

const AUTOLOGIN_USER_QUERY = gql`
  query AutoLoginQuery {
    autologin {
      expiryDate
      user {
        id
        name
        email
        roles { id name permissions }
        namespacePermissions { id namespace canRead canWrite }
      }
    }
  }
`

const SEND_RESET_EMAIL = gql`
  mutation SendResetEmail($receiver: String!) {
    sendResetEmail(receiver: $receiver)
  }
`

const LOGIN_POSSIBILITIES_QUERY = gql`
  query LoginPossibilitiesQuery {
    loginPossibilities {
      googleAuthEnabled
      ldapAuthEnabled
      saml2AuthEnabled
      cyaraPortalEnabled
    }
  }
`

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    const { setAlertErrorMessage, lastLogin } = this.props
    const query = queryString.parse(this.props.location.search)

    this.state = {
      showResetDialog: false,
      query: query,
      forwardTo: query.forward ? decodeURIComponentWeak(query.forward) : lastLogin ? '/dashboard' : '/dashboard', //'/quickstart',
      autoLoginLoading: true
    }

    if (this.state.query.err) {
      setAlertErrorMessage(this.state.query.err)
    }
  }

  renderResetDialog() {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return (
      <Mutation
        mutation={SEND_RESET_EMAIL}
        onCompleted={data => {
          this.setState({ showResetDialog: false })
          setAlertSuccessMessage('In case a user with the given username or email has been found, a verification email has been sent. Watch your Inbox.')
        }}
        onError={error => {
          setAlertErrorMessage(`Sending reset email failed`, error)
        }}
      >
        {(sendResetEmail, { loading, error }) => (
          <Form
            onSubmit={values => {
              sendResetEmail({ variables: { receiver: values.receiver } })
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={this.state.showResetDialog}
                isWorking={loading}
                onCancel={() => this.setState({ showResetDialog: false })}
                onOk={() => handleSubmit()}
                title="Send Verification Email"
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="receiver"
                        component={renderTextField}
                        label="Username or Email Address"
                        validate={required}
                        data-unique="txtLoginPageResetUsername"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  _initFeatures (user) {
    const { features } = this.props
    if (user && user.id) {
      if (features && features.sentryDsn) {
        Sentry.setUser({
          username: user.name,
          email: user.email
        })
      }
    }
  }

  onForwardTo (forwardTo) {
    const { history } = this.props

    if (!forwardTo) return
    if (forwardTo.startsWith('qbox:')) {
      window.location.href = `${config.api.base}/qbox?forward=${encodeURIComponent(forwardTo.substr(5))}`
    } else {
      history.push(forwardTo)
    }
  }

  componentDidMount() {
    const { client, setAlertErrorMessage, setLogin } = this.props
    const { forwardTo } = this.state

    client.query({
      query: AUTOLOGIN_USER_QUERY,
      fetchPolicy: 'network-only',
    }).then(({ data }) => {
      if (data.autologin && data.autologin.user) {
        this._initFeatures(data.autologin.user)
        setLogin(data.autologin.expiryDate, data.autologin.user)
        //passing user and account objects:
        window.aptrinsic && window.aptrinsic('identify',
          {
          //User Fields
            id: data.autologin.user.id, // Required for logged in app users
            email: data.autologin.user.email,
          })
        this.onForwardTo(forwardTo)
      } else {
        this.setState({ autoLoginLoading: false })
      }
    }).catch (err => {
      setAlertErrorMessage('Login failed!', err)
      this.setState({ autoLoginLoading: false })
    })
  }

  render() {
    const { autoLoginLoading } = this.state

    if (autoLoginLoading) return <LoadingIndicator large box />
    else return this.renderLoginOptions()
  }

  onGoogleAuthClicked(forwardTo) {
    const url = `${config.api.base}/auth/google/login?forward=${forwardTo}`
    window.open(url, '_self')
  }

  onSaml2AuthClicked(forwardTo) {
    const url = `${config.api.base}/auth/saml2/login?forward=${forwardTo}`
    window.open(url, '_self')
  }

  onCyaraPortalAuthClicked(forwardTo) {
    const url = `${config.api.base}/auth/cyara/login?forward=${forwardTo}`
    window.open(url, '_self')
  }

  renderLoginOptions() {
    const { loginData, lastLogin } = this.props
    const { forwardTo, saml2ButtonClicked, googleButtonClicked, cyaraPortalButtonClicked } = this.state

    const hasSaml2 = loginData && loginData.loginPossibilities && loginData.loginPossibilities.saml2AuthEnabled
    const hasGoogle = loginData && loginData.loginPossibilities && loginData.loginPossibilities.googleAuthEnabled
    const hasCyaraPortal = loginData && loginData.loginPossibilities && loginData.loginPossibilities.cyaraPortalEnabled

    return <GridContainer>
      <Hidden xsDown>
        <GridItem sm={2} md={3} />
      </Hidden>
      <GridItem xs={12} sm={8} md={6}>
        <GridContainer>
          <GridItem xs={12} largePadding center>
            {lastLogin && <Text header>We're glad to see you again.</Text>}
            {!lastLogin && <Text header>We're glad to see you.</Text>}
          </GridItem>
          <GridItem xs={12} largePadding center>
            <Text subheader>Don't have an account ? Please contact your Administrator.</Text>
          </GridItem>
          {(hasSaml2 || hasGoogle) && <>
            <GridItem xs={12} smallPadding center>
              <Text bold>Login with:</Text>
            </GridItem>
          </>}
          {hasGoogle && <>
            <GridItem xs={12} noPadding center smallMarginTop>
              <Button
                style={{ backgroundColor: '#4285F4' }}
                data-unique="btnLoginPageGoogle"
                onClick={() => {
                  this.setState({ googleButtonClicked: true })
                  this.onGoogleAuthClicked(forwardTo)
                }}
                disabled={googleButtonClicked}
                fullWidth
                noMargin
              >
                {googleButtonClicked && <LoadingIndicator alt />}
                {!googleButtonClicked && <ShowIcon icon={faGoogle} />}
                Sign in with Google
              </Button>
            </GridItem>
          </>}
          {hasSaml2 && <>
            <GridItem xs={12} noPadding center smallMarginTop>
              <Button
                darkButton
                data-unique="btnLoginPageSaml2"
                onClick={() => {
                  this.setState({ saml2ButtonClicked: true })
                  this.onSaml2AuthClicked(forwardTo)
                }}
                disabled={saml2ButtonClicked}
                fullWidth
                noMargin
              >
                {saml2ButtonClicked && <LoadingIndicator alt />}
                {!saml2ButtonClicked && <ShowIcon icon="building" />}
                Sign in with Single Sign-On
              </Button>
            </GridItem>
          </>}
          {hasCyaraPortal && <>
            <GridItem xs={12} noPadding center smallMarginTop>
              <Button
                darkButton
                data-unique="btnLoginPageCyaraPortal"
                onClick={() => {
                  this.setState({ cyaraPortalButtonClicked: true })
                  this.onCyaraPortalAuthClicked(forwardTo)
                }}
                disabled={cyaraPortalButtonClicked}
                fullWidth
                noMargin
              >
                {cyaraPortalButtonClicked && <LoadingIndicator alt />}
                {!cyaraPortalButtonClicked && <ShowIcon icon="building" />}
                Sign in with Cyara Portal
              </Button>
            </GridItem>
          </>}
          {(hasSaml2 || hasGoogle || hasCyaraPortal) && <>
            <GridItem xs={12} smallPadding center>
              <Divider text="or" orientiation="horizontal" />
            </GridItem>
            <GridItem xs={12}>
              {this.renderLocalLoginForm(forwardTo)}
            </GridItem>
          </>}
          {!hasSaml2 && !hasGoogle && !hasCyaraPortal && <>
            <GridItem xs={12}>
              {this.renderLocalLoginForm(forwardTo)}
            </GridItem>
          </>}
        </GridContainer>
      </GridItem>
    </GridContainer>
  }

  renderLocalLoginForm(forwardTo) {
    const { setLogin } = this.props
    const { setAlertSuccessMessage, setAlertErrorMessage, history } = this.props

    return (
      <Mutation
        mutation={LOGIN_USER_MUTATION}
        onCompleted={data => {
          if (data.login.passwordResetToken) {
            setAlertSuccessMessage(`Password is expired.`)
            history.push(`/passwordexpired/${data.login.passwordResetToken}`)
          } else {
            this._initFeatures(data.login.user)
            setAlertSuccessMessage(`User ${data.login.user.name} logged in ...`)
            setLogin(data.login.expiryDate, data.login.user)
            //passing user and account objects:
            window.aptrinsic && window.aptrinsic('identify',
              {
              //User Fields
                id: data.login.user.id, // Required for logged in app users
                email: data.login.user.email,
              })
            this.onForwardTo(forwardTo)
          }
        }}
        onError={error => {
          setAlertErrorMessage('Login failed!', error)
        }}
      >
        {(loginUser, { loading }) => (
          <Form
            onSubmit={values => {
              loginUser({
                variables: {
                  email: values.email,
                  password: values.password,
                },
              })
            }}
            render={({
              handleSubmit,
              submitting
            }) => (
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} noPadding>
                    <Field
                      name="email"
                      component={renderTextField}
                      label="Email or Username"
                      validate={required}
                      data-unique="txtLoginPageLoginFormUsername"
                    />
                  </GridItem>
                  <GridItem xs={12} noPadding>
                    <Field
                      name="password"
                      component={renderPasswordField}
                      label="Password"
                      validate={required}
                      autoComplete="current-password"
                      data-unique="txtLoginPageLoginFormPassword"
                    />
                  </GridItem>
                  <GridItem xs={12} noPadding right>
                    {this.renderResetDialog()}
                    <Text muted>
                      <LinkButton onClick={() => this.setState({ showResetDialog: true })}>Forgot password ?</LinkButton>
                    </Text>
                  </GridItem>
                  <GridItem xs={12} noPadding center smallMarginTop>
                    <Button
                      type="submit"
                      disabled={loading || submitting}
                      data-unique="btnLoginPageLoginFormSubmit"
                      fullWidth
                      noMargin
                    >
                      {(loading || submitting) && <LoadingIndicator alt />}
                      Login
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }
}

export default compose(
  withStyles(loginPageStyle),
  connect(
    state => ({ features: state.settings.features, lastLogin: state.token.lastLogin, license: state.settings.license }),
    { setLogin, setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(LOGIN_POSSIBILITIES_QUERY, {
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({ data }) => ({
      loginData: data,
    }),
  })
)(withApollo(LoginPage))
