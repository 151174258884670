import React, { useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import { Stepper, Step, StepLabel } from '@material-ui/core'

const styles = withStyles({
  '& .MuiStepper-alternativeLabel': {
    border: '1px solid red',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  stepLine: {
    height: 12,
    width: '100%',
    position: 'relative',
    top: 17,
  },
  stepCircle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'relative',
    top: -45,
    margin: 'auto',
  },
  alternativeLabel: {
    marginTop: '0!Important',
    '& .root': {
      padding: 0,
    },
  },
})

const steps = ['Short', 'Standard', 'Extended', 'Exhaustive']

const StepperSlider = ({ color, onChange, value, classes }) => {
  //const { classes } = props
  const [activeStep, setActiveStep] = useState((value ?? 1) - 1)

  useEffect(() => {
    if (value - 1 !== activeStep) {
      setActiveStep(value - 1)
    }
  }, [value, activeStep])

  const handleStepClick = (index) => {
    setActiveStep(index)
    onChange(index + 1)
  }

  return (
    <div style={{ width: '100%', textAlign: 'center'}}>
      <Stepper alternativeLabel activeStep={activeStep} connector={null}>
        {steps.map((label, index) => (
          <Step key={label}>
            <div className={classes.stepLine}
              style={{
                backgroundColor: color,
                opacity: index === activeStep ? 1 : 0.5,
                borderRadius:
                  index === 0 ? '10px 0 0 10px' :
                  index === steps.length - 1 ? '0 10px 10px 0' :
                  '0',
              }}
            />
            <StepLabel
              onClick={() => handleStepClick(index)}
              style={{ cursor: 'pointer', zIndex: 1000, position: 'relative'}}
            >
              <div
                className={classes.stepCircle}
                style={{
                  backgroundColor: index === activeStep ? color : 'white',
                  border: `2px solid ${color}`,
                  color: index === activeStep ? 'white' : color,
                }}
              />
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default styles(StepperSlider)
