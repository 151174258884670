import { gql } from 'apollo-boost'
import { validateUnique } from 'helper/graphHelper'

const CHECK_FACT_CHECKER_PROJECT_NAME_QUERY = gql`
query CheckFactCheckerProjectNameQuery($where: FactCheckerProjectWhereInput!) {
  factcheckerprojects(where: $where) {
    id
  }
}
`
export async function validateFactCheckerProjectNameUnique (client, factCheckerProjectId, factCheckerProjectName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_FACT_CHECKER_PROJECT_NAME_QUERY, { where: { name: factCheckerProjectName } }, 'factcheckerprojects', factCheckerProjectId, errMsg)
}

const CHECK_MISUSE_CHECKER_PROJECT_NAME_QUERY = gql`
query CheckMisuseCheckerProjectNameQuery($where: MisuseCheckerProjectWhereInput!) {
  misusecheckerprojects(where: $where) {
    id
  }
}
`
export async function validateMisuseCheckerProjectNameUnique (client, misuseCheckerProjectId, misuseCheckerProjectName, errMsg = 'Please choose another name, this one is already used') {
  return validateUnique(client, CHECK_MISUSE_CHECKER_PROJECT_NAME_QUERY, { where: { name: misuseCheckerProjectName } }, 'misusecheckerprojects', misuseCheckerProjectId, errMsg)
}
