import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import {
  regressionIcon,
  nlpIcon,
  e2eIcon,
  conditionalLogichookIcon,
  conditionalStart,
  conditionalEnd,
  conditionalStartend,
  conditionalLine,
  asserterFactCheckIcon,
  asserterMisuseIcon,
  asserterObjectiveIcon,
  asserterLanguageIcon
} from 'components/Icon/CustomIcons'
import { aiIcon } from './CustomIcons'
import { partialConvoIcon } from './CustomIcons'
import { inputCustomIcon } from './CustomIcons'
import { asserterCardsIcon } from './CustomIcons'
import { asserterButtonIcon } from './CustomIcons'
import { asserterCustomIcon } from './CustomIcons'
import { asserterMediaIcon } from './CustomIcons'
import { asserterTextIcon } from './CustomIcons'
import { textIcon } from './CustomIcons'
import { convoIcon } from './CustomIcons'
import { inputButtonIcon } from './CustomIcons'
import { inputFormIcon } from './CustomIcons'
import { utteranceIcon } from './CustomIcons'
import { inputMediaIcon } from './CustomIcons'
import { asserterMailIcon } from './CustomIcons'
import { asserterLinkIcon } from './CustomIcons'
import { asserterSmsIcon } from './CustomIcons'
import { asserterHttpIcon } from './CustomIcons'
import { asserterActionIcon } from './CustomIcons'
import { asserterContextIcon } from './CustomIcons'
import { asserterIntentIcon } from './CustomIcons'
import { asserterEntitiesIcon } from './CustomIcons'
import { asserterFormIcon } from './CustomIcons'
import { asserterResponseLengthIcon } from './CustomIcons'
import { asserterBotConsumedIcon } from './CustomIcons'
import { asserterBotUnconsumedIcon } from './CustomIcons'
import { asserterJsonIcon } from './CustomIcons'

import { logichookPauseIcon } from './CustomIcons'
import { logichookPartialConvoIcon } from './CustomIcons'
import { logichookSetScriptingIcon } from './CustomIcons'
import { logichookClearScriptingIcon } from './CustomIcons'
import { logichookAssignScriptingIcon } from './CustomIcons'
import { logichookWaitForBotIcon } from './CustomIcons'
import { logichookSkipBotUnconsumedIcon } from './CustomIcons'
import { logichookCustomIcon } from './CustomIcons'
import { logichookPerfectoReportingIcon } from './CustomIcons'
import { logichookHttpIcon } from './CustomIcons'
import { logicHookVoipIgnoreSilenceDurationIcon } from './CustomIcons'
import { logicHookVoipJoinBySilenceDurationIcon } from './CustomIcons'


import { beginStepIcon } from './CustomIcons'
import { endStepIcon } from './CustomIcons'
import { errorIcon } from './CustomIcons'
import { warningIcon } from './CustomIcons'
import { csvFileIcon } from './CustomIcons'
import { jsonFileIcon } from './CustomIcons'
import { scriptingMemoryFileIcon } from './CustomIcons'
import { yamlFileIcon } from './CustomIcons'
import { convoErrorIcon } from './CustomIcons'
import { convoWarningIcon } from './CustomIcons'
import { utteranceErrorIcon } from './CustomIcons'
import { utteranceWarningIcon } from './CustomIcons'
import { partialConvoErrorIcon } from './CustomIcons'
import { partialConvoWarningIcon } from './CustomIcons'
import { xlsFileIcon } from './CustomIcons'
import { successIcon } from './CustomIcons'
import { testcasesIcon } from './CustomIcons'
import { durationIcon } from './CustomIcons'
import { infoIcon } from './CustomIcons'
import { userResponseIcon } from './CustomIcons'
import { endConvoIcon } from './CustomIcons'
import { asserterDatabaseIcon } from './CustomIcons'
import { stopIcon } from './CustomIcons'
import { highPrioIcon, mediumPrioIcon, lowPrioIcon } from './CustomIcons'
import { altIntentIcon } from './CustomIcons'
import { primaryIntentIcon } from './CustomIcons'
import { entityIcon } from './CustomIcons'
import { attachmentVideoIcon } from './CustomIcons'
import { attachmentAudioIcon } from './CustomIcons'
import { attachmentImageIcon } from './CustomIcons'
import { attachmentIcon } from './CustomIcons'
import { convoCardHeaderIcon } from './CustomIcons'
import { convoCardSubHeaderIcon } from './CustomIcons'

import { menuDashboardIcon } from './CustomIcons'
import { menuNlpIcon } from './CustomIcons'
import { menuE2eIcon } from './CustomIcons'
import { menuLlmIcon } from './CustomIcons'
import { menuRegressionIcon } from './CustomIcons'
import { menuPerformanceIcon } from './CustomIcons'
import { menuSecurityIcon } from './CustomIcons'
import { menuScheduledTestsIcon } from './CustomIcons'
import { menuGdprIcon } from './CustomIcons'
import { menuTestsetsIcon } from './CustomIcons'
import { menuChatbotsIcon } from './CustomIcons'
import { menuSettingsIcon } from './CustomIcons'
import { menuTestDataWizard} from './CustomIcons'
import { menuKnowledgeIcon } from './CustomIcons'
import { menuArrowupIcon } from './CustomIcons'
import { menuArrowdownIcon } from './CustomIcons'
import { menuLogoutIcon } from './CustomIcons'
import { menuFactCheckIcon } from './CustomIcons'
import { menuMisuseCheckIcon} from './CustomIcons'
import { projectsIcon } from './CustomIcons'
import { testTypeIcon } from './CustomIcons'
import { senderBotIcon } from './CustomIcons'
import { senderUserIcon } from './CustomIcons'
import { senderMisuseIcon } from './CustomIcons'

const customIcons = {
  menuTestDataWizard: menuTestDataWizard,
  menuLogoutIcon: menuLogoutIcon,
  menuArrowdown: menuArrowdownIcon,
  menuArrowup: menuArrowupIcon,
  menuKnowledge: menuKnowledgeIcon,
  menuSettings: menuSettingsIcon,
  menuChatbots: menuChatbotsIcon,
  menuTestsets: menuTestsetsIcon,
  menuGdpr: menuGdprIcon,
  menuScheduledTests: menuScheduledTestsIcon,
  menuSecurity: menuSecurityIcon,
  menuPerformance: menuPerformanceIcon,
  menuRegression: menuRegressionIcon,
  menuLlm: menuLlmIcon,
  menuFactCheck: menuFactCheckIcon,
  menuMisuseCheck: menuMisuseCheckIcon,
  menuE2e: menuE2eIcon,
  menuNlp: menuNlpIcon,
  menuDashboard: menuDashboardIcon,
  nlp: nlpIcon,
  e2e: e2eIcon,
  AI: aiIcon,
  regression: regressionIcon,
  inputMedia: inputMediaIcon,
  inputButton: inputButtonIcon,
  inputForm: inputFormIcon,
  text: textIcon,
  utterance: utteranceIcon,
  partialConvo: partialConvoIcon,
  convo: convoIcon,
  customUserInput: inputCustomIcon,
  asserterCustom: asserterCustomIcon,
  asserterText: asserterTextIcon,
  asserterCards: asserterCardsIcon,
  asserterButton: asserterButtonIcon,
  asserterMedia: asserterMediaIcon,
  asserterMail: asserterMailIcon,
  asserterDatabase: asserterDatabaseIcon,
  asserterSms: asserterSmsIcon,
  asserterLink: asserterLinkIcon,
  asserterHttp: asserterHttpIcon,
  asserterAction: asserterActionIcon,
  asserterContext: asserterContextIcon,
  asserterIntent: asserterIntentIcon,
  asserterEntities: asserterEntitiesIcon,
  asserterForm: asserterFormIcon,
  asserterResponseLength: asserterResponseLengthIcon,
  asserterBotConsumed: asserterBotConsumedIcon,
  asserterBotUnconsumed: asserterBotUnconsumedIcon,
  asserterJson: asserterJsonIcon,
  asserterFactCheck: asserterFactCheckIcon,
  asserterMisuse: asserterMisuseIcon,
  asserterObjective: asserterObjectiveIcon,
  asserterLanguage: asserterLanguageIcon,
  logichookPause: logichookPauseIcon,
  logichookPartialConvo: logichookPartialConvoIcon,
  logichookAssignScripting: logichookAssignScriptingIcon,
  logichookSetScripting: logichookSetScriptingIcon,
  logichookClearScripting: logichookClearScriptingIcon,
  logichookWaitForBot: logichookWaitForBotIcon,
  logichookSkipBotUnconsumed: logichookSkipBotUnconsumedIcon,
  logichookPerfectoReporting: logichookPerfectoReportingIcon,
  logichookCustom: logichookCustomIcon,
  logichookHttp: logichookHttpIcon,
  conditionalLogichook: conditionalLogichookIcon,
  conditionalStart: conditionalStart,
  conditionalStartend: conditionalStartend,
  conditionalEnd: conditionalEnd,
  conditionalLine: conditionalLine,
  logichookVoipIgnoreSilenceDuration: logicHookVoipIgnoreSilenceDurationIcon,
  logichookVoipJoinBySilenceDuration: logicHookVoipJoinBySilenceDurationIcon,
  beginStep: beginStepIcon,
  endStep: endStepIcon,
  error: errorIcon,
  warning: warningIcon,
  stop: stopIcon,
  csvFile: csvFileIcon,
  jsonFile: jsonFileIcon,
  scriptingMemoryFile: scriptingMemoryFileIcon,
  yamlFile: yamlFileIcon,
  xlsFile: xlsFileIcon,
  convoError: convoErrorIcon,
  convoWarning: convoWarningIcon,
  utteranceError: utteranceErrorIcon,
  utteranceWarning: utteranceWarningIcon,
  partialConvoError: partialConvoErrorIcon,
  partialConvoWarning: partialConvoWarningIcon,
  success: successIcon,
  testcases: testcasesIcon,
  duration: durationIcon,
  info: infoIcon,
  userResponse: userResponseIcon,
  endConvo: endConvoIcon,
  highPrio: highPrioIcon,
  mediumPrio: mediumPrioIcon,
  lowPrio: lowPrioIcon,
  altIntent: altIntentIcon,
  primaryIntent: primaryIntentIcon,
  entity: entityIcon,
  attachmentVideo: attachmentVideoIcon,
  attachmentAudio: attachmentAudioIcon,
  attachmentImage: attachmentImageIcon,
  attachment: attachmentIcon,
  convoCardHeader: convoCardHeaderIcon,
  convoCardSubHeader: convoCardSubHeaderIcon,
  projects: projectsIcon,
  testtype: testTypeIcon,
  senderBot: senderBotIcon,
  senderUser: senderUserIcon,
  senderMisuse: senderMisuseIcon,
}

const style = theme => ({
  pointer: {
    cursor: 'pointer'
  },
  hidden: {
    visibility: 'hidden'
  },
  custom: {
    display: 'inline-block'
  },
  xxl: {
    fontSize: '120px',
    width: '118px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  xl: {
    fontSize: '50px',
    width: '48px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  lg: {
    fontSize: '37px',
    width: '35px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  md: {
    fontSize: '25px',
    width: '23px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  xs: {
    fontSize: '21px',
    width: '19px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  xxs: {
    fontSize: '12px',
    width: '10px',
    '& svg': {
      width: '100%',
      height: 'auto'
    }
  },
  moveleft: {
    position: 'relative',
    right: 13,
  },
  svgWhiteDark: {
    '& svg':{
      '& rect': {
        fill: 'transparent',
        stroke: theme.colors.primary
      },
      '& line': {
        fill: 'transparent',
        stroke: theme.colors.primary
      },
      '& path': {
        fill: 'transparent',
        stroke: theme.colors.primary
      }
    }
  },
  sideMenuIcon: {
    width: 20,
    height: 20,
    display: 'inline-block',
    textAlign: 'center'
  },
  sideDropDownMenuIcon: {
    width: 20,
    height: 20,
    display: 'inline-block',
    position: 'relative',
    top: 3,
  },
  positionRight: {
    float: 'right'
  },
  lightColor: {
    '& svg': {
      '& path': {
        fill: 'red'
      }
    }
  }
})

function ShowIcon({ classes, custom, pointer, xxl, xl, lg, md, xs, xxs, hidden, moveleft, svgWhiteDark, sideMenuIcon, sideDropDownMenuIcon, positionRight, lightColor, ...rest}) {
  if (custom) return customIcons[rest.icon] ? <div className={classNames({
    [classes.custom]: true,
    [classes.pointer]: pointer,
    [classes.hidden]: hidden,
    [classes.xxl]: xxl,
    [classes.xl]: xl,
    [classes.lg]: lg,
    [classes.md]: md,
    [classes.xs]: xs,
    [classes.xxs]: xxs,
    [classes.moveleft]: moveleft,
    [classes.svgWhiteDark]: svgWhiteDark,
    [classes.sideMenuIcon]: sideMenuIcon,
    [classes.sideDropDownMenuIcon]: sideDropDownMenuIcon,
    [classes.positionRight]: positionRight,
    [classes.lightColor]: lightColor,
  })}>{customIcons[rest.icon]}</div> : null
  return <FontAwesomeIcon className={classNames({
    [classes.pointer]: pointer,
    [classes.hidden]: hidden,
    [classes.xxl]: xxl,
    [classes.xl]: xl,
    [classes.lg]: lg,
    [classes.md]: md,
    [classes.xs]: xs,
    [classes.xxs]: xxs,
    [classes.moveleft]: moveleft,
  })} {...rest} fixedWidth />
}

export default withStyles(style)(ShowIcon)
