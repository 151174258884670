import React, { useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from 'components/List/ListItem/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Chip from 'components/Chip/Chip'
import ShowIcon from 'components/Icon/ShowIcon'
import AvatarImage from 'components/Avatar/AvatarImage'
import Text from 'components/Typography/Text'
import DropdownButton from 'components/Button/DropdownButton'
import objectChipsStyles from 'assets/jss/material-dashboard-react/components/objectChipsStyle.jsx'
import { hasPermission } from 'botium-box-shared/security/permissions'


function OptionalNavLink({ showLink, children, ...props }) {

  if (showLink) {
    return <NavLink {...props}>{children}</NavLink>
  }
  return <div {...props}>{children}</div>
}

function ObjectChips({ history, dataUniquePrefix = 'btn', testProject, factCheckerProject, chatbot, testSets, deviceSets, llmModel, hideHeader, singleChip, classes, lg, rootPath, user }) {

  const [testsetsOpen, setTestsetsOpen] = useState(false)
  const [devicesetsOpen, setDevicesetsOpen] = useState(false)

  return (<GridContainer>
    {testProject && testProject.id &&
      <GridItem md={singleChip ? 12 : 6} lg={3} grid>
        <NavLink
          style={{ display: 'contents' }}
          to={`${rootPath}/projects/view/${testProject.id}`}
          data-unique={`${dataUniquePrefix}TestProject_${testProject.id}/dashboard`}
        >
          <Card hoverlight>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem xs={12} middle>
                  <ListItem compactPadding small >
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="infinity" />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline subheader>Test Project</Text>
                      }
                      secondary={<Text inline bold>{testProject.name}</Text>} />
                    <ListItemIcon>

                      <Text primary>
                        <ShowIcon moveleft icon="angle-right" />
                      </Text>

                    </ListItemIcon>
                  </ListItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </NavLink>
      </GridItem>
    }
    {factCheckerProject && factCheckerProject.id &&
      <GridItem md={singleChip ? 12 : 6} lg={3} grid>
        <NavLink
          style={{ display: 'contents' }}
          to={`/factcheck/projects/view/${factCheckerProject.id}/dashboard`}
          data-unique={`${dataUniquePrefix}FactCheckerProject_${factCheckerProject.id}`}
        >
          <Card hoverlight>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem xs={12} middle>
                  <ListItem compactPadding small >
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="infinity" />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline subheader>Test Project</Text>
                      }
                      secondary={<Text inline bold>{factCheckerProject.name}</Text>} />
                    <ListItemIcon>

                      <Text primary>
                        <ShowIcon moveleft icon="angle-right" />
                      </Text>

                    </ListItemIcon>
                  </ListItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </NavLink>
      </GridItem>
    }
    {chatbot && chatbot.id &&
      <GridItem md={singleChip ? 12 : 6} lg={singleChip ? 12 : (lg || 3)} grid>
        <NavLink
          style={{ display: 'contents' }}
          to={`/chatbots/view/${chatbot.id}`}
          data-unique={`${dataUniquePrefix}Chatbot_${chatbot.id}`}
        >
          <Card hoverlight>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem xs={12} middle>
                  <ListItem compactPadding small >
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="chatbot" avatarImage={<AvatarImage variant="medium" avatar={chatbot.avatar} containermode={chatbot.containermode} chatbotId={chatbot.id} />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline>{!hideHeader && 'Chatbot Under Test'}</Text>
                      }
                      secondary={<Text inline bold>{chatbot.name}</Text>} />
                    <Text primary>
                      <ShowIcon moveleft icon="angle-right" />
                    </Text>
                  </ListItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </NavLink>
      </GridItem>
    }
    {testSets && testSets.length > 0 &&
      <GridItem md={singleChip ? 12 : 6} lg={singleChip ? 3 : (lg || 3)} grid>
        <OptionalNavLink
          style={{ cursor: 'pointer', display: 'contents' }}
          showLink={testSets.length === 1}
          onClick={() => setTestsetsOpen(!testsetsOpen)}
          to={testSets.length === 1 ? `/testsets/view/${testSets[0].id}` : null}
          data-unique={`${dataUniquePrefix}TestSet_${testSets[0].id}`}
        >
          <Card hoverlight>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem xs={12} middle>
                  <ListItem compactPadding small>
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline>
                          {testSets.length === 1 && 'Involved Test Set'}
                          {testSets.length > 1 && 'Involved Test Sets'}</Text>
                      }
                      secondary={<Text inline bold>{testSets[0].name} {testSets.length > 1 && ', ...'}</Text>} />
                    <ListItemIcon>
                      <div>
                        {testSets.length > 1 && <DropdownButton
                          open={testsetsOpen}
                          justIcon
                          items={testSets.map(ts => {
                            return {
                              id: ts.id,
                              name: ts.name,
                              onClick: () => {
                                history.push(`/testsets/view/${ts.id}`)
                              }
                            }
                          })}
                        >
                          <ShowIcon icon="angle-down" />
                        </DropdownButton>
                        }
                        {testSets.length === 1 &&
                          <Text primary>
                            <ShowIcon moveleft icon="angle-right" />
                          </Text>
                        }
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </GridItem>
              </GridContainer>

            </CardBody>
          </Card>
        </OptionalNavLink>
      </GridItem>
    }
    {deviceSets && deviceSets.length > 0 &&
      <GridItem md={singleChip ? 12 : 6} lg={3} grid>
        <OptionalNavLink
          style={{ cursor: 'pointer', display: 'contents' }}
          showLink={deviceSets.length === 1}
          onClick={() => setDevicesetsOpen(!devicesetsOpen)}
          to={deviceSets.length === 1 ? `/settings/devicecloud/devicesets/${deviceSets[0].id}` : null}
          data-unique={`${dataUniquePrefix}DeviceSet_${deviceSets[0].id}`}
        >
          <Card hoverlight>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem md={12} middle>
                  <ListItem compactPadding small>
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="folder-open" />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline subheader>
                          {deviceSets.length === 1 && 'Involved Device Set'}
                          {deviceSets.length > 1 && 'Involved Device Sets'}</Text>
                      }
                      secondary={<Text inline bold>{deviceSets[0].name} {deviceSets.length > 1 && ', ...'}</Text>} />
                    <ListItemIcon>
                      <div>
                        {deviceSets.length > 1 && <DropdownButton
                          open={devicesetsOpen}
                          justIcon
                          items={deviceSets.map(ds => {
                            return {
                              id: ds.id,
                              name: ds.name,
                              onClick: () => {
                                history.push(`/settings/devicecloud/devicesets/${ds.id}`)
                              }
                            }
                          })}
                        >
                          <ShowIcon icon="angle-down" />
                        </DropdownButton>
                        }
                        {deviceSets.length === 1 &&
                          <Text primary>
                            <ShowIcon moveleft icon="angle-right" />
                          </Text>
                        }
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </OptionalNavLink>
      </GridItem>
    }
    {llmModel &&
      <GridItem md={singleChip ? 12 : 6} lg={singleChip ? 12 : (lg || 3)} grid>
        <OptionalNavLink
          style={{
            cursor: hasPermission(user, 'SYSTEMSETTINGS_MANAGE') ? 'pointer' : 'default!Important',
            display: 'contents'
          }}
          showLink={hasPermission(user, 'SYSTEMSETTINGS_MANAGE')}
          to={hasPermission(user, 'SYSTEMSETTINGS_MANAGE') ? '/settings/system/aitrustsuite' : null}
          data-unique={`${dataUniquePrefix}LlmProvider_${llmModel.llmProvider}`}
        >
          <Card {...(hasPermission(user, 'SYSTEMSETTINGS_MANAGE') ? { hoverlight: true } : {})}>
            <CardBody noPadding tabIndex={0} className={classes.cardLink}>
              <GridContainer autoHeight>
                <GridItem md={12} middle>
                  <ListItem compactPadding small>
                    <ListItemIcon>
                      <Chip justIcon cursorPointer iconcolordarkblue variant="info" className={classes.positionAIIcon} icon={<ShowIcon custom icon="AI" />} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Text inline subheader>Used LLM Provider</Text>
                      }
                      secondary={<Text inline bold>{llmModel.llmProvider}</Text>} />
                      {hasPermission(user, 'SYSTEMSETTINGS_MANAGE') && (
                      <Text primary>
                        <ShowIcon moveleft icon="angle-right" />
                      </Text>
                      )}
                  </ListItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </OptionalNavLink>
      </GridItem>
    }
  </GridContainer>)
}

export default withStyles(objectChipsStyles)(withRouter(ObjectChips))
