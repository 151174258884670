import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ChipCore from '@material-ui/core/Chip'
import Tooltip from 'components/Tooltip/Tooltip'
import { Avatar } from '@material-ui/core'
import chipStyle from 'assets/jss/material-dashboard-react/components/chipStyle'
import ShowIcon from '../Icon/ShowIcon'
import classNames from 'classnames'

function Chip({ nobg, truncate, tooltip, classes, slim, slimmer,
  label, variant, namespace, regression, e2e, nlp, performance,
  gdpr, security, factchecker, misusechecker, highlight, disabled,
  avatar, avatarCharacter, avatarImage, justIcon, cursorPointer,
  iconcolordarkblue, disableTooltip, multiline, badge, href, history,
  staticContext, allowHtmlDisplay, logmonitoring, ...props }) {

  const tooltipLabel = (!tooltip || tooltip === '') ? label : tooltip

  const normalChip = {
    root: classes.root,
    label: classes.labelTruncated,
    avatar: classes.avatar,
    deleteIcon: classes.deleteIcon,
    selectIcon: classes.selectIcon,
    unselect: classes.unselect,
    avatarChildren: classes.avatarChildren
  }

  const infoChip = {
    root: classNames({
      [classes.infoRoot]: true,
      [classes.infoRootMultiline]: multiline,
      [classes.infoRootSlim]: slim,
      [classes.infoRootNamespace]: namespace,
      [classes.infoRootRegressionType]: regression,
      [classes.infoRootE2EType]: e2e,
      [classes.infoRootNlpType]: nlp,
      [classes.infoRootPerformanceType]: performance,
      [classes.infoRootGdprType]: gdpr,
      [classes.infoRootLogMonitoringType]: logmonitoring,
      [classes.infoRootFactCheckerType]: factchecker,
      [classes.infoRootMisuseCheckerType]: misusechecker,
      [classes.infoRootSecurityType]: security,
      [classes.infoRootSlimmer]: slimmer,
      [classes.infoRootHighlightError]: highlight,
      [classes.infoRootIcon]: justIcon,
      [classes.infoRootcursorPointer]: cursorPointer,
      [classes.infoRooticoncolordarkblue]: iconcolordarkblue,
      [classes.infoNobg]: nobg,
      [classes.disabled]: disabled
    }),
    label: classNames({
      [classes.labelTruncated]: true,
      [classes.labelMultiline]: multiline,
      [classes.infoRootSlimLabel]: slim,
      [classes.infoRootSlimmerLabel]: slimmer,
      [classes.infoRootSlimLabelError]: (slim || highlight) && variant === 'error',
      [classes.infoRootSlimLabelWarning]: (slim || highlight) && variant === 'warning',
      [classes.infoRootSlimLabelSuccess]: (slim || highlight) && variant === 'success',
      [classes.infoRootSlimLabelWhite]: (slim || highlight) && variant === 'white'
    }),
    avatar: slim ? '' : classes.infoAvatar,
    avatarChildren: classes.infoAvatarChildren
  }

  const getBadge = () => {
    if (badge) {
      return <span className={classes.badge}>{badge}</span>
    }
  }

  const getVariantAvatar = () => {
    if (variant === 'convo') {
      return <Avatar className={classes.infoConvo}>C</Avatar>
    }
    if (variant === 'utterance') {
      return <Avatar className={classes.infoUtterance}>U</Avatar>
    }
    if (variant === 'text') {
      return <Avatar className={classes.infoText}>{avatarCharacter || <ShowIcon custom icon="text" />}</Avatar>
    }
    if (variant === 'scripting') {
      return <Avatar className={classes.infoScripting}>S</Avatar>
    }
    if (variant === 'error' || variant === 'danger') {
      return <Avatar className={classes.infoError}>{avatarCharacter || '!'}</Avatar>
    }
    if (variant === 'warning') {
      return <Avatar className={classes.infoWarning}>{avatarCharacter || <ShowIcon icon="exclamation-triangle" />}</Avatar>
    }
    if (variant === 'success') {
      return <Avatar className={classes.infoSuccess}>{avatarCharacter || <ShowIcon icon="check-circle" />}</Avatar>
    }
    if (variant === 'namespace') {
      return <Avatar className={classes.infoNamespace}></Avatar>
    }
    if (variant === 'testsession') {
      return <Avatar><ShowIcon icon="tasks" /></Avatar>
    }
    if (variant === 'testproject') {
      return <Avatar><ShowIcon icon="infinity" /></Avatar>
    }
    if (variant === 'testset') {
      return <Avatar><ShowIcon icon="folder-open" /></Avatar>
    }
    if (variant === 'chatbot' && !avatar && !avatarImage) {
      return <Avatar><ShowIcon icon="robot" /></Avatar>
    }
    if (variant === 'searchAndReplace') {
      return <Avatar><ShowIcon icon="magnifying-glass" /></Avatar>
    }
    if (avatarImage) {
      return <Avatar>{avatarImage}</Avatar>
    }
    return avatar
  }

  const renderChip = () => <ChipCore
    classes={variant ? infoChip : normalChip}
    style={{ maxWidth: (truncate) ? truncate : '100%' }}
    label={_.isObject(label) ? label : <>{allowHtmlDisplay ? <span dangerouslySetInnerHTML={{ __html: label }} /> : <span dangerouslySetInnerHTML={{ __html: _.escape(label) }} />}{getBadge()}</>}
    avatar={variant && !slim ? getVariantAvatar() : avatar}
    {...(href ? { onClick: () => history.push(href) } : {})}
    {...props} />

  return disableTooltip ? renderChip() : <Tooltip title={tooltipLabel} {...props.key ? { key: props.key } : {}}>{renderChip()}</Tooltip>
}

export default withStyles(chipStyle)(withRouter(Chip))
