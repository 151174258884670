import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import skeletonStyle from 'assets/jss/material-dashboard-react/components/skeletonStyle'

class _RenderSkeletonChipsGenerate extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeletonPurple + ' ' + classes.skeletonChipShort} />
                <div className={classes.skeletonPurple + ' ' + classes.skeletonChipLong} />
                <div className={classes.skeletonPurple + ' ' + classes.skeletonChipShort} />
            </div>
        </div>
    }
}

export const RenderSkeletonChipsGenerate = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChipsGenerate)

class _RenderSkeletonTextShort extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonTextShort} />
                <div className={classes.skeleton + ' ' + classes.skeletonTextLong} />
            </div>
        </div>
    }
}

export const RenderSkeletonTextShort = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonTextShort)

class _RenderSkeletonConvoEditorContent extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer + ' ' + classes.marginTopsm}>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonSwitchButton} />
                <div className={classes.skeleton + ' ' + classes.skeletonConvoName} />
            </div>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonTabelBoxHigh + ' ' + classes.marginTop} />
            </div>
        </div>
    }
}

export const RenderSkeletonConvoEditorContent = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonConvoEditorContent)

class _RenderSkeletonConvoEditor extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer + ' ' + classes.marginTop}>
            <div className={classes.skeletonContainerFullwidth}>
                {[...Array(5)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div key={index} className={classes.skeleton + ' ' + classes.skeletonTopMenuRight} />
                    </React.Fragment>
                ))}
            </div>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonTabelBoxHigh} />
            </div>
        </div>
    }
}

export const RenderSkeletonConvoEditor = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonConvoEditor)

class _RenderSkeletonThreeBlocks extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer + ' ' + classes.marginTop}>
            <div className={classes.skeletonContainerFullwidth + ' ' + classes.skeletonFlex}>
                {[...Array(3)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div key={index} className={classes.skeleton + ' ' + classes.skeletonTabelBoxHigh} />
                    </React.Fragment>
                ))}
            </div>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonNLPSuggestionLow} />
            </div>
        </div>
    }
}

export const RenderSkeletonThreeBlocks = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonThreeBlocks)

class _RenderSkeletonNLPSuggestion extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer + ' ' + classes.marginTop}>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonNLPSuggestionLow} />
                <div className={classes.skeleton + ' ' + classes.skeletonNLPSuggestionHigh} />
            </div>
        </div>
    }
}

export const RenderSkeletonNLPSuggestion = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonNLPSuggestion)

class _RenderSkeletonChartBox extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                <div className={classes.skeleton + ' ' + classes.skeletonChartBox} />
            </div>
        </div>
    }
}

export const RenderSkeletonChartBox = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChartBox)

class _RenderSkeletonAdvancedTable extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                {[...Array(3)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div key={index} className={classes.skeleton + ' ' + classes.skeletonTabelRowLow} />
                    </React.Fragment>
                ))}
            </div>
        </div>
    }
}

export const RenderSkeletonAdvancedTable = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonAdvancedTable)

class _RenderSkeletonTestCases extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                {[...Array(3)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div key={index} className={classes.skeleton + ' ' + classes.skeletonTabelRowHigh} />
                    </React.Fragment>
                ))}
            </div>
        </div>
    }
}

export const RenderSkeletonTestCases = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonTestCases)

class _RenderSkeletonSmallTable extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer}>
            <div className={classes.skeletonContainerFullwidth}>
                {[...Array(4)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div key={index} style={{marginBottom: 8}}>
                            <div className={classes.skeleton + ' ' + classes.skeletonCheck} />
                            <div className={classes.skeleton + ' ' + classes.skeletonTableHeader + ' ' + classes.skeletonMarginRightBig} />
                            <div className={classes.skeleton + ' ' + classes.skeletonTableHeader + ' ' + classes.skeletonMarginRightSmall} />
                        </div>
                    </React.Fragment>    
                ))}
            </div>
        </div>
    }
}

export const RenderSkeletonSmallTable = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonSmallTable)



class _RenderSkeletonProjectMenu extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainer + ' ' + classes.skeletonContainerBox}>
        <div className={classes.skeleton + ' ' + classes.skeletonTopMenu} />
    </div>
    }
}

export const RenderSkeletonProjectMenu = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonProjectMenu)

class _RenderSkeletonHeaderTitle extends React.Component {
    render() {
        const { classes, noTestype } = this.props

        return <div className={classes.skeletonContainerLeft + ' ' + classes.skeletonContainerBox}>
                <div className={classes.skeleton + ' ' + classes.skeletonHeaderTitle} />
                {!noTestype && <div className={classes.skeleton + ' ' + classes.skeletonTesttype} />}
            </div>
    }
}

export const RenderSkeletonHeaderTitle = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonHeaderTitle)

class _RenderSkeletonHeaderTitleLow extends React.Component {
    render() {
        const { classes } = this.props

        return <div className={classes.skeletonContainerLeft + ' ' + classes.skeletonContainerBoxLow}>
                <div className={classes.skeleton + ' ' + classes.skeletonHeaderTitle} />
            </div>
    }
}

export const RenderSkeletonHeaderTitleLow = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonHeaderTitleLow)

class _RenderSkeletonChartBarLine extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonContainerFullwidth + ' ' + classes.barAlignItems} style={{ position: 'relative', marginTop: 120 }}>
                <svg 
                    width="100%" 
                    height="50" 
                    viewBox="0 0 660 50" 
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: 'absolute', top: '-50px', left: '0' }}
                >
                    <path 
                        className={classes.skeletonLine}
                        d="M0,30 Q110,5 220,30 T440,30 T660,30"   
                        strokeWidth="2" 
                        fill="none" 
                    />
                </svg>
                    <div className={classes.skeleton + ' ' + classes.skeletonBarLine} />
                    <div className={classes.skeleton + ' ' + classes.skeletonBarLine} />
                    <div className={classes.skeleton + ' ' + classes.skeletonBarLine} />
                </div>
                <div className={classes.skeleton + ' ' + classes.skeletonChartLine} />
            </div>
        </>)
    }
}

export const RenderSkeletonChartBarLine = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChartBarLine)

class _RenderSkeletonChartStackedBar extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonContainerFullwidth + ' ' + classes.barAlignItems} >
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar} />
                </div>
                <div className={classes.skeleton + ' ' + classes.skeletonChartStackedLine} />
                <div className={classes.skeletonContainerFullwidth + ' ' + classes.barAlignItems} >
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar + ' ' + classes.skeletonOpacity} />
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar + ' ' + classes.skeletonOpacity} />
                    <div className={classes.skeleton + ' ' + classes.skeletonStackedBar + ' ' + classes.skeletonOpacity} />
                </div>
            </div>
        </>)
    }
}

export const RenderSkeletonChartStackedBar = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChartStackedBar)

class _RenderSkeletonChartBar extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonContainerFullwidth + ' ' + classes.barAlignItems} >
                    <div className={classes.skeleton + ' ' + classes.skeletonBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonBar} />
                </div>
                <div className={classes.skeleton + ' ' + classes.skeletonChartLine} />
            </div>
        </>)
    }
}

export const RenderSkeletonChartBar = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChartBar)

class _RenderSkeletonChartDot extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonContainerFullwidth + ' ' + classes.paddingTopBottom + ' ' + classes.barAlignItems} >
                    <div className={classes.skeleton + ' ' + classes.skeletonDotBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonDotBar} />
                    <div className={classes.skeleton + ' ' + classes.skeletonDotBar} />
                </div>
                <div className={classes.skeleton + ' ' + classes.skeletonChartLine} />
            </div>
        </>)
    }
}

export const RenderSkeletonChartDot = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChartDot)

class _RenderSkeletonChatbotHistory extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonContainerFullwidth} >
                    <div className={classes.skeleton + ' ' + classes.skeletonLeftDropdown} />
                    <div className={classes.skeleton + ' ' + classes.skeletonRightDropdown} />
                </div>
            </div>
        </>)
    }
}

export const RenderSkeletonChatbotHistory = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonChatbotHistory)

class _RenderSkeletonNews extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeletonParentNews}>
                    {[...Array(4)].map((_, index) => (
                        <React.Fragment key={index}>
                            <div className={classes.skeletonNews}>
                                <div className={classes.skeleton + ' ' + classes.skeletonNewsImage} />
                                <div className={classes.skeletonNewsTitle}>
                                    <div className={classes.skeleton + ' ' + classes.skeletonNewsText} />
                                    <div className={classes.skeleton + ' ' + classes.skeletonNewsText} />
                                </div>
                                <div className={classes.skeletonNewsContent}>
                                    <div className={classes.skeleton + ' ' + classes.skeletonNewsText} />
                                    <div className={classes.skeleton + ' ' + classes.skeletonNewsText} />
                                    <div className={classes.skeleton + ' ' + classes.skeletonNewsText} />
                                </div>
                                <div className={classes.skeleton + ' ' + classes.skeletonNewsSmallbutton} />
                            </div>
                        </React.Fragment>
                   ))}
                </div>
            </div>
        </>)
    }
}

export const RenderSkeletonNews = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonNews)

class _RenderSkeletonTable extends React.Component {
    render() {
        const { classes } = this.props

        return (<>
            <div className={classes.skeletonContainer}>
                <div className={classes.skeleton + ' ' + classes.skeletonTableFilter} />
                <div className={classes.skeletonContainerFullwidth} style={{marginBottom: 28}}>
                    <div className={classes.skeleton + ' ' + classes.skeletonTableHeader + ' ' + classes.skeletonMarginRightBig} />
                    <div className={classes.skeleton + ' ' + classes.skeletonTableHeader + ' ' + classes.skeletonMarginRightSmall} />
                    <div className={classes.skeleton + ' ' + classes.skeletonTableHeader} />
                </div>
                {[...Array(5)].map((_, index) => (
                    <React.Fragment key={index}>
                        <div className={classes.skeleton + ' ' + classes.skeletonTabelRowHigh} />
                    </React.Fragment>
                ))}
            </div>
        </>)
    }
}

export const RenderSkeletonTable = withStyles(skeletonStyle, { withTheme: true })(_RenderSkeletonTable)
