import {
  defaultFont,
  infoColor,
  successColor,
  lightgreenColor,
  warningColor,
  dangerColor,
  whiteColor,
  lowRisk,
  defaultBoxShadow
} from 'assets/jss/material-dashboard-react.jsx'

const typographyStyle = theme => ({
  defaultFontStyle: {
    ...defaultFont,
    color: theme.colors.primary,
    fontSize: '12px',
    '& a': {
      color: theme.colors.primary,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:focus-visible': {
        background: theme.colors.linkButtonHover,
        borderRadius: 5
      },
    },
  },
  defaultHeaderMargins: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  inline: {
    display: 'inline'
  },
  inlineTable: {
    display: 'inline-table'
  },
  flex: {
    display: 'flex'
  },
  quote: {
    padding: '10px 20px',
    margin: '0 0 20px',
    fontSize: '17.5px',
    borderLeft: '5px solid #eee',
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
  quoteAuthor: {
    display: 'block',
    fontSize: '80%',
    lineHeight: '1.42857143',
    color: '#777',
  },

  mutedText: {
    fontSize: '12px',
    color: theme.colors.primary50,
    '& a': {
      color: theme.colors.primary50,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  primaryText: {
    fontSize: '12px',
    color: theme.colors.primary,
    '& a': {
      color: theme.colors.primary,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    },
  },
  primaryCommonText: {
    fontSize: '12px',
    color: theme.colors.common.primary,
    '& a': {
      color: theme.colors.common.primary,
      textDecoration: 'underline'
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  infoText: {
    fontSize: '12px',
    color: theme.colors.primary,
    '& a': {
      color: theme.colors.primary,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  infoCommonText: {
    fontSize: '12px',
    color: theme.colors.common.info,
    '& a': {
      color: theme.colors.common.info,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  successText: {
    fontSize: '12px',
    color: successColor,
    '& a': {
      color: successColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  warningText: {
    fontSize: '12px',
    color: warningColor,
    '& a': {
      color: warningColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  lowSeverityText: {
    fontSize: '12px',
    color: lightgreenColor,
    '& a': {
      color: lightgreenColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  dangerText: {
    fontSize: '12px',
    color: dangerColor,
    '& a': {
      color: dangerColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  dangerTextBg: {
    backgroundColor: dangerColor,
    fontSize: '12px',
    color: whiteColor,
    display: 'inline-block',
    borderRadius: 3,
    padding: '4px 10px',
    fontWeight: 500,
    '& svg': {
      paddingRight: 5
    }
  },
  lowSeverityTextBg: {
    backgroundColor: lowRisk,
    fontSize: '12px',
    color: theme.colors.common.primary,
    display: 'inline-block',
    borderRadius: 3,
    padding: '4px 10px',
    fontWeight: 500,
    '& svg': {
      paddingRight: 5
    }
  },
  warningTextBg: {
    backgroundColor: warningColor,
    fontSize: '12px',
    color: theme.colors.common.primary,
    display: 'inline-block',
    borderRadius: 3,
    padding: '4px 10px',
    fontWeight: 500,
    '& svg': {
      paddingRight: 5
    }
  },
  successTextBg: {
    backgroundColor: successColor,
    fontSize: '12px',
    color: whiteColor,
    display: 'inline-block',
    borderRadius: 3,
    padding: '4px 10px',
    fontWeight: 500,
    '& svg': {
      paddingRight: 5
    }
  },
  whiteText: {
    fontSize: '12px',
    color: whiteColor,
    '& a': {
      color: whiteColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  dangerBox: {
    ...defaultBoxShadow,
    fontSize: '12px',
    backgroundColor: theme.colors.danger,
    borderRadius: 5,
    fontWeight: '300',
    padding: 10,
    margin: 12,
    color: 'white',
    whiteSpace: 'pre-line',
  },
  importantText: {
    color: theme.colors.primary,
    fontSize: '12px',
    fontWeight: 'bold',
    '& a': {
      color: infoColor,
      textDecoration: 'underline',
    },
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  preText: {
    whiteSpace: 'pre-wrap'
  },
  centerText: {
    textAlign: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  bold500: {
    fontWeight: '500'
  },
  regular: {
    fontWeight: 'normal'
  },
  italic: {
    fontStyle: 'italic'
  },
  extralg: {
    fontSize: '41px!Important',
    lineHeight: 1,
  },
  mlg: {
    fontSize: '26px!Important',
    lineHeight: 1,
  },
  lg: {
    fontSize: 18
  },
  md: {
    fontSize: 14
  },
  sm: {
    fontSize: 10
  },
  span: {
    display: 'inline-block'
  },
  linebreak: {
    lineBreak: 'anywhere'
  },
  padding: {
    padding: 10
  },
  paddingBottom: {
    paddingBottom: 25,
  },
  topMargin: {
    marginTop: 55,
  },
  topMarginmd: {
    marginTop: 25,
  },
  topMarginsm: {
    marginTop: 10,
  },
  topMarginxs: {
    marginTop: 5,
  },
  bottomMargin: {
    marginBottom: 35,
  },
  bottomMarginsm: {
    marginBottom: 5,
  },
  rightMarginmd: {
    marginRight: 25
  },
  rightMarginsm: {
    marginRight: 15
  },
  rightMarginxs: {
    marginRight: 5
  },
  leftMarginxs: {
    marginLeft: 5
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  helperText: {
    paddingLeft: 3,
    paddingRight: 0,
    marginBottom: 10,
    fontSize: 12,
    color: `${theme.colors.inputlabel} !important`,
    '& a': {
      color: `${theme.colors.primary} !important`,
      fontWeight: 'bold'
    }
  },
  labelText: {
    fontSize: 12,
    color: `${theme.colors.inputlabel} !important`,
  },
  dropdownLabel: {
    fontWeight: 'bold',
    margin: '15px 0 5px 0'
  },
  bggrey: {
    backgroundColor: theme.colors.testsetseditorbg,
    padding: '5px 10px'
  },
  bgradius: {
    borderRadius: 5
  },
  horizontalCenter: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  noUnderline: {
    textDecoration: 'none!Important'
  },
  contentCenter: {
    display: 'contents'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  paddingLeftRight: {
    padding: '0 15px'
  },
  smLineHeight: {
    lineHeight: 1
  },
  linkUnderline: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  marginRight: {
    marginRight: 8
  },
  displayInline: {
    display: 'inline-block'
  },
  displayBlock: {
    display: 'block'
  },
  iconRotation: {
    '& svg': {
      animation: 'rotation infinite 1s linear',
      margin: '0 4px 0 -1px',
      padding: 0
    },
  },
  navHeader: {
    fontSize: 21
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  fixWidth70: {
    width: '70px',
    textAlign: 'center'
  },
  misuse: {
    color: theme.colors.misusecheckChips,
    '& path': {
    fill: theme.colors.misusecheckChips,
    }
  },
  '@keyframes rotation': {
    'from': {
      transform:'rotate(0deg)',
    },
    'to':{
      transform:'rotate(360deg)',
    }
  },
})

export default typographyStyle
