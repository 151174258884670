import React from 'react'
import {connect} from 'react-redux'
import {NavLink, Route, Switch, withRouter} from 'react-router-dom'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import RefreshIcon from '@material-ui/icons/Refresh'
import {Form} from 'react-final-form'
import Field from 'components/Form/OptionalField'
import arrayMutators from 'final-form-arrays'
import config from 'config'
// apollo
import {compose, graphql, Mutation, Query} from 'react-apollo'
// core components
import Chip from 'components/Chip/Chip'
import SimpleList from 'components/List/SimpleList.jsx'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import DropdownButton from 'components/Button/DropdownButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardHeaderActions from 'components/Card/CardHeaderActions.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import ConfidenceGaugeChart from 'components/Stats/Charts/ConfidenceGaugeChart'
import TestSetImport from './TestSetImport.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Table from 'components/Table/AdvancedTable.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import {
  composeValidators,
  CustomCheckbox,
  CustomSelect,
  FormActionsToolbar,
  jsonpath,
  maxLength,
  maxValue,
  minValue,
  parseInteger,
  renderCheckbox,
  renderIntField,
  renderNamespaceField,
  renderSelect,
  renderSlider,
  renderTagField,
  renderTextArea,
  renderTextField,
  required,
  url
} from 'components/Form/Form'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import {setTestCaseFilter} from 'actions/testcasefilter.js'
import {removeRecentListEntry} from 'actions/activity'
import {getTablePaginationVariables} from 'actions/table'
import {formatCreatedByAndLastChange, formatLastChange} from 'helper/authHelper'
import {extractErrorMessage} from 'helper/graphHelper'
import {safeGetNamespaceFilteredList} from '../helper'

import ShowIcon from 'components/Icon/ShowIcon'
import {faGitSquare} from '@fortawesome/free-brands-svg-icons'

import TestSetUtterance from './TestSetUtterance.jsx'
import TestSetConvo from './TestSetConvo.jsx'
import TestSetRepository from './TestSetRepository.jsx'
import TestSetFolder from './TestSetFolder.jsx'
import TestSetDownloadLink from './TestSetDownloadLink.jsx'
import TestSetInsights from './TestSetInsights.jsx'
import ConvosTree from 'components/Convo/ConvosTree'
import TestSessionProgress from '../TestSessions/TestSessionProgress.jsx'
import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'
import FileBrowser from '../Settings/FileBrowser.jsx'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  getCompiledItemHref,
  getCompiledItemIconLink,
  getCompiledItemLink,
  MATCHING_MODES,
  splitDateInfo
} from './helper'

import {
  BULK_CLONE_TESTSETSCRIPT,
  BULK_COPY_TESTSETSCRIPT,
  CLONE_TESTSET_REFERENCES,
  CLONE_TESTSETSCRIPT,
  COPY_TESTSETEXCEL,
  COPY_TESTSETSCRIPT,
  CREATE_TESTSET,
  DELETE_TESTSET,
  DELETE_TESTSETEXCEL,
  DELETE_TESTSETSCRIPT,
  DELETE_TESTSETSCRIPTS,
  EXPORT_TESTSET_TO_FOLDER,
  IMPORT_TESTSET_FROM_FILE,
  RefetchTestSetQueries,
  RefetchTestSetQueriesOnNewTestSession,
  TESTSET_COMPILEDCONVOS_QUERY,
  TESTSET_COMPILEDUTTERANCES_QUERY,
  TESTSET_EDITABLE_CONVO_DELETE,
  TESTSET_EDITABLE_QUERY,
  TESTSET_EDITABLE_UTTERANCE_DELETE,
  TESTSET_QUERY,
  TESTSET_SCRIPT_BULK_DELETE,
  TESTSET_STATS_QUERY,
  TESTSET_TESTPROJECTS_QUERY,
  TESTSET_TREE_QUERY,
  TESTSETCOACHSESSION_QUERY,
  TESTSETCOACHSESSION_SUBSCRIPTION,
  TESTSETS_DROPDOWN_QUERY,
  TESTSETSOURCES_EXCELS_QUERY,
  TESTSETSOURCES_REMOTE_QUERY,
  TESTSETSOURCES_SCRIPTS_QUERY,
  UPDATE_TESTSET,
  UPDATE_TESTSETEXCEL,
  UPDATE_TESTSETSTATS
} from './gql'
import {RefetchTestProjectQueriesOnNewTestSession, START_TESTPROJECT} from '../TestProjects/gql'
import {DeleteTestSessionListsFromCache, RefetchTestSessionQueries} from '../TestSessions/gql'
import {TAGS_QUERY} from '../Settings/gql'
import {
  canReadNamespace,
  canWriteNamespace,
  hasAnyPermission,
  hasPermission
} from 'botium-box-shared/security/permissions'
import StatsText from 'components/Stats/StatsText.jsx'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import Text from 'components/Typography/Text.jsx'
import CheckboxDialog from 'components/Dialog/CheckboxDialog.jsx'
import TestSetScript from './TestSetScript.jsx'
import TestSetExcel from './TestSetExcel.jsx'
import {downloadfileformpost} from 'helper/downloadHelper.js'
import QueryStatus from 'components/Info/QueryStatus'
import sanitize from 'sanitize-filename'
import slugify from 'slugify'
import ListItem from 'components/List/ListItem/ListItem.jsx'
import Divider from 'components/Divider/Divider.js'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import moment from 'moment'
import GeneralTestProjectsEmbeddedTable from 'views/TestProjects/GeneralTestProjectsEmbeddedTable.jsx'
import DropdownCheckbox from '../../components/Button/DropdownCheckbox'
import { RenderSkeletonProjectMenu, RenderSkeletonConvoEditor } from 'components/Skeleton/skeletonHelper'
import selecttestcase from 'assets/img/select-testcase.png'


class TestSet extends React.Component {
  constructor(props) {
    super(props)

    if (_.isNil(props.testcasefilter.utterances)) {
      props.setTestCaseFilter('utterances', true)
    }
    if (_.isNil(props.testcasefilter.convos)) {
      props.setTestCaseFilter('convos', true)
    }
    if (_.isNil(props.testcasefilter.partialconvos)) {
      props.setTestCaseFilter('partialconvos', true)
    }
    if (_.isNil(props.testcasefilter.parameterstores)) {
      props.setTestCaseFilter('parameterstores', true)
    }
    if (_.isNil(props.testcasefilter.excel)) {
      props.setTestCaseFilter('excel', true)
    }
    if (_.isNil(props.testcasefilter.others)) {
      props.setTestCaseFilter('others', true)
    }

    this.state = {
      showCloneDialog: false,
      showCloneError: null,
      showExportToFolderDialog: false,
      showExportToFolderError: null,
      selectedFolder: null,
      showExportToGitDialog: false,
      showExportToGitError: null,
      selectedRepository: null,
      testsetUploading: false,
      showImportFromZipDialog: false,
      showImportFromZipError: null,
      showImportFromZipFileLoaded: false,
      showMediaDirectoryDialog: false,
      showTreeDialog: false,
      newCounter: 0,
      selectedScript: null,
      selectedTestSet: null,
      showUtteranceDeleteDialog: false,
      showUtteranceCloneDialog: false,
      showUtteranceCopyDialog: false,
      showUtteranceMoveDialog: false,
      showConvoDeleteDialog: false,
      showConvoCloneDialog: false,
      showConvoCopyDialog: false,
      showScriptBulkDeleteDialog: false,
      showScriptBulkCloneDialog: false,
      showScriptBulkCopyDialog: false,
      showScriptBulkMoveDialog: false,
      copyUtterances: true,
      copyPartialConvos: true,
      showConvoMoveDialog: false,
      showScriptDeleteDialog: false,
      showScriptCopyDialog: false,
      showScriptMoveDialog: false,
      showExcelDeleteDialog: false,
      showExcelCopyDialog: false,
      showExcelMoveDialog: false,
      testCaseViewMode: 'ui',
      newTestSessionCount: 0,
      selectedItems: []
    }
    this.recorder = null
    this.setParentState = this.setParentState.bind(this)
  }

  setParentState(state) {
    this.setState(state)
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  renderDependenciesSelection() {
    return (
      <Query query={TESTSETS_DROPDOWN_QUERY}>
        {({ loading, error, data }) => {
          return (
            <Field
              name="dependencies"
              component={renderSelect}
              label="Select Dependencies"
              data-unique="selTestSetDependencySelection"
              disabled={!this.hasWritePermission()}
              loading={loading}
              error={error}
              multiple
              valueKeyMap={t => t.id}
              items={data && data.testsets && data.testsets.map(t => {
                return {
                  key: t.id,
                  label: t.name,
                  value: t,
                }
              })}
              helperText="When running tests with this test set, all test sets in this list are loaded as well"
            />
          )
        }}
      </Query>
    )
  }

  renderTreeDialog(testSetId, testSetName) {
    const { showTreeDialog } = this.state

    return <ConfirmationDialog
      fullScreen
      open={showTreeDialog}
      title="Flow Chart"
      onCancel={() => this.setState({ showTreeDialog: false })}
      cancelText="Close"
    >
      <GridContainer>
        <GridItem xs={12}>
          {this.renderTree(testSetId, testSetName)}
        </GridItem>
      </GridContainer>
    </ConfirmationDialog>
  }

  renderForm(testset) {
    const { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry, history, user, license } = this.props

    const _renderFormWrapper = (renderChildSectionFn) =>
      <Mutation
        mutation={testset.id ? UPDATE_TESTSET : CREATE_TESTSET}
        refetchQueries={[
          ...RefetchTestSetQueries(testset.id, license),
          {
            query: TAGS_QUERY
          }
        ]}
      >
        {(mutateTestSet, { loading, error }) => (
          <Form
            mutators={{ ...arrayMutators }}
            onSubmit={async (values, form) => {
              if (testset.id) {
                try {
                  await mutateTestSet({
                    variables: {
                      id: values.id,
                      testSet: {
                        name: values.name,
                        description: values.description || null,
                        namespace: values.namespace || null,
                        tags: {
                          set: values.tags,
                        },
                        useMatchingMode: values.useMatchingMode || null,
                        useMatchingModeWer: values.useMatchingModeWer || null,
                        expandConvos: values.expandConvos,
                        expandConvosMode: values.expandConvosMode || null,
                        expandConvosModeRandomCount: values.expandConvosModeRandomCount,
                        expandUtterancesToConvos: values.expandUtterancesToConvos,
                        exportUtterancesUseNameAsIntent: values.exportUtterancesUseNameAsIntent,
                        expandUtterancesIncomprehension: values.expandUtterancesIncomprehension || null,
                        // until we have just two types we can use checkbox in the UI.
                        expandUtterancesNaming: values.expandUtterancesNaming ? 'UTTEXPANSION_NAMING_MODE_UTTERANCE' : 'UTTEXPANSION_NAMING_MODE_JUST_LINE_NUMBER',
                        expandUtterancesNamingUtteranceMax: values.expandUtterancesNamingUtteranceMax || null,
                        useScriptingMemory: values.useScriptingMemory,
                        useScriptingMemoryMatchingMode: values.useScriptingMemoryMatchingMode || null,
                        expandScriptingMemory: values.expandScriptingMemory,
                        normalizeText: values.normalizeText,
                        normalizeTextRemoveChars: values.normalizeTextRemoveChars || null,
                        normalizeTextRemoveRegexp: values.normalizeTextRemoveRegexp || null,
                        skipAssertErrors: values.skipAssertErrors,
                        skipTestCasesPatterns: values.skipTestCasesPatterns || null,
                        excelHasConvos: values.excelHasConvos,
                        excelHasPartialConvos: values.excelHasPartialConvos,
                        excelHasUtterances: values.excelHasUtterances,
                        excelHasScriptingMemory: values.excelHasScriptingMemory,
                        excelWorksheetsConvos: values.excelWorksheetsConvos || null,
                        excelWorksheetsPartialConvos: values.excelWorksheetsPartialConvos || null,
                        excelWorksheetsUtterances: values.excelWorksheetsUtterances || null,
                        excelWorksheetsScriptingMemory: values.excelWorksheetsScriptingMemory || null,
                        excelStartRow: values.excelStartRow,
                        excelStartCol: values.excelStartCol,
                        excelHasHeader: values.excelHasHeader,
                        excelHasNameCol: values.excelHasNameCol,
                        jsonCheckerPath: values.jsonCheckerPath || null,
                        jsonRootPath: values.jsonRootPath || null,
                        jsonUtteranceRefPath: values.jsonUtteranceRefPath || null,
                        jsonUtterancesPath: values.jsonUtterancesPath || null,
                        markdownMode: values.markdownMode || null,
                        mediaBaseUri: values.mediaBaseUri || null,
                        mediaBaseDir: values.mediaBaseDir || null,
                        mediaUsageType: values.mediaUsageType || null,
                        csvDelimiter: values.csvDelimiter || null,
                        csvUseHeader: values.csvUseHeader,
                        csvQuote: values.csvQuote || null,
                        csvEscape: values.csvEscape || null,
                        csvUtteranceStartrow: values.csvUtteranceStartrow || null,
                        csvUtteranceStartrowHeader: values.csvUtteranceStartrowHeader || null,
                        csvUtteranceStopOnEmpty: values.csvUtteranceStopOnEmpty || null,
                        selectionType: values.selectionType || null,
                        statsSkipCoach: values.statsSkipCoach || false,
                        statsSkipCoachAdvanced: values.statsSkipCoachAdvanced || false,
                        dependencies: {
                          set: (values.dependencies || []).map(d => ({ id: d.id }))
                        }
                      },
                    },
                  })
                  setAlertSuccessMessage('Test Set updated')
                } catch (error) {
                  setAlertErrorMessage(`Test Set update failed`, error)
                }
              } else {
                try {
                  const res = await mutateTestSet({
                    variables: {
                      testSet: {
                        name: values.name,
                        description: values.description || null,
                        namespace: values.namespace || null,
                        tags: {
                          set: values.tags,
                        },
                        useMatchingMode: values.useMatchingMode || null,
                        useMatchingModeWer: values.useMatchingModeWer || null,
                        expandConvos: values.expandConvos,
                        expandConvosMode: values.expandConvosMode || null,
                        expandConvosModeRandomCount: values.expandConvosModeRandomCount,
                        expandUtterancesToConvos: values.expandUtterancesToConvos,
                        exportUtterancesUseNameAsIntent: values.exportUtterancesUseNameAsIntent,
                        expandUtterancesIncomprehension: values.expandUtterancesIncomprehension || null,
                        // until we have just two types we can use checkbox in the UI.
                        expandUtterancesNaming: values.expandUtterancesNaming ? 'UTTEXPANSION_NAMING_MODE_UTTERANCE' : 'UTTEXPANSION_NAMING_MODE_JUST_LINE_NUMBER',
                        expandUtterancesNamingUtteranceMax: values.expandUtterancesNamingUtteranceMax || null,
                        useScriptingMemory: values.useScriptingMemory,
                        useScriptingMemoryMatchingMode: values.useScriptingMemoryMatchingMode || null,
                        expandScriptingMemory: values.expandScriptingMemory,
                        normalizeText: values.normalizeText,
                        normalizeTextRemoveChars: values.normalizeTextRemoveChars || null,
                        normalizeTextRemoveRegexp: values.normalizeTextRemoveRegexp || null,
                        skipAssertErrors: values.skipAssertErrors,
                        skipTestCasesPatterns: values.skipTestCasesPatterns || null,
                        excelHasConvos: values.excelHasConvos,
                        excelHasPartialConvos: values.excelHasPartialConvos,
                        excelHasUtterances: values.excelHasUtterances,
                        excelWorksheetsConvos: values.excelWorksheetsConvos || null,
                        excelWorksheetsPartialConvos: values.excelWorksheetsPartialConvos || null,
                        excelWorksheetsUtterances: values.excelWorksheetsUtterances || null,
                        excelStartRow: values.excelStartRow,
                        excelStartCol: values.excelStartCol,
                        excelHasHeader: values.excelHasHeader,
                        excelHasNameCol: values.excelHasNameCol,
                        jsonCheckerPath: values.jsonCheckerPath || null,
                        jsonRootPath: values.jsonRootPath || null,
                        jsonUtteranceRefPath: values.jsonUtteranceRefPath || null,
                        jsonUtterancesPath: values.jsonUtterancesPath || null,
                        markdownMode: values.markdownMode || null,
                        mediaBaseUri: values.mediaBaseUri || null,
                        mediaBaseDir: values.mediaBaseDir || null,
                        mediaUsageType: values.mediaUsageType || null,
                        csvDelimiter: values.csvDelimiter || null,
                        csvUseHeader: values.csvUseHeader,
                        csvQuote: values.csvQuote || null,
                        csvEscape: values.csvEscape || null,
                        csvUtteranceStartrow: values.csvUtteranceStartrow || null,
                        csvUtteranceStartrowHeader: values.csvUtteranceStartrowHeader || null,
                        csvUtteranceStopOnEmpty: values.csvUtteranceStopOnEmpty || null,
                        selectionType: values.selectionType || null,
                        statsSkipCoach: values.statsSkipCoach || false,
                        statsSkipCoachAdvanced: values.statsSkipCoachAdvanced || false,
                        dependencies: {
                          connect: (values.dependencies || []).map(d => ({ id: d.id }))
                        }
                      },
                    },
                  })
                  form.initialize(res.data.createTestSet)
                  setAlertSuccessMessage('Test Set registered')
                  history.push(`/testsets/view/${res.data.createTestSet.id}`)
                } catch (error) {
                  setAlertErrorMessage(`Test Set registration failed`, error)
                }
              }
            }}
            initialValues={{
              ...testset,
              // until we have just two types we can use checkbox in the UI.
              expandUtterancesNaming: testset.expandUtterancesNaming === 'UTTEXPANSION_NAMING_MODE_UTTERANCE'
            }}
            render={(formArgs) => <form onSubmit={formArgs.handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem lg={8}>
                  {renderChildSectionFn(formArgs)}
                </GridItem>
              </GridContainer>
            </form>}
          />
        )}
      </Mutation>

    const renderSubmitButton = ({ submitting, invalid, pristine }) => (
      <GridItem xs={12} largePadding>
        <FormActionsToolbar
          leftButtons={<>
            {hasPermission(user, 'TESTSETS_CREATE') &&
              <Button
                onClick={() => {
                  this.setState({ showCloneDialog: true, showCloneError: null })
                }}
                data-unique="btnTestSetClone"
                secondary
              >
                <ShowIcon icon="clone" />
                Clone Test Set
              </Button>
            }
          </>}
          rightButtons={<>
            {this.hasWritePermission() &&
              <Button
                type="submit"
                disabled={submitting || invalid || pristine}
                data-unique="btnTestSetSave"
              >
                {submitting && <LoadingIndicator alt />}
                {!submitting && <ShowIcon icon="save" />}
                Save
              </Button>
            }
          </>}
        />
      </GridItem>
    )

    const renderTestSets = (formArgs) => (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="name"
            component={renderTextField}
            label="Test Set Name"
            validate={required}
            disabled={!this.hasWritePermission()}
            data-unique="txtTestSetSettingsName"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="namespace"
            component={renderNamespaceField}
            forWrite
            label="Namespace"
            disabled={!this.hasWritePermission()}
            data-unique="txtTestSetNamespace"
          />
        </GridItem>
        <GridItem xs={12} >
          <Field
            name="tags"
            component={renderTagField}
            label="Tags"
            disabled={!this.hasWritePermission()}
            data-unique="tagTestSetTags"
          />
        </GridItem>

        <GridItem xs={12}>
          <Field
            name="description"
            component={renderTextArea}
            label="Test Set Description"
            rows={3}
            disabled={!this.hasWritePermission()}
            data-unique="txtTestSetSettingsDescription"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="selectionType"
            component={renderSelect}
            label="Content Selection Type"
            disabled={!this.hasWritePermission()}
            data-unique="selTestSetSettingsSelectionType"
            items={[
              { key: 'SELECTION_TYPE_LOCAL_AND_REMOTE', label: 'Use Test Cases from Botium and External Connections' },
              { key: 'SELECTION_TYPE_LOCAL_ONLY', label: 'Use Test Cases from Botium only' },
              { key: 'SELECTION_TYPE_REMOTE_ONLY', label: 'Use Test Cases from External Connections only' },
            ]}
            helperText="Select what repositories, local or remote, to use when running tests with this test set"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          {this.renderDependenciesSelection()}
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="statsSkipCoach"
            component={renderCheckbox}
            label="Skip Auto-Calculation of Test Set Insights"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetStatsSkipCoach"
            helperText="If auto-calculation is skipped, Test Set Insights are only updated on explicit request in the Insights tab"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="skipTestCasesPatterns"
            component={renderTextField}
            label="Skip Test Cases"
            disabled={!this.hasWritePermission()}
            data-unique="txtTestSetSettingsSkipPattern"
            helperText={'When running tests, skip any test case matching any of those patterns - multiple patterns separated by comma "," and wildcards "*" are allowed'}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="statsSkipCoachAdvanced"
            component={renderCheckbox}
            label="Skip Calculation of Utterance Similarity Insights"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetStatsSkipCoachAdvanced"
            helperText="For large test sets(>10,000 utterances), it's recommended to skip this."
          />
        </GridItem>
        <GridItem xs={12}>
          <Text muted>{formatCreatedByAndLastChange(testset)}</Text>
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderScripting = ({ values, form, ...formArgs }) => (
      <GridContainer nounset>
        <GridItem xs={12} lg={4}>
          <Field
            name="useMatchingMode"
            component={renderSelect}
            label="Text Matching Mode"
            disabled={!this.hasWritePermission()}
            data-unique="selTestSetSettingsUseMatchingMode"
            items={MATCHING_MODES}
          />
        </GridItem>
        <GridItem xs={12} lg={8}>
          {values.useMatchingMode === 'MATCHING_MODE_WER' && <Field
            name="useMatchingModeWer"
            component={renderSlider}
            label="Acceptable Word Error Rate"
            helperText="Range between 0% (=exact match) and 100% (= total mismatch)"
            parse={parseInteger}
            min={0}
            max={100}
            validate={composeValidators(required, minValue(0), maxValue(100))}
            disabled={!this.hasWritePermission()}
            step={1}
            form={form}
            showInputField
            data-unique="selTestSetSettingsUseMatchingModeWer"
          />}
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="normalizeText"
            component={renderCheckbox}
            label="Normalize Text before Matching"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetNormalizeText"
            helperText="Enable this option so all texts are normalized before matching (cleaned by HTML tags, multiple spaces, line breaks etc)"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="normalizeTextRemoveChars"
            component={renderTextField}
            label="Remove Characters"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetNormalizeTextRemoveChars"
            helperText="Add a comma separated list of characters to be removed during normalization"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="normalizeTextRemoveRegexp"
            component={renderTextField}
            label="Remove Characters via RegExp"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetNormalizeTextRemoveRegexp"
            helperText="A regular expression to remove characters. Example: '[`\p{Emoji_Presentation}]' to remove all '`' and emojis"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandConvos"
            component={renderCheckbox}
            label="Expand Conversations"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetExpandConvos"
            helperText="Enable this option if your Test Set contains utterances files to be merged with the convo files"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandConvosMode"
            component={renderSelect}
            label="Convo Expansion Mode"
            disabled={!this.hasWritePermission() || !values.expandConvos}
            data-unique="selTestSetExpandConvosMode"
            items={[
              { key: 'UTTEXPANSION_MODE_ALL', label: 'Use all utterances' },
              { key: 'UTTEXPANSION_MODE_FIRST', label: 'Use first utterance' },
              { key: 'UTTEXPANSION_MODE_RANDOM', label: 'Randomly select utterances' },
              { key: 'UTTEXPANSION_MODE_BY_INDEX', label: 'Associate utterances by index' },
            ]}
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandConvosModeRandomCount"
            component={renderIntField}
            label="Number of utterances to select"
            parse={parseInteger}
            validate={minValue(1)}
            disabled={!this.hasWritePermission() || !values.expandConvos || values.expandConvosMode !== 'UTTEXPANSION_MODE_RANDOM'}
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandUtterancesToConvos"
            component={renderCheckbox}
            label="Expand Utterances to Conversations"
            type="checkbox"
            disabled={!this.hasWritePermission() || !values.expandConvos}
            data-unique="chkTestSetExpandUtterancesToConvos"
            helperText="Enable this option if your Test Set contains only utterances files to be treated as 2-step-conversations"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="exportUtterancesUseNameAsIntent"
            component={renderCheckbox}
            label="Use Utterance Name as NLU intent"
            type="checkbox"
            disabled={values.expandUtterancesIncomprehension || !values.expandUtterancesToConvos || !this.hasWritePermission() || !values.expandConvos}
            data-unique="chkTestSetExportUtterancesUseNameAsIntent"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandUtterancesIncomprehension"
            component={renderTextField}
            label="Incomprehension Utterance"
            disabled={values.exportUtterancesUseNameAsIntent || !values.expandUtterancesToConvos || !this.hasWritePermission() || !values.expandConvos}
            data-unique="txtTestSetSettingsIncomprehensionUtterance"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandUtterancesNaming"
            component={renderCheckbox}
            label="Use Utterance in Conversation Name"
            type="checkbox"
            disabled={!values.expandUtterancesToConvos || !this.hasWritePermission() || !values.expandConvos}
            data-unique="chkTestSetExpandUtterancesNaming"
            helperText="Enable this option to generate human readable conversation names"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandUtterancesNamingUtteranceMax"
            component={renderTextField}
            label="Max length of the Utterance"
            parse={parseInteger}
            disabled={!values.expandUtterancesToConvos || !values.expandUtterancesNaming || !this.hasWritePermission()}
            data-unique="txtTestSetExpandUtterancesNamingUtteranceMax"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="useScriptingMemory"
            component={renderCheckbox}
            label="Enable Scripting Memory"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetUseScriptingMemory"
            helperText="Enable this option to enable the BotiumScript scripting memory (variable extraction and expansion)"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="useScriptingMemoryMatchingMode"
            component={renderSelect}
            label="Scripting Variable Matching Mode"
            disabled={!this.hasWritePermission() || !values.useScriptingMemory}
            data-unique="selTestSetUseScriptingMemoryMatchingMode"
            items={[
              { key: 'MATCHING_MODE_NON_WHITESPACE', label: 'Capture every non whitespace character' },
              { key: 'MATCHING_MODE_WORD', label: 'Capture word characters only' },
            ]}
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="expandScriptingMemory"
            component={renderCheckbox}
            label="Enable Test Parameter Store"
            type="checkbox"
            disabled={!this.hasWritePermission() || !values.useScriptingMemory}
            data-unique="chkTestSetExpandScriptingMemory"
            helperText="Enable this option if your Test Set contains test parameter files (*.scriptingmemory.txt) to be expanded with the convo file"
          />
        </GridItem>
        <GridItem xs={12} lg={4}>
          <Field
            name="skipAssertErrors"
            component={renderCheckbox}
            label="Skip Assertion Errors"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            data-unique="chkTestSetSkipAssertErrors"
            helperText="Enable this option if Botium should try to continue test cases after assertion failures"
          />
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderExcel = ({ values, ...formArgs }) => (
      <GridContainer nounset>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="excelHasConvos"
                component={renderCheckbox}
                label="Customize Worksheet Names for Convos"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkTestSetExcelHasConvos"
              />
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Field
                name="excelWorksheetsConvos"
                component={renderTextField}
                label="Worksheets containing convos, comma separated"
                disabled={!values.excelHasConvos || !this.hasWritePermission()}
                data-unique="txtTestSetSettingsExcelWorksheetsConvos"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="excelHasPartialConvos"
                component={renderCheckbox}
                label="Customize Worksheet Names for Partial Convos"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkTestSetExcelHasPartialConvos"
              />
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Field
                name="excelWorksheetsPartialConvos"
                component={renderTextField}
                label="Worksheets containing partial convos, comma separated"
                disabled={!values.excelHasPartialConvos || !this.hasWritePermission()}
                data-unique="txtTestSetSettingsExcelWorksheetsPartialConvos"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="excelHasUtterances"
                component={renderCheckbox}
                label="Customize Worksheet Names for Utterances"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkTestSetExcelHasUtterances"
              />
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Field
                name="excelWorksheetsUtterances"
                component={renderTextField}
                label="Worksheets containing utterances, comma separated"
                disabled={!values.excelHasUtterances || !this.hasWritePermission()}
                data-unique="txtTestSetSettingsExcelWorksheetsUtterances"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="excelHasScriptingMemory"
                component={renderCheckbox}
                label="Customize Worksheet Names for Test Parameter Store"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkTestSetExcelHasScriptingMemory"
              />
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Field
                name="excelWorksheetsScriptingMemory"
                component={renderTextField}
                label="Worksheets containing test parameter tables, comma separated"
                disabled={!values.excelHasScriptingMemory || !this.hasWritePermission()}
                data-unique="txtTestSetSettingsExcelWorksheetsScriptingMemory"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="excelHasHeader"
                component={renderCheckbox}
                label="Skip Header Row on Content Area Identification"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkTestSetExcelHasHeader"
              />
            </GridItem>
            <GridItem xs={6} sm={4}>
              <Field
                name="excelStartRow"
                component={renderIntField}
                label="Starting Row Number"
                parse={parseInteger}
                validate={composeValidators(minValue(1))}
                helperText="Excel row number to start searching (starting at 1) - 2 means that there is a header row"
                disabled={!this.hasWritePermission()}
                data-unique="intTestSetExcelStartingRowNumber"
              />
            </GridItem>
            <GridItem xs={6} sm={4}>
              <Field
                name="excelStartCol"
                component={renderIntField}
                label="Starting Column Number"
                parse={parseInteger}
                validate={composeValidators(minValue(1))}
                helperText="Excel column number to start searching (starting at 1)"
                disabled={!this.hasWritePermission()}
                data-unique="intTestSetStartingColumnNumber"
              />
            </GridItem>
            <GridItem md={12} >
              <Field
                name="excelHasNameCol"
                component={renderSelect}
                label="Test Case Naming"
                disabled={!this.hasWritePermission()}
                data-unique="selTestSetExcelHasNameCol"
                items={[
                  { key: 'AUTO', label: 'Auto-Detect (based on row header)' },
                  { key: 'YES', label: '1st filled column contains test case name' },
                  { key: 'NO', label: 'Test case naming from row number' },
                ]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderJson = (formArgs) => (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="jsonRootPath"
            component={renderTextField}
            label="JSON-Path to select root element for extraction"
            disabled={!this.hasWritePermission()}
            validate={jsonpath}
            helperText="Maps the source JSON to utterance struct array. (One entry in map can be mapped to one utterance reference name)"
            data-unique="txtTestSetSettingsJsonRootPath"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="jsonCheckerPath"
            component={renderTextField}
            label="JSON-Path to select JSON files for extraction"
            disabled={!this.hasWritePermission()}
            validate={jsonpath}
            helperText="If the precompiler doesn't find anything using this JSON-Path, then json file is ignored."
            data-unique="txtTestSetSettingsJsonCheckerPath"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="jsonUtteranceRefPath"
            component={renderTextField}
            label="JSON-Path for utterance name extraction"
            disabled={!this.hasWritePermission()}
            validate={jsonpath}
            helperText="Should resolve to a string"
            data-unique="txtTestSetSettingsJsonUtteranceRefPath"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="jsonUtterancesPath"
            component={renderTextField}
            label="JSON-Path for utterance examples extraction"
            disabled={!this.hasWritePermission()}
            validate={jsonpath}
            helperText="Should resolve to an array of strings"
            data-unique="txtTestSetSettingsJsonUtterancesPath"
          />
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderMarkdown = ({ values, ...formArgs }) => (
      <GridContainer nounset>
        <GridItem md={12}>
          <Field
            name="markdownMode"
            component={renderSelect}
            label="Markdown Format"
            filterable={false}
            disabled={!this.hasWritePermission()}
            data-unique="selTestSetSettingsMarkdownMode"
            items={[
              { key: 'BOTIUM', label: 'Botium Markdown Format' },
              { key: 'RASA', label: 'Rasa Markdown Format' },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>
          {values.markdownMode === 'BOTIUM' &&
            <Text muted>
              You can read about the Botium Markdown Format in the <a href="https://botium-docs.readthedocs.io/en/latest/05_botiumscript/index.html#composing-in-markdown-files" target="_blank" rel="noopener noreferrer">Botium Documentation</a> - example:
              <Text pre>{`# Convos
## Test Case 1
- me
- hello bot
- bot
- hello meat bag
- BUTTONS checkbutton|checkbutton2
`}
              </Text>
            </Text>
          }
          {values.markdownMode === 'RASA' &&
            <Text muted>
              You can read about the Rasa Markdown Format in the <a href="https://rasa.com/docs/rasa/nlu/training-data-format/#markdown-format" target="_blank" rel="noopener noreferrer">Rasa Documentation</a> - example:
              <Text pre>{`## intent:greeting
- hello
- hi
- good morning
- good evening
`}
              </Text>
            </Text>
          }
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderCsv = (formArgs) => (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvDelimiter"
            component={renderTextField}
            label="Column separator used for CSV format"
            disabled={!this.hasWritePermission()}
            maxLength={2}
            validate={maxLength(2)}
            helperText={`Use "\t" for tab character. If not given Botium will try to auto-detect the field separator`}
            data-unique="txtTestSetSettingsCsvDelimiter"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvUseHeader"
            component={renderCheckbox}
            label="CSV header row contains data"
            type="checkbox"
            disabled={!this.hasWritePermission()}
            helperText="Skip or use first row (often, first row contains column header to be ignored)"
            data-unique="txtTestSetSettingsCsvUseHeader"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvQuote"
            component={renderTextField}
            label="Quote character used for CSV format"
            disabled={!this.hasWritePermission()}
            validate={maxLength(1)}
            maxLength={1}
            helperText={`Default quote character is double quotes (")`}
            data-unique="txtTestSetSettingsCsvQuote"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvEscape"
            component={renderTextField}
            label="Escape character used for CSV format"
            disabled={!this.hasWritePermission()}
            validate={maxLength(1)}
            maxLength={1}
            helperText={`Default escape character is double quotes (")`}
            data-unique="txtTestSetSettingsCsvEscape"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvUtteranceStartrow"
            component={renderIntField}
            label="Starting row number"
            parse={parseInteger}
            validate={composeValidators(minValue(2))}
            disabled={!this.hasWritePermission()}
            helperText={`The row index for searching for user examples. Default value is 2`}
            data-unique="txtTestSetSettingsCsvUtteranceStartrow"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvUtteranceStartrowHeader"
            component={renderTextField}
            label="Starting row header"
            disabled={!this.hasWritePermission()}
            helperText={`Use if the user examples starting dynamically, but they have some header like 'User Examples'`}
            data-unique="txtTestSetSettingsCsvUtteranceStartrowHeader"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="csvUtteranceStopOnEmpty"
            component={renderCheckbox}
            type="checkbox"
            label="Stop reading user examples on empty cell"
            disabled={!this.hasWritePermission()}
            helperText={`Use if the user examples are ending dynamically, marked my an empty cell`}
            data-unique="txtTestSetSettingsCsvUtteranceStopOnEmpty"
          />
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderMedia = ({ values, form: { change }, ...formArgs }) => (
      <GridContainer nounset>
        <GridItem xs={12} sm={6}>
          <Field
            name="mediaBaseUri"
            component={renderTextField}
            label="Download URI for attachment files (filename is appended)"
            disabled={!this.hasWritePermission()}
            validate={url}
            data-unique="txtTestSetSettingsMediaBaseUri"
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="mediaBaseDir"
            component={renderTextField}
            label="Folder for attachment files"
            disabled={!this.hasWritePermission()}
            data-unique="txtTestSetSettingsMediaBaseDir"
            endAdornment={<>
              <Button aria-label="Folder Open" title="Folder Open" justIcon dense disabled={!values.mediaBaseDir} data-unique="btnTestSetOpenFolderSelectionDialog" onClick={() => history.push(`/testsets/view/${testset.id}/filebrowser`)}>
                <ShowIcon icon="folder-open" />
              </Button>
              <Button aria-label="Select Folder" title="Select Folder" justIcon dense disabled={!this.hasWritePermission()} data-unique="btnTestSetOpenFolderSelectionDialog" onClick={() => this.setState({ showMediaDirectoryDialog: true })}>
                <ShowIcon icon="folder" />
              </Button>
            </>}
          />
          {this.hasWritePermission() &&
            <MediaSelectionDialog allowFolderSelection
              initialPath={values.mediaBaseDir}
              open={this.state.showMediaDirectoryDialog}
              onCancel={() => this.setState({ showMediaDirectoryDialog: false })}
              onOk={({ selectedFolders }) => {
                change('mediaBaseDir', selectedFolders[0].join('/'))
                this.setState({ showMediaDirectoryDialog: false })
              }}
              title="Select Folder"
            />
          }
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name="mediaUsageType"
            component={renderSelect}
            label="Audio File Usage"
            disabled={!this.hasWritePermission()}
            data-unique="selTestSetSettingsMediaUsageType"
            items={[
              { key: 'MEDIA_USAGE_SCRIPTS_ONLY', label: 'When referenced from Test Cases' },
              { key: 'MEDIA_USAGE_GEN_NOTEXT', label: 'Use all audio files as Test Case input' },
              { key: 'MEDIA_USAGE_GEN_TEXT_FROM_TRANSCRIPTION', label: 'Use all audio files as Test Case input, and read transcription from file' },
              { key: 'MEDIA_USAGE_GEN_TEXT_FROM_FILENAME', label: 'Use all audio files as Test Case input, and use filename as transcription' },
              { key: 'MEDIA_USAGE_WER_FROM_TRANSCRIPTION', label: 'Use all audio files as Test Case input, and calculate word error rate from transcription from file' },
              { key: 'MEDIA_USAGE_WER_FROM_FILENAME', label: 'Use all audio files as Test Case input, and calculate word error rate from filename as transcription' },

            ]}
            helperText="Select how Botium should handle media files placed in this test set"
          />
        </GridItem>
        <GridItem xs={12} sm={6}></GridItem>
        <GridItem xs={12}>
          {(values.mediaUsageType === 'MEDIA_USAGE_GEN_TEXT_FROM_TRANSCRIPTION' || values.mediaUsageType === 'MEDIA_USAGE_WER_FROM_TRANSCRIPTION') && <>
            Botium will look up the transcription for an audio file in the following order:
            <ol>
              <li>If there is a file with the same name but extension <em>.txt</em> instead, the transcription is read from this file</li>
              <li>If there is a CSV file named <em>transcript.csv</em> and the first column matches the file name, then the second column in this row is read as transcription</li>
              <li>Go up one folder and look for another file <em>transcript.csv</em> there (and so on)</li>
            </ol>
          </>}
          {(values.mediaUsageType === 'MEDIA_USAGE_GEN_TEXT_FROM_FILENAME' || values.mediaUsageType === 'MEDIA_USAGE_WER_FROM_FILENAME') && <>
            Botium will use the name of the file as transcription the transcription. The transcription for the file named <em>hello-botium-i-am-fine.wav</em> is expected to be <em>hello botium i am fine</em>
          </>}
        </GridItem>
        {renderSubmitButton(formArgs)}
      </GridContainer>
    )

    const renderDanger = () => (
      <GridContainer nounset>
        <GridItem md={8} lg={4}>
          <ListItem>
            <Text lg danger padding><ShowIcon icon="trash" /></Text>
            <GridContainer>
              <GridItem md={12}><Text bold>Delete whole Test Set</Text></GridItem>
              <GridItem md={12}><Text>This removes the Test Set and its Test Scripts</Text></GridItem>
            </GridContainer>
            <Mutation
              mutation={DELETE_TESTSET}
              onCompleted={data => {
                removeRecentListEntry({
                  url: `/testsets/view/${testset.id}`
                })
                setAlertSuccessMessage('Test Set deleted')
                this.props.history.push('/testsets')
              }}
              onError={error => {
                setAlertErrorMessage(
                  `Test Set deletion failed`,
                  error,
                )
              }}
              refetchQueries={[
                ...RefetchTestSetQueries(testset.id, license)
              ]}
            >
              {(deleteTestSet, { loading, error }) => (
               <Tooltip title="Delete" placement="bottom">
                <ConfirmationButton
                  confirmationText={`When deleting the Test Set "${testset.name}", all configuration settings and test scripts are removed. Test Results won't be deleted. Are you sure you want to delete it ?`}
                  requireCheck={true}
                  danger
                  small
                  minWidth
                  disabled={!hasPermission(user, 'TESTSETS_DELETE')}
                  onClick={() => {
                    deleteTestSet({
                      variables: { id: testset.id },
                    })
                  }}
                  data-unique="btnTestSetDelete"
                >
                  Delete
                </ConfirmationButton></Tooltip>
              )}
            </Mutation>
          </ListItem>

        </GridItem>
        <GridItem md={12} lg={8}></GridItem>
        <GridItem md={8} lg={4}><Divider dense /></GridItem>
        <GridItem md={12} lg={8}></GridItem>
        <GridItem md={8} lg={4}>
          <ListItem>
            <Text lg danger padding><ShowIcon icon="broom" /></Text>
            <GridContainer>
              <GridItem md={12}><Text bold>Empty Test Set</Text></GridItem>
              <GridItem md={12}><Text>Removes all Test Cases and Excel files from this Test Set</Text></GridItem>
            </GridContainer>
            <Mutation
              mutation={DELETE_TESTSETSCRIPTS}
              onCompleted={data => {
                setAlertSuccessMessage('Test Cases deleted')
              }}
              onError={error => {
                setAlertErrorMessage(
                  `Test Cases deletion failed`,
                  error,
                )
              }}
              refetchQueries={[
                ...RefetchTestSetQueries(testset.id, license)
              ]}
            >
              {(deleteTestSetScripts, { loading, error }) => (
               <Tooltip title="Clear" placement="bottom">
                <ConfirmationButton
                  confirmationText={`Are you sure you want to delete all test cases and Excel files from the local repository ?`}
                  requireCheck={true}
                  danger
                  minWidth
                  small
                  disabled={!this.hasWritePermission()}
                  onClick={() => {
                    deleteTestSetScripts({
                      variables: { testSetId: testset.id },
                    })
                  }}
                  data-unique="btnTestSetDeleteLocalTestCases"
                >

                  Clear
                </ConfirmationButton></Tooltip>
              )}
            </Mutation>
          </ListItem>
        </GridItem>
      </GridContainer>
    )

    return <GridContainer>
      <GridItem xs={12}>
        {hasPermission(user, 'TESTSETS_CREATE') && this.renderCloneDialog(testset)}
        <CustomTabsSecondary
          name={`tabTestSetSettings_${testset.id}`}
          tabs={[
            {
              tabName: 'Test Set',
              tabContent: _renderFormWrapper(renderTestSets),
              locationPrefix: `/testsets/view/${testset.id}/settings/testset`,
              dataUnique: 'tabTestSetSettingsTestSet'
            },
            {
              tabName: 'External Connections',
              tabContent: this.renderRemoteSourcesTab(testset.id),
              locationPrefix: `/testsets/view/${testset.id}/settings/remote`,
              dataUnique: 'tabTestSetSettingsConnections'
            },
            {
              tabName: 'Scripting',
              tabContent: _renderFormWrapper(renderScripting),
              locationPrefix: `/testsets/view/${testset.id}/settings/scripting`,
              dataUnique: 'tabTestSetSettingsScripting'
            },
            {
              tabName: 'Excel',
              tabContent: _renderFormWrapper(renderExcel),
              locationPrefix: `/testsets/view/${testset.id}/settings/excel`,
              dataUnique: 'tabTestSetSettingsExcel'
            },
            {
              tabName: 'JSON',
              tabContent: _renderFormWrapper(renderJson),
              locationPrefix: `/testsets/view/${testset.id}/settings/json`,
              dataUnique: 'tabTestSetSettingsJson'
            },
            {
              tabName: 'CSV',
              tabContent: _renderFormWrapper(renderCsv),
              locationPrefix: `/testsets/view/${testset.id}/settings/csv`,
              dataUnique: 'tabTestSetSettingsCsv'
            },
            {
              tabName: 'Markdown',
              tabContent: _renderFormWrapper(renderMarkdown),
              locationPrefix: `/testsets/view/${testset.id}/settings/markdown`,
              dataUnique: 'tabTestSetSettingsMarkdown'
            },
            {
              tabName: 'Media Resources',
              tabContent: _renderFormWrapper(renderMedia),
              locationPrefix: `/testsets/view/${testset.id}/settings/media`,
              dataUnique: 'tabTestSetSettingsMedia'
            },
            {
              tabName: 'Danger Zone',
              tabContent: renderDanger(),
              locationPrefix: `/testsets/view/${testset.id}/settings/danger`,
              dataUnique: 'tabTestSetSettingsDanger'
            }
          ].filter(t => t)}
        />
      </GridItem>
    </GridContainer>
  }

  renderUtteranceDeleteDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showUtteranceDeleteDialog, selectedScript } = this.state

    return <Mutation
      mutation={TESTSET_EDITABLE_UTTERANCE_DELETE}
      onCompleted={data => {
        this.setState({ showUtteranceDeleteDialog: false })
        setAlertSuccessMessage('Utterance List deleted')
        history.push(`/testsets/view/${testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showUtteranceDeleteDialog: false })
        setAlertErrorMessage('Utterance List deletion failed', error)
      }}
      refetchQueries={[
        ...RefetchTestSetQueries(testSetId, license)
      ]}
    >
      {(deleteUtterance, { loading, error }) => (

        <CheckboxDialog danger
          open={showUtteranceDeleteDialog}
          onCancel={() => {
            this.setState({
              showUtteranceDeleteDialog: false
            })
          }}
          onConfirmed={() => {
            deleteUtterance({
              variables: {
                testSetId,
                testSetScriptId: selectedScript.script.id,
                name: selectedScript.name
              }
            })
            this.setState({
              showUtteranceDeleteDialog: false
            })
          }}
          confirmationTitle="Are you sure you want to irrevocably delete this Utterance List ?"
        />
      )}
    </Mutation>
  }

  renderUtteranceCloneDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showUtteranceCloneDialog, selectedScript, testCaseViewMode } = this.state

    return (
      <Mutation
        mutation={CLONE_TESTSETSCRIPT}
        onCompleted={data => {
          this.setState({ showUtteranceCloneDialog: false })
          setAlertSuccessMessage('Utterance List clone finished')
          testCaseViewMode === 'ui' ?
            history.push(`/testsets/view/${testSetId}/testcases/viewutterance/${data.cloneTestSetScript.id}/${encodeURIComponent(data.cloneTestSetScript.name)}`) :
            history.push(`/testsets/view/${testSetId}/testcases/viewscript/${data.cloneTestSetScript.id}`)
        }}
        onError={error => {
          this.setState({ showUtteranceCloneDialog: false })
          setAlertErrorMessage('Utterance List clone failed', error)
        }}
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(cloneUtterance, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                await cloneUtterance({
                  variables: {
                    id: selectedScript.script.id,
                    name: values.name
                  },
                })
              } catch (error) {
                this.setState({ showUtteranceCloneDialog: false })
                setAlertErrorMessage('Utterance List clone failed', error)
              }
            }}
            initialValues={{
              name: `Clone of ${selectedScript && selectedScript.script ? selectedScript.script.name : ''}`
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={showUtteranceCloneDialog}
                onCancel={() => this.setState({ showUtteranceCloneDialog: false })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title="Clone Utterance"
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="New Utterance Name"
                        validate={required}
                        data-unique="txtUtteranceCloneUtteranceName"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderUtteranceCopyDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showUtteranceCopyDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        this.setState({ showUtteranceCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage('Utterance List copied')
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewutterance/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showUtteranceCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Copying Utterance List failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(copyTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showUtteranceCopyDialog}
          title="Copy to Test Set ..."
          isWorking={loading}
          okButtonIcon="copy"
          okText="Copy to Test Set"
          okDisabled={!selectedTestSet}
          onCancel={() => this.setState({ showUtteranceCopyDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            copyTestSetScript({
              variables: {
                id: selectedScript.script.id,
                testSetId: selectedTestSet,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetUtteranceListCopy"
                label="Select Test Set"
                input={{
                  name: 'selTestSetCopy',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>
  }

  renderUtteranceMoveDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showUtteranceMoveDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        this.setState({ showUtteranceMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage('Utterance List moved')
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewutterance/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showUtteranceMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Moving Utterance List failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(moveTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showUtteranceMoveDialog}
          title="Move to Test Set ..."
          isWorking={loading}
          okButtonIcon="cut"
          okDisabled={!selectedTestSet}
          okText="Move to Test Set"
          onCancel={() => this.setState({ showUtteranceMoveDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            moveTestSetScript({
              variables: {
                id: selectedScript.script.id,
                testSetId: selectedTestSet,
                move: true,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetUtteranceListMove"
                label="Select Test Set"
                input={{
                  name: 'selTestSetMove',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>

  }

  renderConvoDeleteDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showConvoDeleteDialog, selectedScript } = this.state

    return <Mutation
      mutation={TESTSET_EDITABLE_CONVO_DELETE}
      onCompleted={data => {
        this.setState({ showConvoDeleteDialog: false })
        setAlertSuccessMessage('Convo deleted')
        history.push(`/testsets/view/${testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showConvoDeleteDialog: false })
        setAlertErrorMessage('Convo deletion failed', error)
      }}
      refetchQueries={[
        ...RefetchTestSetQueries(testSetId, license)
      ]}
    >
      {(deleteConvo, { loading, error }) => (

        <CheckboxDialog danger
          open={showConvoDeleteDialog}
          onCancel={() => {
            this.setState({
              showConvoDeleteDialog: false
            })
          }}
          onConfirmed={() => {
            deleteConvo({
              variables: {
                testSetId,
                testSetScriptId: selectedScript.script.id,
                name: selectedScript.name
              }
            })
            this.setState({
              showConvoDeleteDialog: false
            })
          }}
          confirmationTitle="Are you sure you want to irrevocably delete this Convo?"
        />
      )}
    </Mutation>
  }

  renderScriptBulkDeleteDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showScriptBulkDeleteDialog, selectedItems } = this.state

    return <Mutation
      mutation={TESTSET_SCRIPT_BULK_DELETE}
      onCompleted={data => {
        this.setState({ showScriptBulkDeleteDialog: false })
        setAlertSuccessMessage('Convo(s) deleted')
        history.push(`/testsets/view/${testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showScriptBulkDeleteDialog: false })
        setAlertErrorMessage('Convo deletion failed', error)
      }}
      refetchQueries={[
        ...RefetchTestSetQueries(testSetId, license)
      ]}
    >
      {(bulkDeleteScript, { loading, error }) => (

        <CheckboxDialog danger
                        open={showScriptBulkDeleteDialog}
                        onCancel={() => {
                          this.setState({
                            showScriptBulkDeleteDialog: false
                          })
                        }}
                        onConfirmed={() => {
                          bulkDeleteScript({
                            variables: {
                              testSetId,
                              testSetScriptIds: selectedItems,
                            }
                          })
                          this.setState({
                            showScriptBulkDeleteDialog: false,
                            selectedItems: []
                          })
                        }}
                        confirmationTitle="Are you sure you want to irrevocably delete all of the selected Convos?"
        />
      )}
    </Mutation>
  }

  renderConvoCloneDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showConvoCloneDialog, selectedScript, testCaseViewMode } = this.state

    return (
      <Mutation
        mutation={CLONE_TESTSETSCRIPT}
        onCompleted={data => {
          this.setState({ showConvoCloneDialog: false })
          setAlertSuccessMessage('Convo clone finished')
          if (data.cloneTestSetScript.scriptType === 'SCRIPTING_TYPE_PCONVO' || data.cloneTestSetScript.scriptType === 'SCRIPTING_TYPE_CONVO') {
            testCaseViewMode === 'ui' ?
              history.push(`/testsets/view/${testSetId}/testcases/${data.cloneTestSetScript.scriptType === 'SCRIPTING_TYPE_PCONVO' ? 'viewpconvo' : 'viewconvo'}/${data.cloneTestSetScript.id}/${encodeURIComponent(data.cloneTestSetScript.name)}`) :
              history.push(`/testsets/view/${testSetId}/testcases/viewscript/${data.cloneTestSetScript.id}`)
          }
        }}
        onError={error => {
          setAlertErrorMessage('Convo clone failed', error)
        }}
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(cloneUtterance, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                await cloneUtterance({
                  variables: {
                    id: selectedScript.script.id,
                    name: values.name
                  },
                })
              } catch (error) {
                this.setState({ showConvoCloneDialog: false })
                setAlertErrorMessage('Convo clone failed', error)
              }
            }}
            initialValues={{
              name: `Clone of ${selectedScript && selectedScript.script ? selectedScript.script.name : ''}`
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={showConvoCloneDialog}
                onCancel={() => this.setState({ showConvoCloneDialog: false })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title="Clone Convo"
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="New Convo Name"
                        validate={required}
                        data-unique="txtConvoCloneConvoName"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderScriptBulkCloneDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showScriptBulkCloneDialog, selectedItems } = this.state

    return (
      <Mutation
        mutation={BULK_CLONE_TESTSETSCRIPT}
        onCompleted={data => {
          this.setState({ showScriptBulkCloneDialog: false, selectedItems: [] })
          setAlertSuccessMessage('Convo(s) clone finished')
          history.push(`/testsets/view/${testSetId}/testcases`)
        }}
        onError={error => {
          this.setState({ showScriptBulkCloneDialog: false })
          setAlertErrorMessage('Convo(s) clone failed', error)
        }}
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(bulkCloneUtterance, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                await bulkCloneUtterance({
                  variables: {
                    ids: selectedItems,
                    prefix: values.prefix
                  },
                })
              } catch (error) {
                this.setState({ showScriptBulkCloneDialog: false })
                setAlertErrorMessage('Convo(s) clone failed', error)
              }
            }}
            initialValues={{
              prefix: 'Clone of '
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={showScriptBulkCloneDialog}
                onCancel={() => this.setState({ showScriptBulkCloneDialog: false })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title="Bulk Clone Convo"
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="prefix"
                        component={renderTextField}
                        label="New Convo Name Prefix"
                        validate={required}
                        data-unique="txtConvoCloneConvoNamePrefix"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderConvoCopyDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showConvoCopyDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        let successMessage = 'Convo copied'
        if (copyUtterances && copyPartialConvos) {
          successMessage = 'Convo copied including Utterances and Partial convos'
        } else if (copyUtterances) {
          successMessage = 'Convo copied including Utterances'
        } else if (copyPartialConvos) {
          successMessage = 'Convo copied including Partial convos'
        }
        this.setState({ showConvoCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage(successMessage)
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewconvo/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showConvoCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Copying Convo failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(copyTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showConvoCopyDialog}
          title="Copy to Test Set ..."
          isWorking={loading}
          okButtonIcon="copy"
          okDisabled={!selectedTestSet}
          okText="Copy to Test Set"
          onCancel={() => this.setState({ showConvoCopyDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            copyTestSetScript({
              variables: {
                id: selectedScript.script.id,
                testSetId: selectedTestSet,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyUtterances: e.target.checked }),
                  checked: copyUtterances
                }}
                label="Copy Utterances"
                data-unique="chkTestSetConvoCopyUtterances"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyPartialConvos: e.target.checked }),
                  checked: copyPartialConvos
                }}
                label="Copy Partial Convos"
                data-unique="chkTestSetConvoCopyPartialConvos"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetConvoCopy"
                label="Select Test Set"
                input={{
                  name: 'selTestSetCopy',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>
  }

  renderScriptBulkCopyDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showScriptBulkCopyDialog, selectedItems, selectedTestSet } = this.state

    return <Mutation
      mutation={BULK_COPY_TESTSETSCRIPT}
      onCompleted={data => {
        this.setState({ showScriptBulkCopyDialog: false })
        setAlertSuccessMessage('Script(s) copied')
        history.push(`/testsets/view/${data.bulkCopyTestSetScript.testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showScriptBulkCopyDialog: false })
        setAlertErrorMessage('Copying Script(s) failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.bulkCopyTestSetScript.testSetId, license)
      ]}
    >
      {(bulkCopyTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showScriptBulkCopyDialog}
          title="Copy to Test Set ..."
          isWorking={loading}
          okButtonIcon="copy"
          okDisabled={!selectedTestSet}
          okText="Copy to Test Set"
          onCancel={() => this.setState({ showScriptBulkCopyDialog: false })}
          onOk={async () => {
            await bulkCopyTestSetScript({
              variables: {
                ids: selectedItems,
                testSetId: selectedTestSet
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetConvoCopy"
                label="Select Test Set"
                input={{
                  name: 'selTestSetCopy',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>
  }

  renderConvoMoveDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showConvoMoveDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        let successMessage = 'Convo moved'
        if (copyUtterances && copyPartialConvos) {
          successMessage = 'Convo moved including Utterances and Partial convos'
        } else if (copyUtterances) {
          successMessage = 'Convo moved including Utterances'
        } else if (copyPartialConvos) {
          successMessage = 'Convo moved including Partial convos'
        }
        this.setState({ showConvoMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage(successMessage)
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewconvo/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showConvoMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Moving Convo failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(moveTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showConvoMoveDialog}
          title="Move to Test Set ..."
          isWorking={loading}
          okButtonIcon="cut"
          okDisabled={!selectedTestSet}
          okText="Move to Test Set"
          onCancel={() => this.setState({ showConvoMoveDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            moveTestSetScript({
              variables: {
                id: selectedScript.script.id,
                testSetId: selectedTestSet,
                move: true,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyUtterances: e.target.checked }),
                  checked: copyUtterances
                }}
                label="Move Utterances"
                data-unique="chkTestSetConvoMoveUtterances"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyPartialConvos: e.target.checked }),
                  checked: copyPartialConvos
                }}
                label="Move Partial Convos"
                data-unique="chkTestSetConvoMovePartialConvos"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetConvoMove"
                label="Select Test Set"
                input={{
                  name: 'selTestSetMove',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>

  }

  renderScriptBulkMoveDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showScriptBulkMoveDialog, selectedItems, selectedTestSet } = this.state

    return <Mutation
      mutation={BULK_COPY_TESTSETSCRIPT}
      onCompleted={data => {
        this.setState({ showScriptBulkMoveDialog: false })
        setAlertSuccessMessage('Script(s) moved')
        history.push(`/testsets/view/${data.bulkCopyTestSetScript.testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showScriptBulkMoveDialog: false })
        setAlertErrorMessage('Moving Script(s) failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.bulkCopyTestSetScript.testSetId, license)
      ]}
    >
      {(bulkMoveTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showScriptBulkMoveDialog}
          title="Bulk Move to Test Set ..."
          isWorking={loading}
          okButtonIcon="cut"
          okDisabled={!selectedTestSet}
          okText="Move to Test Set"
          onCancel={() => this.setState({ showScriptBulkMoveDialog: false })}
          onOk={() => {
            bulkMoveTestSetScript({
              variables: {
                ids: selectedItems,
                testSetId: selectedTestSet,
                move: true,
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetConvoMove"
                label="Select Test Set"
                input={{
                  name: 'selTestSetMove',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>

  }

  renderScriptDeleteDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showScriptDeleteDialog, selectedScript } = this.state

    return <Mutation
      mutation={DELETE_TESTSETSCRIPT}
      onCompleted={data => {
        this.setState({ showScriptDeleteDialog: false })
        setAlertSuccessMessage('Test Script deleted')
        history.push(`/testsets/view/${testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showScriptDeleteDialog: false })
        setAlertErrorMessage('Test Script deletion failed', error)
      }}
      refetchQueries={[
        ...RefetchTestSetQueries(testSetId, license)
      ]}
    >
      {(deleteScript, { loading, error }) => (

        <CheckboxDialog danger
          open={showScriptDeleteDialog}
          onCancel={() => {
            this.setState({
              showScriptDeleteDialog: false
            })
          }}
          onConfirmed={() => {
            deleteScript({
              variables: {
                id: selectedScript.id
              }
            })
            this.setState({
              showScriptDeleteDialog: false
            })
          }}
          confirmationTitle={`Are you sure you want to irrevocably delete this Test Script ?`}
        />
      )}
    </Mutation>
  }

  renderScriptCopyDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showScriptCopyDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        let successMessage = 'Test Script copied'
        if (copyUtterances && copyPartialConvos) {
          successMessage = 'Test Script copied including Utterances and Partial convos'
        } else if (copyUtterances) {
          successMessage = 'Test Script copied including Utterances'
        } else if (copyPartialConvos) {
          successMessage = 'Test Script copied including Partial convos'
        }
        this.setState({ showScriptCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage(successMessage)
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewscript/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showScriptCopyDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Copying Test Script failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(copyTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showScriptCopyDialog}
          title="Copy to Test Set ..."
          isWorking={loading}
          okButtonIcon="copy"
          okDisabled={!selectedTestSet}
          okText="Copy to Test Set"
          onCancel={() => this.setState({ showScriptCopyDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            copyTestSetScript({
              variables: {
                id: selectedScript.id,
                testSetId: selectedTestSet,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyUtterances: e.target.checked }),
                  checked: copyUtterances
                }}
                label="Copy Utterances"
                data-unique="chkTestSetConvoCopyUtterances"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyPartialConvos: e.target.checked }),
                  checked: copyPartialConvos
                }}
                label="Copy Partial Convos"
                data-unique="chkTestSetConvoCopyPartialConvos"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetScriptCopy"
                label="Select Test Set"
                input={{
                  name: 'selTestSetCopy',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>
  }

  renderScriptMoveDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showScriptMoveDialog, selectedScript, selectedTestSet, copyUtterances, copyPartialConvos } = this.state

    return <Mutation
      mutation={COPY_TESTSETSCRIPT}
      onCompleted={data => {
        let successMessage = 'Test Script moved'
        if (copyUtterances && copyPartialConvos) {
          successMessage = 'Test Script moved including Utterances and Partial convos'
        } else if (copyUtterances) {
          successMessage = 'Test Script moved including Utterances'
        } else if (copyPartialConvos) {
          successMessage = 'Test Script moved including Partial convos'
        }
        this.setState({ showScriptMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertSuccessMessage(successMessage)
        history.push(`/testsets/view/${data.copyTestSetScript.testSet.id}/testcases/viewscript/${data.copyTestSetScript.id}/${encodeURIComponent(data.copyTestSetScript.name)}`)
      }}
      onError={error => {
        this.setState({ showScriptMoveDialog: false, copyUtterances:true, copyPartialConvos: true })
        setAlertErrorMessage('Moving Test Script failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.copyTestSetScript.testSet.id, license)
      ]}
    >
      {(moveTestSetScript, { loading }) => (
        <ConfirmationDialog
          open={showScriptMoveDialog}
          title="Move to Test Set ..."
          isWorking={loading}
          okButtonIcon="cut"
          okDisabled={!selectedTestSet}
          okText="Move to Test Set"
          onCancel={() => this.setState({ showScriptMoveDialog: false, copyUtterances:true, copyPartialConvos: true })}
          onOk={() => {
            moveTestSetScript({
              variables: {
                id: selectedScript.script.id,
                testSetId: selectedTestSet,
                move: true,
                copyUtterances,
                copyPartialConvos
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyUtterances: e.target.checked }),
                  checked: copyUtterances
                }}
                label="Move Utterances"
                data-unique="chkTestSetConvoMoveUtterances"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomCheckbox
                input={{
                  onChange: e => this.setState({ copyPartialConvos: e.target.checked }),
                  checked: copyPartialConvos
                }}
                label="Move Partial Convos"
                data-unique="chkTestSetConvoMovePartialConvos"
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetScriptMove"
                label="Select Test Set"
                input={{
                  name: 'selTestSetMove',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>

  }

  renderExcelDeleteDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { showExcelDeleteDialog, selectedScript } = this.state

    return <Mutation
      mutation={DELETE_TESTSETEXCEL}
      onCompleted={data => {
        this.setState({ showExcelDeleteDialog: false })
        setAlertSuccessMessage('Excel deleted')
        history.push(`/testsets/view/${testSetId}/testcases`)
      }}
      onError={error => {
        this.setState({ showExcelDeleteDialog: false })
        setAlertErrorMessage('Excel deletion failed', error)
      }}
      refetchQueries={[
        ...RefetchTestSetQueries(testSetId, license)
      ]}
    >
      {(deleteExcel, { loading, error }) => (
        <CheckboxDialog danger
          open={showExcelDeleteDialog}
          onCancel={() => {
            this.setState({
              showExcelDeleteDialog: false
            })
          }}
          onConfirmed={() => {
            deleteExcel({
              variables: {
                id: selectedScript.id
              }
            })
            this.setState({
              showExcelDeleteDialog: false
            })
          }}
          confirmationTitle={`Are you sure you want to irrevocably delete this Excel file ?`}
        />
      )}
    </Mutation>
  }

  renderExcelCopyDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showExcelCopyDialog, selectedScript, selectedTestSet } = this.state

    return <Mutation
      mutation={COPY_TESTSETEXCEL}
      onCompleted={data => {
        this.setState({ showExcelCopyDialog: false })
        setAlertSuccessMessage('Excel copied')
        history.push(`/testsets/view/${data.copyTestSetExcel.testSet.id}/testcases/viewexcel/${data.copyTestSetExcel.id}`)
      }}
      onError={error => {
        this.setState({ showExcelCopyDialog: false })
        setAlertErrorMessage('Copying Excel failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(data.copyTestSetExcel.testSet.id, license)
      ]}
    >
      {(copyTestSetExcel, { loading }) => (
        <ConfirmationDialog
          open={showExcelCopyDialog}
          title="Copy to Test Set ..."
          isWorking={loading}
          okButtonIcon="copy"
          okDisabled={!selectedTestSet}
          okText="Copy to Test Set"
          onCancel={() => this.setState({ showExcelCopyDialog: false })}
          onOk={() => {
            copyTestSetExcel({
              variables: {
                id: selectedScript.id,
                testSetId: selectedTestSet
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetExcelCopy"
                label="Select Test Set"
                input={{
                  name: 'selTestSetCopy',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>
  }

  renderExcelMoveDialog(testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license, testSetsData } = this.props
    const { showExcelMoveDialog, selectedScript, selectedTestSet } = this.state

    return <Mutation
      mutation={UPDATE_TESTSETEXCEL}
      onCompleted={data => {
        this.setState({ showExcelMoveDialog: false })
        setAlertSuccessMessage('Excel moved')
        history.push(`/testsets/view/${data.updateTestSetExcel.testSet.id}/testcases/viewexcel/${data.updateTestSetExcel.id}`)
      }}
      onError={error => {
        this.setState({ showExcelMoveDialog: false })
        setAlertErrorMessage('Moving Excel failed', error)
      }}
      refetchQueries={({ data }) => [
        ...RefetchTestSetQueries(testSetId, license),
        ...RefetchTestSetQueries(data.updateTestSetExcel.testSet.id, license)
      ]}
    >
      {(moveTestSetExcel, { loading }) => (
        <ConfirmationDialog
          open={showExcelMoveDialog}
          title="Move to Test Set ..."
          isWorking={loading}
          okButtonIcon="cut"
          okDisabled={!selectedTestSet}
          okText="Move to Test Set"
          onCancel={() => this.setState({ showExcelMoveDialog: false })}
          onOk={() => {
            moveTestSetExcel({
              variables: {
                id: selectedScript.id,
                testSetExcel: {
                  testSet: {
                    connect: {
                      id: selectedTestSet
                    }
                  }
                }
              }
            })
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <CustomSelect
                data-unique="ddbtnTestSetExcelMove"
                label="Select Test Set"
                input={{
                  name: 'selTestSetMove',
                  value: selectedTestSet || '',
                  onChange: e => {
                    this.setState({ selectedTestSet: e.target.value })
                  }
                }}
                filterable
                items={(testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace).map(t => ({
                  key: t.id,
                  label: t.name
                }))) || []}
              />
            </GridItem>
          </GridContainer>

        </ConfirmationDialog>
      )}
    </Mutation>

  }

  renderTypeFilterDropDownButton() {
    const { classes} = this.props

    const filterBadge = _.filter(_.keys(this.props.testcasefilter), k => this.props.testcasefilter[k]).length

    return <DropdownCheckbox link secondary noCaret
                      className={classes.dropdownCheckboxFullWidth}
                      data-unique="ddbtnTestSetFilterType"
                      items={[
                        {
                          id: 'viewAll',
                          label: 'View All',
                          dataUnique: 'ddbtnTestSetViewAll',
                          input: {
                            onChange: e => {
                              this.props.setTestCaseFilter('utterances', e.target.checked)
                              this.props.setTestCaseFilter('convos', e.target.checked)
                              this.props.setTestCaseFilter('partialconvos', e.target.checked)
                              this.props.setTestCaseFilter('parameterstores', e.target.checked)
                              this.props.setTestCaseFilter('excel', e.target.checked)
                              this.props.setTestCaseFilter('others', e.target.checked)
                            },
                            checked: this.props.testcasefilter.utterances && this.props.testcasefilter.convos && this.props.testcasefilter.partialconvos && this.props.testcasefilter.parameterstores && this.props.testcasefilter.excel && this.props.testcasefilter.others
                          }
                        },
                        {
                          id: 'utterances',
                          label: 'Utterances',
                          dataUnique: 'ddbtnTestSetUtterances',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('utterances', !this.props.testcasefilter.utterances),
                            checked: this.props.testcasefilter.utterances
                          }
                        },
                        {
                          id: 'convos',
                          label: 'Convos',
                          dataUnique: 'ddbtnTestSetConvos',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('convos', !this.props.testcasefilter.convos),
                            checked: this.props.testcasefilter.convos
                          }
                        },
                        {
                          id: 'partialConvos',
                          label: 'Partial Convos',
                          dataUnique: 'ddbtnTestSetPartialConvos',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('partialconvos', !this.props.testcasefilter.partialconvos),
                            checked: this.props.testcasefilter.partialconvos
                          }
                        },
                        {
                          id: 'testParameterStores',
                          label: 'Test Parameter Stores',
                          dataUnique: 'ddbtnTestSetTestParameterStores',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('parameterstores', !this.props.testcasefilter.parameterstores),
                            checked: this.props.testcasefilter.parameterstores
                          }
                        },
                        {
                          id: 'excel',
                          label: 'Excel',
                          dataUnique: 'ddbtnTestSetExcel',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('excel', !this.props.testcasefilter.excel),
                            checked: this.props.testcasefilter.excel
                          }
                        },
                        {
                          id: 'otherScripts',
                          label: 'Other Scripts',
                          dataUnique: 'ddbtnTestSetOtherScripts',
                          input: {
                            onChange: e => this.props.setTestCaseFilter('others', !this.props.testcasefilter.others),
                            checked: this.props.testcasefilter.others
                          }
                        }
                      ]}
    >

      <ShowIcon icon="filter" /> Type Filter <span className={classes.valuebuble}>{filterBadge}</span> <ShowIcon icon="caret-down" />
    </DropdownCheckbox>
  }

  renderTestCasesTab(testSetId, testSetName) {
    const { setAlertSuccessMessage, history, location, testsetscriptsData, testsetexcelsData, testsetremoteData, license, user, token, classes } = this.props
    const { testCaseViewMode } = this.state

    return (<GridContainer>
      <GridItem xs={12}>
        <Query query={TESTSET_EDITABLE_QUERY} variables={{ testSetId }}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <RenderSkeletonConvoEditor />
            }
            if (error) {
              return <ErrorFormat err={error} />
            }

            const exportItems = []
            if (license.testsetExport) {
              exportItems.push({
                id: 'zip',
                name: '... as single ZIP file',
                onClick: () => downloadfileformpost(`${config.api.base}/testset/${testSetId}`, token),
                dataUnique: 'itemSingleZipFile'
              })
              if (this.hasWritePermission() && testsetremoteData) {
                /*
                if (testsetremoteData.testsetrepositories) {
                  testsetremoteData.testsetrepositories.forEach(t => {
                    exportItems.push({
                      id: t.id,
                      name: `... to Git Repository ${t.name}`,
                      onClick: () => this.setState({ showExportToGitDialog: true, showExportToGitError: null, selectedRepository: t }),
                      dataUnique: `item-${sanitize(slugify(t.name))}`
                    })
                  })
                }
                */
                if (testsetremoteData.testsetfolders) {
                  testsetremoteData.testsetfolders.forEach(t => {
                    exportItems.push({
                      id: t.id,
                      name: `... to Shared Folder ${t.name}`,
                      onClick: () => this.setState({ showExportToFolderDialog: true, showExportToFolderError: null, selectedFolder: t }),
                      dataUnique: `item-${sanitize(slugify(t.name))}`
                    })
                  })
                }
              }
              if (hasPermission(user, 'TESTSETS_UPLOAD')) {
                exportItems.push({
                  id: 'uploader',
                  name: `... to Conversation Model`,
                  onClick: () => history.push(`/testsets/view/${testSetId}/testcases/upload`),
                  dataUnique: `item-upload`
                })
              }
            }

            const testsetscripts = testsetscriptsData && testsetscriptsData.testsetscripts
            const testsetexcels = testsetexcelsData && testsetexcelsData.testsetexcels

            let cases = []
            if (data.testseteditableconvos && data.testseteditableconvos.length > 0) {
              data.testseteditableconvos.forEach(t => {
                const errorIcon = t.partial ? <ShowIcon custom icon="partialConvoError" /> : <ShowIcon custom icon="convoError" />
                const warningIcon = t.partial ? <ShowIcon custom icon="partialConvoWarning" /> : <ShowIcon custom icon="convoWarning" />
                const icon = (t.warnings || []).some(t => t.severity === 'ERROR') ? errorIcon : (t.warnings || []).some(t => t.severity === 'WARNING') ? warningIcon : t.partial ? <ShowIcon custom icon="partialConvo" /> : <ShowIcon custom icon="convo" />

                if ((this.props.testcasefilter.convos && !t.partial) || (this.props.testcasefilter.partialconvos && t.partial)) {
                  const convo = {
                    id: t.id,
                    scriptId: t.script.id,
                    name: t.name,
                    secondary: `${t.stepCount === 1 ? '1 conversation step' : `${t.stepCount} conversation steps`}, ${formatLastChange(t.script)}`,
                    selected: location.pathname.indexOf(t.script.id) > 0,
                    dataUnique: `liTestSetConvo_${t.name}`,
                    icon,
                    type: t.partial ? 'partialConvo' : 'convo',
                    actions: [
                      {
                        id: `delete_${t.id}`,
                        icon: 'trash',
                        name: 'Delete',
                        dataUnique: `btnTestSetConvo_${t.name}_Delete`,
                        onClick: () => this.setState({ selectedScript: t, showConvoDeleteDialog: true }),
                        disabled: !this.hasWritePermission()
                      },
                      {
                        id: `clone_${t.id}`,
                        icon: 'clone',
                        name: 'Clone',
                        dataUnique: `btnTestSetConvo_${t.name}_Clone`,
                        onClick: () => this.setState({ selectedScript: t, showConvoCloneDialog: true }),
                        disabled: !this.hasWritePermission()
                      },
                      {
                        id: `copy_${t.id}`,
                        icon: 'copy',
                        name: 'Copy to Test Set',
                        dataUnique: `btnTestSetConvo_${t.name}_Copy`,
                        onClick: () => this.setState({ selectedScript: t, showConvoCopyDialog: true }),
                        disabled: !this.hasWritePermission()
                      },
                      {
                        id: `move_${t.id}`,
                        icon: 'cut',
                        name: 'Move to Test Set',
                        dataUnique: `btnTestSetConvo_${t.name}_Move`,
                        onClick: () => this.setState({ selectedScript: t, showConvoMoveDialog: true }),
                        disabled: !this.hasWritePermission()
                      },
                    ],
                    onClick: () => testCaseViewMode === 'ui' ?
                      history.push(`/testsets/view/${testSetId}/testcases/${t.partial ? 'viewpconvo' : 'viewconvo'}/${t.script.id}/${encodeURIComponent(t.name)}`) :
                      history.push(`/testsets/view/${testSetId}/testcases/viewscript/${t.script.id}`),
                  }
                  cases.push(convo)
                }
              })
            }
            if (data.testseteditableutterances && data.testseteditableutterances.length > 0 && this.props.testcasefilter.utterances) {
              data.testseteditableutterances.forEach(t => {
                const icon = (t.warnings || []).some(t => t.severity === 'ERROR') ? <ShowIcon custom icon="utteranceError" /> : (t.warnings || []).some(t => t.severity === 'WARNING') ? <ShowIcon custom icon="utteranceWarning" /> : <ShowIcon custom icon="utterance" />

                const utt = {
                  id: t.id,
                  scriptId: t.script.id,
                  name: t.name,
                  secondary: `${t.uttCount === 1 ? '1 user example' : `${t.uttCount} user examples`}, ${formatLastChange(t.script)}`,
                  selected: location.pathname.indexOf(t.script.id) > 0,
                  dataUnique: `liTestSetUtterance_${t.name}`,
                  icon,
                  type: 'utterance',
                  actions: [
                    {
                      id: `delete_${t.id}`,
                      icon: 'trash',
                      name: 'Delete',
                      dataUnique: `btnTestSetUtterance_${t.name}_Delete`,
                      onClick: () => this.setState({ selectedScript: t, showUtteranceDeleteDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                    {
                      id: `clone_${t.id}`,
                      icon: 'clone',
                      name: 'Clone',
                      dataUnique: `btnTestSetUtterance_${t.name}_Clone`,
                      onClick: () => this.setState({ selectedScript: t, showUtteranceCloneDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                    {
                      id: `copy_${t.id}`,
                      icon: 'copy',
                      name: 'Copy to Test Set',
                      dataUnique: `btnTestSetUtterance_${t.name}_Copy`,
                      onClick: () => this.setState({ selectedScript: t, showUtteranceCopyDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                    {
                      id: `move_${t.id}`,
                      icon: 'cut',
                      name: 'Move to Test Set',
                      dataUnique: `btnTestSetUtterance_${t.name}_Move`,
                      onClick: () => this.setState({ selectedScript: t, showUtteranceMoveDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                  ],
                  onClick: () => testCaseViewMode === 'ui' ?
                    history.push(`/testsets/view/${testSetId}/testcases/viewutterance/${t.script.id}/${encodeURIComponent(t.name)}`) :
                    history.push(`/testsets/view/${testSetId}/testcases/viewscript/${t.script.id}`),
                }
                cases.push(utt)
              })
            }
            if (testsetscripts && testsetscripts.length > 0) {
              testsetscripts.forEach(t => {
                if (t.scriptType !== 'SCRIPTING_TYPE_UTTERANCES' && t.scriptType !== 'SCRIPTING_TYPE_CONVO' && t.scriptType !== 'SCRIPTING_TYPE_PCONVO') {
                  if ((this.props.testcasefilter.others && t.scriptType !== 'SCRIPTING_TYPE_SCRIPTING_MEMORY') || (this.props.testcasefilter.parameterstores && t.scriptType === 'SCRIPTING_TYPE_SCRIPTING_MEMORY')) {
                    const script = {
                      id: t.id,
                      scriptId: t.id,
                      name: t.name,
                      secondary: formatLastChange(t),
                      type: t.scriptType === 'SCRIPTING_TYPE_SCRIPTING_MEMORY' ? 'scriptingMemory' : 'script',
                      selected: location.pathname.indexOf(t.id) > 0,
                      dataUnique: `liTestSetScript_${t.name}`,
                      onClick: () => history.push(`/testsets/view/${testSetId}/testcases/viewscript/${t.id}`),
                      actions: [
                        {
                          id: `delete_${t.id}`,
                          icon: 'trash',
                          name: 'Delete',
                          dataUnique: `btnTestSetScript_${t.name}_Delete`,
                          onClick: () => this.setState({ selectedScript: t, showScriptDeleteDialog: true }),
                          disabled: !this.hasWritePermission()
                        },
                        {
                          id: `copy_${t.id}`,
                          icon: 'copy',
                          name: 'Copy to Test Set',
                          dataUnique: `btnTestSetScript_${t.name}_Copy`,
                          onClick: () => this.setState({ selectedScript: t, showScriptCopyDialog: true }),
                          disabled: !this.hasWritePermission()
                        },
                        {
                          id: `move_${t.id}`,
                          icon: 'cut',
                          name: 'Move to Test Set',
                          dataUnique: `btnTestSetScript_${t.name}_Move`,
                          onClick: () => this.setState({ selectedScript: t, showScriptMoveDialog: true }),
                          disabled: !this.hasWritePermission()
                        }
                      ]
                    }
                    if (t.scriptType === 'SCRIPTING_TYPE_CSV') script.icon = <ShowIcon custom icon="csvFile" />
                    else if (t.scriptType === 'SCRIPTING_TYPE_YAML') script.icon = <ShowIcon custom icon="yamlFile" />
                    else if (t.scriptType === 'SCRIPTING_TYPE_JSON') script.icon = <ShowIcon custom icon="jsonFile" />
                    else if (t.scriptType === 'SCRIPTING_TYPE_SCRIPTING_MEMORY') script.icon = <ShowIcon custom icon="scriptingMemoryFile" />
                    else script.icon = <ShowIcon icon="file-code" />
                    cases.push(script)
                  }
                }
              })
            }
            if (testsetexcels && testsetexcels.length > 0 && this.props.testcasefilter.excel) {
              testsetexcels.forEach(t => {
                const script = {
                  id: t.id,
                  scriptId: t.id,
                  name: t.name,
                  secondary: formatLastChange(t),
                  selected: location.pathname.indexOf(t.id) > 0,
                  dataUnique: `liTestSetExcel_${t.name}`,
                  onClick: () => history.push(`/testsets/view/${testSetId}/testcases/viewexcel/${t.id}`),
                  icon: <ShowIcon custom icon="xlsFile" />,
                  type: 'excel',
                  actions: [
                    {
                      id: `delete_${t.id}`,
                      icon: 'trash',
                      name: 'Delete',
                      dataUnique: `btnTestSetExcel_${t.name}_Delete`,
                      onClick: () => this.setState({ selectedScript: t, showExcelDeleteDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                    {
                      id: `copy_${t.id}`,
                      icon: 'copy',
                      name: 'Copy to Test Set',
                      dataUnique: `btnTestSetExcel_${t.name}_Copy`,
                      onClick: () => this.setState({ selectedScript: t, showExcelCopyDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                    {
                      id: `move_${t.id}`,
                      icon: 'cut',
                      name: 'Move to Test Set',
                      dataUnique: `btnTestSetExcel_${t.name}_Move`,
                      onClick: () => this.setState({ selectedScript: t, showExcelMoveDialog: true }),
                      disabled: !this.hasWritePermission()
                    },
                  ]
                }
                cases.push(script)
              })
            }
            cases = _.sortBy(cases, [s => s.name.toLowerCase()])

            return (<GridContainer >
              <GridItem xs={4}>
                {this.hasWritePermission() && <React.Fragment>
                  <DropdownButton secondary noCaret
                    data-unique="ddbtnTestSetNew"
                    items={[
                      {
                        id: 'new_convo',
                        icon: 'plus',
                        name: 'Convo',
                        secondaryText: '*.convo.txt',
                        dataUnique: 'btnTestSetTestCasesRegisterConvo',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerconvo')
                        }
                      },
                      {
                        id: 'new_partial_convo',
                        icon: 'plus',
                        name: 'Partial Convo',
                        secondaryText: '*.pconvo.txt',
                        dataUnique: 'btnTestSetTestCasesRegisterPartialConvo',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerpconvo')
                        }
                      },
                      {
                        id: 'new_utterance',
                        icon: 'plus',
                        name: 'Utterances',
                        secondaryText: '*.utterances.txt',
                        dataUnique: 'btnTestSetTestCasesRegisterUtterance',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerutterance')
                        }
                      },
                      {
                        id: 'divider_1',
                        divider: true
                      },
                      {
                        id: 'new_scripting',
                        icon: 'plus',
                        name: 'Test Parameter Store',
                        secondaryText: '*.scriptingmemory.txt',
                        dataUnique: 'btnTestSetTestCasesRegisterScriptingMemory',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerscript/scripting')
                        }
                      },
                      {
                        id: 'new_yaml',
                        icon: 'plus',
                        name: 'Combined YAML',
                        secondaryText: '*.yaml',
                        dataUnique: 'btnTestSetTestCasesRegisterYAML',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerscript/yaml')
                        }
                      },
                      {
                        id: 'new_json',
                        icon: 'plus',
                        name: 'Combined JSON',
                        secondaryText: '*.json',
                        dataUnique: 'btnTestSetTestCasesRegisterJSON',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerscript/json')
                        }
                      },
                      {
                        id: 'new_csv',
                        icon: 'plus',
                        name: 'CSV',
                        secondaryText: '*.csv',
                        dataUnique: 'btnTestSetTestCasesRegisterCSV',
                        onClick: () => {
                          this.setState({ newCounter: this.state.newCounter + 1 })
                          history.push('/testsets/view/' + testSetId + '/testcases/registerscript/csv')
                        }
                      },
                      {
                        id: 'divider_2',
                        divider: true
                      },
                      {
                        id: 'zip_import',
                        icon: 'plus',
                        name: 'Import from file',
                        dataUnique: 'ddbtniSingleFile',
                        onClick: () => this.setState({ showImportFromZipDialog: true, showImportFromZipError: null })
                      }
                    ]}
                  >
                    <ShowIcon icon="plus" /> New
                  </DropdownButton>
                </React.Fragment>}
              </GridItem>
              <GridItem xs={8} right middle>
                <DropdownButton link secondary noCaret
                                disabled={this.state.selectedItems.length === 0}
                                data-unique="ddbtnTestSetBulkAction"
                                items={[
                                  {
                                    id: 'bulkDeleteScript',
                                    icon: 'trash',
                                    name: 'Delete',
                                    dataUnique: 'btnTestSetBulkDeleteScript',
                                    onClick: () => this.setState({ showScriptBulkDeleteDialog: true }),
                                    disabled: !this.hasWritePermission()
                                  },
                                  {
                                    id: 'bulkCloneScript',
                                    icon: 'clone',
                                    name: 'Clone',
                                    dataUnique: 'btnTestSetBulkCloneScript',
                                    onClick: () => this.setState({ showScriptBulkCloneDialog: true }),
                                    disabled: !this.hasWritePermission()
                                  },
                                  {
                                    id: 'bulkCopyScript',
                                    icon: 'copy',
                                    name: 'Copy to Test Set',
                                    dataUnique: 'btnTestSetBulkCopyScript',
                                    onClick: () => this.setState({ showScriptBulkCopyDialog: true }),
                                    disabled: !this.hasWritePermission()
                                  },
                                  {
                                    id: 'bulkMoveScript',
                                    icon: 'cut',
                                    name: 'Move to Test Set',
                                    dataUnique: 'btnTestSetBulkCopyScript',
                                    onClick: () => this.setState({ showScriptBulkMoveDialog: true }),
                                    disabled: !this.hasWritePermission()
                                  },
                                ]}
                >
                  <ShowIcon icon="redo" /> Bulk Action
                </DropdownButton>
                <Text primary>|</Text>
                {this.hasWritePermission() &&
                  <React.Fragment>
                    <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                      {({ data }) => {
                        if (data.testset) {
                          return <Mutation
                            mutation={UPDATE_TESTSETSTATS}
                            onCompleted={data1 => {
                              if (data.testset.statsSkipCoach) {
                                setAlertSuccessMessage('Test Set Statistics are updated.')
                              } else {
                                setAlertSuccessMessage('Test Set Insights are updating in the background.')
                              }
                            }}
                            onError={error => {
                              setAlertErrorMessage('Test Set Insights failed', error)
                            }}
                            refetchQueries={[
                              ...RefetchTestSetQueries(testSetId, license)
                            ]}
                          >
                            {(updateTestSetStats, { loading }) => (
                              <Tooltip title="Retrieves Test Cases from external connections (Git Repositories, ...) and analyzes the Test Set">
                                <Button
                                  link
                                  onClick={() => {
                                    updateTestSetStats({
                                      variables: { id: testSetId },
                                    })
                                  }}
                                  disabled={loading}
                                  data-unique="btnTestSetSyncRepos"
                                >
                                  {loading && <><LoadingIndicator alt />Synchronize</>}
                                  {!loading && <><ShowIcon icon="sync" />Synchronize</>}
                                </Button>
                              </Tooltip>
                            )}
                          </Mutation>
                        }
                        return null
                      }}
                    </Query>
                    <Text primary>|</Text>
                  </React.Fragment>
                }
                <DropdownButton link secondary noCaret
                  data-unique="ddbtnTestSetExport"
                  items={exportItems}
                >
                  <ShowIcon icon="external-link" /> Export
                </DropdownButton>
                <Text primary>|</Text>
                <Button link secondary noCaret onClick={() => this.setState({ showTreeDialog: true })}>
                  <ShowIcon icon="project-diagram" /> Flow
                </Button>
                {this.renderTreeDialog(testSetId, testSetName)}
              </GridItem>
              <GridItem lg={12}>
                <Card>
                  <CardBody noPaddingTop>
                    <GridContainer>
                      <GridItem md={4} lg={3} borderRight noMargin noPadding className={classes.gridWidthLeft} >
                        <SimpleList
                          name="TestSet_TestCases"
                          listData={cases}
                          onRefresh={() => refetch()}
                          pageLoading={loading}
                          onSelectionChange={selectedItems => {
                            this.setState({ selectedItems })
                          }}
                          mapSelectIds={data => data && data.map(t => t.scriptId)}
                          selectIdField={'scriptId'}
                          dropDownFilterComponent={this.renderTypeFilterDropDownButton()}
                        />
                      </GridItem>
                      <GridItem md={8} lg={9} className={classes.gridWidthRight} >
                      <Switch>
                              <Route
                                path="/testsets/view/:testSetId/testcases/registerutterance"
                                render={props => <TestSetUtterance setParentState={this.setParentState} key={`new_${this.state.newCounter}`} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/viewutterance/:testSetScriptId/:name"
                                render={props => <TestSetUtterance setParentState={this.setParentState} key={props.match.params.testSetScriptId + props.match.params.name} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/registerconvo"
                                render={props => <TestSetConvo setParentState={this.setParentState} key={`new_${this.state.newCounter}`} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/registerpconvo"
                                render={props => <TestSetConvo setParentState={this.setParentState} key={`new_${this.state.newCounter}`} {...props} partial />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/viewconvo/:testSetScriptId/:name"
                                render={props => <TestSetConvo setParentState={this.setParentState} key={props.match.params.testSetScriptId + props.match.params.name} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/viewpconvo/:testSetScriptId/:name"
                                render={props => <TestSetConvo setParentState={this.setParentState} key={props.match.params.testSetScriptId + props.match.params.name} {...props} partial />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/registerscript/:type"
                                render={props => <TestSetScript setParentState={this.setParentState} key={`new_${this.state.newCounter}`} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/viewscript/:id"
                                render={props => <TestSetScript setParentState={this.setParentState} key={props.match.params.testSetScriptId} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/registerexcel"
                                render={props => <TestSetExcel setParentState={this.setParentState} key={`new_${this.state.newCounter}`} {...props} />}
                              />
                              <Route
                                path="/testsets/view/:testSetId/testcases/viewexcel/:id"
                                render={props => <TestSetExcel setParentState={this.setParentState} key={props.match.params.id} {...props} />}
                              />
                              <Route component={() => <>
                                  <GridContainer>
                                    <GridItem xs={12} center marginTop paddingTop> 
                                      <img src={selecttestcase} alt="No Data" />
                                    </GridItem>
                                    <GridItem xs={12} center>
                                      <Text padding>Please select a test case in the menu on the left, or add a new one.</Text>
                                    </GridItem>
                                  </GridContainer>
                                </>
                              } />
                            </Switch>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>)
          }}
        </Query>
      </GridItem>
    </GridContainer>)
  }

  isGitEdition() {
    const { license } = this.props
    return license && (license.gitIntegration)
  }

  isHumanificationEdition() {
    const { license } = this.props
    return license && (license.humanification)
  }

  isTranslationEdition() {
    const { license } = this.props
    return license && (license.translation)
  }

  renderRemoteSourcesTab(testSetId) {
    const { history, location, license } = this.props
    const { testsetremoteData } = this.props

    const loading = testsetremoteData && testsetremoteData.loading
    const refetch = () => {
      testsetremoteData && testsetremoteData.refetch && testsetremoteData.refetch()
    }

    if (testsetremoteData && testsetremoteData.error) {
      return <ErrorFormat err={testsetremoteData.error} />
    }

    const testsetrepositories = testsetremoteData && testsetremoteData.testsetrepositories
    const testsetfolders = testsetremoteData && testsetremoteData.testsetfolders
    const testsetdownloadlinks = testsetremoteData && testsetremoteData.testsetdownloadlinks

    let repositories = []
    if (testsetrepositories && testsetrepositories.length > 0) {
      testsetrepositories.forEach(t => {
        const r = {
          id: t.id,
          name: t.name,
          secondary: `${t.skip ? '(Ignored) ' : ''}${formatLastChange(t)}`,
          selected: location.pathname.indexOf(t.id) > 0,
          onClick: () => history.push(`/testsets/view/${testSetId}/settings/remote/viewrepository/${t.id}`),
          icon: <ShowIcon icon={faGitSquare} />
        }
        repositories.push(r)
      })
    }
    if (testsetfolders && testsetfolders.length > 0) {
      testsetfolders.forEach(t => {
        const r = {
          id: t.id,
          name: t.name,
          secondary: `${t.skip ? '(Ignored) ' : ''}${formatLastChange(t)}`,
          selected: location.pathname.indexOf(t.id) > 0,
          onClick: () => history.push(`/testsets/view/${testSetId}/settings/remote/viewfolder/${t.id}`),
          icon: <ShowIcon icon="share-alt-square" />
        }
        repositories.push(r)
      })
    }
    if (testsetdownloadlinks && testsetdownloadlinks.length > 0) {
      testsetdownloadlinks.forEach(t => {
        const r = {
          id: t.id,
          name: t.name,
          secondary: `${t.skip ? '(Ignored) ' : ''}${formatLastChange(t)}`,
          selected: location.pathname.indexOf(t.id) > 0,
          onClick: () => history.push(`/testsets/view/${testSetId}/settings/remote/viewdownloadlink/${t.id}`),
          icon: <ShowIcon icon="link" />
        }
        repositories.push(r)
      })
    }
    repositories = _.sortBy(repositories, [r => r.name.toLowerCase()])

    const getRegisterItems = () => {

      const items = []

      if (this.isGitEdition()) {
        items.push({
          id: 'newGitRepository',
          name: `Git Repository`,
          dataUnique: 'btnTestSetSettingsNewGitRepository',
          onClick: () => {
            this.setState({ newCounter: this.state.newCounter + 1 })
            history.push('/testsets/view/' + testSetId + '/settings/remote/registerrepository')
          }
        })
        if (!license.shared) {
          items.push({
            id: 'newSharedFolder',
            name: `Shared Folder`,
            dataUnique: 'btnTestSetSettingsNewSharedFolder',
            onClick: () => {
              this.setState({ newCounter: this.state.newCounter + 1 })
              history.push('/testsets/view/' + testSetId + '/settings/remote/registerfolder')
            }
          })
        }
        items.push({
          id: 'newDownloadLink',
          name: `Download Link`,
          dataUnique: 'btnTestSetSettingsNewDownloadLink',
          onClick: () => {
            this.setState({ newCounter: this.state.newCounter + 1 })
            history.push('/testsets/view/' + testSetId + '/settings/remote/registerdownloadlink')
          }
        })
      }
      return items
    }

    return (
      <GridContainer >
        <GridItem xs={12}>
          {this.hasWritePermission() && <React.Fragment>
            <DropdownButton
              secondary
              noCaret
              data-unique="ddbtnTestSetSettingsNewConnection"
              items={getRegisterItems()}
            >
              <ShowIcon icon="plus" /> New
            </DropdownButton>
          </React.Fragment>}
        </GridItem>
        <GridItem xs={12} sm={4} md={3} noPadding>
          <SimpleList
            name="TestSet_RemoteSources"
            listData={repositories}
            onRefresh={() => refetch()}
            pageLoading={loading}
          />
        </GridItem>
        <GridItem xs={12} sm={8} md={9}>
          <Switch>
            <Route
              path="/testsets/view/:testSetId/settings/remote/registerrepository"
              render={props => <TestSetRepository key={`new_${this.state.newCounter}`} {...props} />}
            />
            <Route
              path="/testsets/view/:testSetId/settings/remote/viewrepository/:id"
              render={props => <TestSetRepository key={`new_${props.match.params.id}`} {...props} />}
            />
            <Route
              path="/testsets/view/:testSetId/settings/remote/registerfolder"
              render={props => <TestSetFolder key={`new_${this.state.newCounter}`} {...props} />}
            />
            <Route
              path="/testsets/view/:testSetId/settings/remote/viewfolder/:id"
              render={props => <TestSetFolder key={`new_${props.match.params.id}`} {...props} />}
            />
            <Route
              path="/testsets/view/:testSetId/settings/remote/registerdownloadlink"
              render={props => <TestSetDownloadLink key={`new_${this.state.newCounter}`} {...props} />}
            />
            <Route
              path="/testsets/view/:testSetId/settings/remote/viewdownloadlink/:id"
              render={props => <TestSetDownloadLink key={`new_${props.match.params.id}`} {...props} />}
            />
            <Route component={() => <Text padding>Please select a repository in the menu on the left, or register a new one.</Text>} />
          </Switch>
        </GridItem>
      </GridContainer>
    )
  }

  renderCloneDialog(testSet) {
    const { setAlertSuccessMessage, history } = this.props

    return (
      <Mutation
        mutation={CLONE_TESTSET_REFERENCES}
        refetchQueries={() => {
          return [
            ...RefetchTestSetQueries()
          ]
        }}
      >
        {(cloneTestSet, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                const res = await cloneTestSet({
                  variables: {
                    id: testSet.id,
                    options: {
                      name: values.name,
                      cloneRepositories: values.cloneRepositories,
                      cloneDownloadLinks: values.cloneDownloadLinks,
                      cloneSharedFolders: values.cloneSharedFolders,
                    },
                  },
                })
                this.setState({ showCloneDialog: false, showCloneError: null })
                setAlertSuccessMessage('Test Set cloned')
                history.push(`/testsets/view/${res.data.cloneTestSetReferences.id}`)
              } catch (error) {
                // workaround. Until we dont have a better solution for long running background tasks, we suppress the error message.
                if (error?.networkError?.statusCode === 504) {
                  this.setState({ showCloneDialog: false, showCloneError: null })
                  setAlertSuccessMessage('Your request is taking longer than expected. The system will continue running this task in the background, please check later.')
                } else {
                  this.setState({ showCloneError: error })
                }
              }
            }}
            initialValues={{
              name: `Copy of ${testSet.name}`,
              cloneRepositories: true,
              cloneDownloadLinks: true,
              cloneSharedFolders: true
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={this.state.showCloneDialog}
                onCancel={() => this.setState({ showCloneDialog: false })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title="Clone Test Set"
                showError={this.state.showCloneError && `Cloning Test Set failed: ${extractErrorMessage(this.state.showCloneError, true)}`}
                clearError={() => this.setState({ showCloneError: null })}
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        component={renderTextField}
                        label="New Test Set Name"
                        validate={required}
                        data-unique="txtTestSetCloneTestSetName"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="cloneRepositories"
                        component={renderCheckbox}
                        label="Clone External Git Repository connections"
                        type="checkbox"
                        data-unique="chkTestSetCloneRepositories"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="cloneSharedFolders"
                        component={renderCheckbox}
                        label="Clone External Shared Folder connections"
                        type="checkbox"
                        data-unique="chkTestSetCloneSharedFolders"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <Field
                        name="cloneDownloadLinks"
                        component={renderCheckbox}
                        label="Clone External Download Link connections"
                        type="checkbox"
                        data-unique="chkTestSetCloneDownloadLinks"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderExportToFolderDialog(testSetId) {
    const { setAlertSuccessMessage } = this.props
    const { showExportToFolderError, selectedFolder } = this.state

    return (
      <Mutation
        mutation={EXPORT_TESTSET_TO_FOLDER}
        onCompleted={data => {
          this.setState({ showExportToFolderDialog: false, showExportToFolderError: null })
          setAlertSuccessMessage(`Test Cases exported to Shared Folder ${selectedFolder.name}`)
        }}
        onError={error => {
          this.setState({ showExportToFolderError: error })
        }}
      >
        {(exportTestSet, { loading }) => (
          <Form
            onSubmit={values => {
              exportTestSet({
                variables: {
                  id: testSetId,
                  options: {
                    id: selectedFolder.id,
                    subdirectory: values.subdirectory
                  },
                },
              })
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={this.state.showExportToFolderDialog}
                onCancel={() => this.setState({ showExportToFolderDialog: false, showExportToFolderError: null })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title={`Export Test Cases to Shared Folder ${selectedFolder && selectedFolder.name}`}
                showError={showExportToFolderError && `Exporting Test Cases to Shared Folder ${selectedFolder.name} failed: ${extractErrorMessage(showExportToFolderError, true)}`}
                clearError={() => this.setState({ showExportToFolderError: null })}
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      A copy of all test case scripts will be written to the shared folder. You can select a subdirectory below (will be created if not existing).
                    </GridItem>
                    <GridItem xs={12}>
                      <Text danger bold>
                        Attention: all existing scripts will be overwritten!
                      </Text>
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="subdirectory"
                        component={renderTextField}
                        label="Subdirectory"
                        data-unique="txtTestSetExportSubdirectory"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  /*
  renderExportToGitDialog(testSetId) {
    const { setAlertSuccessMessage } = this.props
    const { showExportToGitError, selectedRepository } = this.state

    return (
      <Mutation
        mutation={EXPORT_TESTSET_TO_GIT}
        onCompleted={data => {
          this.setState({ showExportToGitDialog: false, showExportToGitError: null })
          setAlertSuccessMessage(`Test Cases pushed to Git Repository ${selectedRepository.name}`)
        }}
        onError={error => {
          this.setState({ showExportToGitError: error })
        }}
      >
        {(exportTestSet, { loading }) => (
          <Form
            onSubmit={values => {
              exportTestSet({
                variables: {
                  id: testSetId,
                  options: {
                    id: selectedRepository.id,
                    gitbranch: values.gitbranch === 'new' ? values.newbranchname : values.gitbranch,
                    gituser: values.gituser,
                    gitpassword: values.gitpassword,
                    gitcomment: values.gitcomment,
                    cleangitdir: values.cleangitdir
                  },
                }
              })
            }}
            initialValues={{ gitbranch: selectedRepository && selectedRepository.gitbranch, gitcomment: `Botium Checkin` }}
            render={({ handleSubmit, values, form: { change } }) => (
              <ConfirmationDialog
                open={this.state.showExportToGitDialog}
                onCancel={() => this.setState({ showExportToGitDialog: false, showExportToGitError: null })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title={`Push Test Cases to Git Repository ${selectedRepository && selectedRepository.name}`}
                showError={showExportToGitError && `Pushing Test Cases to Git Repository ${selectedRepository.name} failed: ${extractErrorMessage(showExportToGitError, true)}`}
                clearError={() => this.setState({ showExportToGitError: null })}
              >
                <form onSubmit={handleSubmit}>
                  <Query
                    query={GITREPOSITORYBRANCHES_QUERY}
                    variables={{
                      testSetId: testSetId,
                      id: selectedRepository ? selectedRepository.id : null,
                      giturl: selectedRepository ? selectedRepository.giturl : null,
                      gituser: selectedRepository ? selectedRepository.gituser : null,
                      gitpassword: selectedRepository ? selectedRepository.gitpassword : null,
                    }}
                    skip={!selectedRepository || !selectedRepository.giturl}
                  >
                    {({ loading, error, data }) => {
                      if (loading) {
                        return <LoadingIndicator />
                      }
                      if (error) {
                        return <ErrorFormat err={error} />
                      }
                      if (!values.gitbranch || values.gitbranch === '') {
                        change('gitbranch', 'new')
                      }
                      const items = []
                      items.push({
                        key: 'new',
                        label: '--- Create new branch ---'
                      })
                      if (data && data.gitrepositorybranches && data.gitrepositorybranches.remoteBranches && data.gitrepositorybranches.remoteBranches.length > 0) {
                        data.gitrepositorybranches.remoteBranches.forEach(a => items.push({
                          key: a,
                          label: a
                        }))
                      }

                      return (
                        <GridContainer>
                          <GridItem xs={12}>
                            A copy of all scripts from the local repository will be committed and pushed to the Git Repository.
                            You can either select or create a git branch for your commit below.
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="gitbranch"
                              component={renderSelect}
                              label="Select Git Branch"
                              data-unique="selTestSetExportGitBranch"
                              items={items}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            {values.gitbranch === 'new' &&
                              <Field
                                name="newbranchname"
                                component={renderTextField}
                                label="New Branch Name"
                                validate={required}
                                data-unique="txtTestSetExportNewBranchName"
                                helperText="A new branch will be created from main (or master) branch of the repository"
                              />
                            }
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="gituser"
                              component={renderTextField}
                              label="Git User"
                              data-unique="txtTestSetExportGitUser"
                              helperText={`Only if different from default configured user ${(selectedRepository && selectedRepository.gituser) ? `"${selectedRepository.gituser}` : ''}`}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <Field
                              name="gitpassword"
                              component={renderPasswordField}
                              label="Git Password or Access Token"
                              data-unique="pwTestSetExportGitPassword"
                              helperText="Only if different from default configured password"
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name="gitcomment"
                              component={renderTextField}
                              label="Git checkin comment"
                              validate={required}
                              data-unique="txtTestSetExportGitComment"
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name="cleangitdir"
                              component={renderCheckbox}
                              label="Clean before commit"
                              type="checkbox"
                              data-unique="chkTestSetExportCleanGitDir"
                            />
                            <Text warning>
                              Be careful! If you enable this option the '{selectedRepository.gitdir || 'root'}' directory is going to be cleaned before commit.
                            </Text>
                          </GridItem>
                        </GridContainer>
                      )
                    }}
                  </Query>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }
  */

  renderImportFromZipDialog(testSetId) {
    const { setAlertSuccessMessage, license } = this.props
    const { showImportFromZipError } = this.state

    return (
      <Mutation
        mutation={IMPORT_TESTSET_FROM_FILE}
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(importTestSet, { loading, error }) => (
          <Form
            onSubmit={async values => {
              try {
                this.setState({ testsetUploading: true })
                await importTestSet({
                  variables: {
                    id: testSetId,
                    filename: values.filename,
                    filecontent: values.filecontent,
                    overwrite: !!values.overwrite
                  },
                })
                this.setState({ showImportFromZipDialog: false, showImportFromZipError: null, testsetUploading: false })
                setAlertSuccessMessage(`File imported to local repository`)
              } catch (error) {
                this.setState({ showImportFromZipError: error, testsetUploading: false })
              }
            }}
            render={({ handleSubmit }) => (
              <ConfirmationDialog
                open={this.state.showImportFromZipDialog}
                onCancel={() => this.setState({ showImportFromZipDialog: false, showImportFromZipError: null, showImportFromZipFileLoaded: false })}
                onOk={() => handleSubmit()}
                okText="Import Test Cases"
                isWorking={loading}
                okDisabled={!this.state.showImportFromZipFileLoaded}
                title={`Import from file`}
              >
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <TestSetImport
                        testsetUploading={this.state.testsetUploading}
                        importOnDrop={false}
                        dataUnique="fileTestSetImportLocalFromFile"
                        onFileLoaded={(filename, filecontent) => {
                          if (filename) {
                            this.setState({
                              showImportFromZipFileLoaded: true,
                              showImportFromZipError: false
                            })
                          } else {
                            this.setState({
                              showImportFromZipFileLoaded: false,
                              showImportFromZipError: false
                            })
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="overwrite"
                        component={renderCheckbox}
                        label="Overwrite test case(s)"
                        type="checkbox"
                        helperText="Overwrite existing test case(s) based on name(s)"
                        data-unique="chkTestSetOverwrite"
                      />
                    </GridItem>
                    {showImportFromZipError &&
                      <GridItem xs={12}>
                        <ErrorFormat prefix="Import to Test Set failed" err={showImportFromZipError} />
                      </GridItem>
                    }
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderStats(testSetId) {
    const { getTablePaginationVariables, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    const { user, history, license, classes } = this.props

    const tblNameCompiledConvos = `${testSetId}_statsCompiledConvos`
    const tblNameCompiledUtterances = `${testSetId}_statsCompiledUtterances`

    const getStepSamples = (stepSamples, stepCount) => {
      if (stepSamples && stepSamples.length > 0 && stepSamples.length < stepCount) {
        return [...stepSamples, `... ${stepCount - stepSamples.length} more`]
      } else {
        return stepSamples || []
      }
    }
    const getUttSamples = (uttSamples, uttCount) => {
      if (uttSamples && uttSamples.length > 0 && uttSamples.length < uttCount) {
        return [...uttSamples, `... ${uttCount - uttSamples.length} more`]
      } else {
        return uttSamples || []
      }
    }

    return (<GridContainer>
      {this.hasReadPermission() && <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={12}>
            <Card secondary>
              <CardBody>
                <GridContainer>
                  <GridItem md={4} lg={6}>
                    <Text header>Test Case Count</Text>
                    <Text subheader>Test Set Statistics</Text>
                  </GridItem>
                  <GridItem md={8} lg={6} right>

                        <Button
                          spaceRight
                          className={classes.dashboardbutton}
                          data-unique="btnTestSetUtteranceInsights"
                          onClick={() => history.push(`/testsets/view/${testSetId}/utteranceinsights`)}
                          link
                        >
                          <ShowIcon icon="chart-line" />
                          Utterance Analytics
                        </Button>
                        {this.hasWritePermission() &&
                          <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                            {({ data }) => {
                              if (data && data.testset) {
                                return <Mutation
                                  mutation={UPDATE_TESTSETSTATS}
                                  onCompleted={data1 => {
                                    if (data.testset.statsSkipCoach) {
                                      setAlertSuccessMessage('Test Set Statistics are updated.')
                                    } else {
                                      setAlertSuccessMessage('Test Set Insights are updating in the background.')
                                    }
                                  }}
                                  onError={error => {
                                    setAlertErrorMessage(`Test Set Insights failed`, error)
                                  }}
                                  refetchQueries={[
                                    ...RefetchTestSetQueries(testSetId, license)
                                  ]}
                                >
                                  {(updateTestSetStats, { loading }) => (
                                    <Button
                                      className={classes.dashboardbutton}
                                      link
                                      onClick={() => {
                                        updateTestSetStats({
                                          variables: { id: testSetId },
                                        })
                                      }}
                                      disabled={loading}
                                      data-unique="btnTestSetSyncRepos"
                                    >
                                      {loading && <LoadingIndicator alt />}
                                      {!loading && <RefreshIcon />}
                                      {!loading && data.testset.statsSkipCoach && 'Update Statistics'}
                                      {!loading && !data.testset.statsSkipCoach && 'Update Statistics & Insights'}
                                      {loading && data.testset.statsSkipCoach && 'Updating Statistics ...'}
                                      {loading && !data.testset.statsSkipCoach && 'Updating Statistics & Insights ...'}
                                    </Button>
                                  )}
                                </Mutation>
                              }
                              return null
                            }}
                          </Query>
                        }

                  </GridItem>
                  <GridItem md={6} lg={6}>
                    <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                      {({ loading, error, data, refetch }) => {
                        if (loading) return <LoadingIndicator />
                        if (error) return <ErrorFormat err={error} />
                        if (data.testset && data.testset.statsUpdatedAt) {
                          return (
                            <GridContainer classes={{
                              grid: classes.leftDatacards
                            }}>
                              <GridItem md={4} lg={2}>
                                <StatsText data-unique="lblTotalNumberOfConvos" primaryText={data && data.testset && data.testset.statsConvoCount} secondaryText="Convos" />
                              </GridItem>
                              <GridItem md={4} lg={2}>
                                <StatsText data-unique="lblTotalNumberOfPartialConvos" primaryText={data && data.testset && data.testset.statsPartialConvoCount} secondaryText="Partial Convos" />
                              </GridItem>
                              <GridItem md={4} lg={2}>
                                <StatsText data-unique="lblTotalNumberOfUtterances" primaryText={data && data.testset && data.testset.statsUtterancesCount} secondaryText="Utterances" />
                              </GridItem>
                              <GridItem md={4} lg={2}>
                                <StatsText data-unique="lblTotalNumberOfUtteranceRefs" primaryText={data && data.testset && data.testset.statsUtterancesRefCount} secondaryText="Utterances Refs" />
                              </GridItem>
                              <GridItem md={4} lg={2}>
                                <StatsText data-unique="lblTotalNumberOfScriptingMemories" primaryText={data && data.testset && data.testset.statsScriptingMemoryCount} secondaryText="Test Parameter Stores" />
                              </GridItem>
                              <GridItem md={4} lg={2}>
                                <StatsText secondaryText="Last Statistics Update" primaryText={splitDateInfo(moment(data.testset.statsUpdatedAt).fromNow(true)).prefix} primarySuffix={splitDateInfo(moment(data.testset.statsUpdatedAt).fromNow(true)).suffix} />
                              </GridItem>
                            </GridContainer>
                          )
                        }
                        return null
                      }
                      }
                    </Query>
                  </GridItem>
                  <GridItem md={4} lg={2}>
                    <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                          {({ data }) => {
                            if (data && data.testset && data.testset.lastCoachSessions && data.testset.lastCoachSessions.length > 0 && data.testset.lastCoachSessions[0].coachSession && data.testset.lastCoachSessions[0].coachSession.id) {
                              return (<TestSessionProgress
                                query={TESTSETCOACHSESSION_QUERY}
                                querySelector={data => data.testsetcoachsession}
                                subscription={TESTSETCOACHSESSION_SUBSCRIPTION}
                                subscriptionSelector={data => data.coachSessionProgress}
                                testSession={{ id: data.testset.lastCoachSessions[0].coachSession.id }}>
                                {({ testSessionProgress: coachSessionProgress, testSessionProgressLoading: coachSessionProgressLoading }) => {
                                  if (!coachSessionProgressLoading && coachSessionProgress) {
                                    if (coachSessionProgress.kfoldReady) {
                                      if (coachSessionProgress.kfoldF1) {
                                        return <NavLink
                                          to={'/testsets/view/' + testSetId + '/insights'}
                                          data-unique="btnTestSetStatsInsights">
                                          <ConfidenceGaugeChart id={`kfold_gauge_${testSetId}`} value={coachSessionProgress.kfoldF1} />
                                        </NavLink>
                                      }
                                    } else {
                                      return <><LoadingIndicator /> Waiting for Training Data Insights</>
                                    }
                                  }
                                  return null
                                }}
                              </TestSessionProgress>)
                            }
                            return null
                          }}
                    </Query>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridItem>}
      <GridItem xs={12}>
        <GridContainer>
          {this.hasReadPermission() &&
            <GridItem xs={12}>
              <Query query={TESTSET_STATS_QUERY} variables={{ id: testSetId }}>
                {({ loading, data = {}, refetch }) => {
                  if (loading || !data.testset || !data.testset.statsWarnings || data.testset.statsWarnings.length === 0) return null

                  const color = (data.testset.statsWarnings || []).some(w => w.severity === 'ERROR') ? 'danger' : (data.testset.statsWarnings || []).some(w => w.severity === 'WARNING') ? 'warning' : 'info'
                  const header = (data.testset.statsWarnings || []).some(w => w.severity === 'ERROR') ? 'Attention' : (data.testset.statsWarnings || []).some(w => w.severity === 'WARNING') ? 'Warning' : 'Info'
                  const aggregatedWarnings = []
                  for (const warning of (data.testset.statsWarnings || [])) {
                    const foundWarn = aggregatedWarnings.find((aggWarn) => {
                      if (warning.name === aggWarn.name) {
                        if ((warning.compiledConvo && aggWarn.compiledConvo && warning.compiledConvo.id === aggWarn.compiledConvo.id)
                          || (warning.compiledUtterance && aggWarn.compiledUtterance && warning.compiledUtterance.id === aggWarn.compiledUtterance.id)
                          || (warning.compiledScriptingMemory && aggWarn.compiledScriptingMemory && warning.compiledScriptingMemory.id === aggWarn.compiledScriptingMemory.id)) {
                          return true
                        }
                      }
                      return false
                    })
                    if (foundWarn) {
                      foundWarn.description.push(<Text key={warning.description}>{warning.description}</Text>)
                    } else {
                      const aggregatedWarning = _.cloneDeep(warning)
                      aggregatedWarning.description = [<Text key={aggregatedWarning.description}>{aggregatedWarning.description}</Text>]
                      aggregatedWarnings.push(aggregatedWarning)
                    }
                  }
                  return (<Card>
                    <CardHeader>
                      <Text header color={color}>{header}</Text>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} skipTableSpace>
                          <Table
                            name={`TestSet_${testSetId}_DashboardWarnings`}
                            pageLoading={loading}
                            tableHead={[{name: ' ', width: 'smallsecondary'}, {name: ' ', width: 'small'}, {name: ' ', width: 'large'}]}
                            tableData={aggregatedWarnings.map(w => [
                              () => {
                                if (w.severity === 'ERROR') return <Chip variant="error" label={w.name} />
                                else if (w.severity === 'WARNING') return <Chip variant="warning" label={w.name} />
                                else return <Chip variant="info" label={w.name} />
                              },
                              () => w.description,
                              (w.compiledConvo && getCompiledItemLink(testSetId, 'convo', w.compiledConvo, <Tooltip title={w.compiledConvo.name}><div className={classes.linkName}>{w.compiledConvo.name}</div></Tooltip>))
                                || (w.compiledUtterance && getCompiledItemLink(testSetId, 'utterance', w.compiledUtterance, <Tooltip title={w.compiledUtterance.name}><div className={classes.linkName}>{w.compiledUtterance.name}</div></Tooltip>))
                                || (w.compiledScriptingMemory && getCompiledItemLink(testSetId, 'scriptingMemory', w.compiledScriptingMemory, <Tooltip title={w.compiledScriptingMemory.name}><div className={classes.linkName}>{w.compiledScriptingMemory.name}</div></Tooltip>)),
                            ])}
                            onRefresh={() => refetch()}
                            truncateText={200}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>)
                }}
              </Query>
            </GridItem>
          }
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info">
                <CardHeaderActions>
                  {hasPermission(user, 'TESTSESSIONS_CREATE') && <Query query={TESTSET_TESTPROJECTS_QUERY} variables={{ testSetId }}>
                    {({ error, data }) => {
                      if (error) {
                        return <ErrorFormat err={error} />
                      }

                      let startedTestProjectId = null

                      const startableTestProjects = data && data.testprojects && data.testprojects.filter(p => canWriteNamespace(user, user.namespacePermissions, p.namespace))

                      if (startableTestProjects && startableTestProjects.length > 0) {
                        return (<Mutation
                          mutation={START_TESTPROJECT}
                          onCompleted={data => {
                            setAlertSuccessMessage('Test session started ...')
                            this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
                          }}
                          onError={error => {
                            setAlertErrorMessage('Test session failed', error)
                          }}
                          refetchQueries={[
                            ...(startedTestProjectId ? RefetchTestProjectQueriesOnNewTestSession(startedTestProjectId) : []),
                            ...RefetchTestSetQueriesOnNewTestSession(testSetId),
                            ...RefetchTestSessionQueries()
                          ]}
                          update={DeleteTestSessionListsFromCache}
                        >
                          {(startTestProject, { loading, error }) => (
                            <DropdownButton
                              primaryMini
                              data-unique="ddbtnTestSetStartTestSession"
                              items={startableTestProjects.map(t => ({
                                id: t.id,
                                name: `... ${t.name}`,
                                onClick: () => {
                                  startedTestProjectId = t.id
                                  startTestProject({ variables: { id: t.id, debug: false } })
                                }
                              }))
                              }
                            >
                              <ShowIcon icon="play-circle" />
                              Select and Start New Test Session
                            </DropdownButton>
                          )}
                        </Mutation>)
                      }
                      return null
                    }}
                  </Query>}
                </CardHeaderActions>
                <Text header>Recent Test Results</Text>
                <Text subheader>Recent Test Session Results of this Test Set</Text>
              </CardHeader>
              <CardBody noPadding className={classes.embeddedTable}>
                <TestSessionsEmbeddedTable key={`TestSessions_${this.state.newTestSessionCount}`} variables={{ testSets_some: { id: testSetId } }} disableOrderBy disableFooter skip={0} first={5} name={`TestSet_${testSetId}_DashboardTestSessions`} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
          <Card>
            <CardHeader color="info">
              <Text header>Test Projects</Text>
              <Text subheader>Available Test Projects for this Test Set</Text>
            </CardHeader>
            <CardBody noPadding>
              <GridContainer>
                <GridItem xs={12} className={classes.embeddedTableNamespace}>
                  <GeneralTestProjectsEmbeddedTable
                    projectType="all"
                    disableFooter
                    variables={{ botiumFormat: { testSetId } }}
                    name={`Testset_${testSetId}_TestProjects`}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
      </GridItem>
      {this.hasReadPermission() && <GridItem xs={12}>
        <Card>
          <CardHeader color="info">
            <Text header>Test Set Conversations</Text>
            <Text subheader>
              All convos found in this Test Set
            </Text>
          </CardHeader>
          <CardBody noPadding>
            <Query
              query={TESTSET_COMPILEDCONVOS_QUERY}
              variables={{ testSetId, ...getTablePaginationVariables(tblNameCompiledConvos) }}
              fetchPolicy={'network-only'}
              notifyOnNetworkStatusChange={true}
            >
              {({ loading, error, fetchMore, data = {} }) => {
                return (<Table
                  name={tblNameCompiledConvos}
                  tableHeaderColor="primary"
                  tableHead={['', '', 'Test Case Convo', 'Description', '', 'Source', 'Conversation Steps']}
                  pageLoading={loading}
                  pageErr={error}
                  disableHeader
                  tableData={
                    _.sortBy(data.testsetcompiledconvos, [r => r.name.toLowerCase()]).map(t => [
                      () => <>
                        {t.warnings && t.warnings.some(w => w.severity === 'ERROR') && <Chip tooltip={t.warnings.filter(w => w.severity === 'ERROR').map(w => `${w.name}: ${w.description}`).join(' | ')} variant="error" label={<Text danger>{t.warnings.filter(w => w.severity === 'ERROR').length} error(s)</Text>} />}
                        {t.warnings && t.warnings.some(w => w.severity === 'WARNING') && <Chip tooltip={t.warnings.filter(w => w.severity === 'WARNING').map(w => `${w.name}: ${w.description}`).join(' | ')} variant="warning" label={<Text warning>{t.warnings.filter(w => w.severity === 'WARNING').length} warning(s)</Text>} />}
                      </>,
                      () => t.partial ? <ShowIcon icon="comment" /> : <ShowIcon icon="comments" />,
                      getCompiledItemLink(testSetId, 'convo', t, t.name),
                      t.description,
                      getCompiledItemIconLink(testSetId, 'convo', t),
                      getCompiledItemLink(testSetId, 'convo', t),
                      getStepSamples(t.stepSamples, t.stepCount)
                    ])}
                  maxCount={data.testset ? (data.testset.statsConvoCount || 0) + (data.testset.statsPartialConvoCount || 0) : 0}
                  onPageChange={async (skip, first) => {
                    try {
                      await fetchMore({
                        variables: { testSetId, skip, first },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev
                          return fetchMoreResult
                        },
                      })
                    } catch (err) { console.warn('fetchMore failed', err) }
                  }}
                />)
              }}
            </Query>
          </CardBody>
        </Card>
      </GridItem>}
      {this.hasReadPermission() && <GridItem xs={12}>
        <Card>
          <CardHeader color="info">
            <Text header>Test Set Utterances</Text>
            <Text subheader>
              All utterances found in this Test Set
            </Text>
          </CardHeader>
          <CardBody noPadding>
            <Query
              query={TESTSET_COMPILEDUTTERANCES_QUERY}
              variables={{ testSetId, ...getTablePaginationVariables(tblNameCompiledUtterances) }}
              fetchPolicy={'network-only'}
              notifyOnNetworkStatusChange={true}
            >
              {({ loading, error, fetchMore, data = {} }) => {
                return (<Table
                  name={tblNameCompiledUtterances}
                  tableHeaderColor="primary"
                  tableHead={[{name: ' ', width: 'smallsecondary' }, 'Utterance Name', '', 'Source', 'User Examples', '']}
                  pageLoading={loading}
                  pageErr={error}
                  disableHeader
                  tableData={
                    _.sortBy((data.testsetcompiledutterances || []), [r => r.name.toLowerCase()]).map(t => [
                      () => <React.Fragment>
                        {t.warnings && t.warnings.some(w => w.severity === 'ERROR') && <Chip tooltip={t.warnings.filter(w => w.severity === 'ERROR').map(w => `${w.name}: ${w.description}`).join(' | ')} variant="error" label={`${t.warnings.filter(w => w.severity === 'ERROR').length} error(s)`} />}
                        {t.warnings && t.warnings.some(w => w.severity === 'WARNING') && <Chip tooltip={t.warnings.filter(w => w.severity === 'WARNING').map(w => `${w.name}: ${w.description}`).join(' | ')} variant="warning" label={`${t.warnings.filter(w => w.severity === 'WARNING').length} warning(s)`} />}
                      </React.Fragment>,
                      getCompiledItemLink(testSetId, 'utterance', t, t.name),
                      getCompiledItemIconLink(testSetId, 'convo', t),
                      getCompiledItemLink(testSetId, 'utterance', t),
                      `${t.uttCount}`,
                      getUttSamples(t.uttSamples, t.uttCount)
                    ])}
                  maxCount={data.testset ? data.testset.statsUtterancesRefCount || 0 : 0}
                  onPageChange={async (skip, first) => {
                    try {
                      await fetchMore({
                        variables: { testSetId, skip, first },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev
                          return fetchMoreResult
                        },
                      })
                    } catch (err) { console.warn('fetchMore failed', err) }
                  }}
                />)
              }}
            </Query>
          </CardBody>
        </Card>
      </GridItem>}
      {this.hasReadPermission() && <GridItem xs={12}>
        <Card>
          <CardHeader color="info">
            <Text header>Test Set Dependencies</Text>
            <Text subheader>
              All dependencies and references found to this Test Set
            </Text>
          </CardHeader>
          <CardBody noPadding>
            <Query
              query={TESTSET_QUERY}
              variables={{ id: testSetId }}
              fetchPolicy={'network-only'}
              notifyOnNetworkStatusChange={true}
            >
              {({ loading, error, fetchMore, data = {} }) => {
                if(loading) return (<LoadingIndicator large />)
                if(error) return (<ErrorFormat err={error} />)

                const tableData = [
                  ...(data.testset.dependencies.map(d => ({
                        id: d.id,
                        name: d.name,
                        dependOrRef: 'dependency'
                      }))),
                  ...(data.testset.depending.map(d => ({
                    id: d.id,
                    name: d.name,
                    dependOrRef: 'reference'
                  }))),
                  ]

                return (<Table
                  name={`${testSetId}_Dependencies`}
                  tableHeaderColor="primary"
                  tableHead={['Test Set Name', 'Dependency / Reference']}
                  pageLoading={loading}
                  pageErr={error}
                  disableHeader
                  disableFooter
                  tableData={
                    tableData.map(t => [
                      () => <NavLink to={`/testsets/view/${t.id}/dashboard`} data-unique={`btnTestSetDependOrRef_${t.id}`}>
                        {t.name}
                      </NavLink>,
                      t.dependOrRef
                    ])}
                />)
              }}
            </Query>
          </CardBody>
        </Card>
      </GridItem>}
    </GridContainer>)
  }

  renderTree(testSetId, testSetName) {
    const { history } = this.props

    return (
      <Query query={TESTSET_TREE_QUERY}
        variables={{ id: testSetId }}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <LoadingIndicator />
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          const tree = data.testsettree && JSON.parse(data.testsettree)

          return (<GridContainer>
            <GridItem xs={12}>
              {tree && tree.err && <ErrorFormat err={tree.err} />}
              {tree && !tree.err && <ConvosTree autoHeight fileName={`${testSetName} - flowchart.png`} key={`TestSetTree_${testSetId}`} name={`TestSetTree_${testSetId}`} tree={tree} refetch={refetch} onNodeClick={(convo) => history.push(getCompiledItemHref(testSetId, 'convo', Object.assign({ name: convo.header.name }, convo.sourceTag)))} />}
            </GridItem>
          </GridContainer>)
        }}
      </Query>
    )
  }

  render() {
    const { testset, user } = this.props
    return (
      <GridContainer>
        <GridItem xs={12}>
          {this.hasReadPermission() && <>
            {this.renderUtteranceDeleteDialog(testset.id)}
            {this.renderUtteranceCloneDialog(testset.id)}
            {this.renderUtteranceCopyDialog(testset.id)}
            {this.renderUtteranceMoveDialog(testset.id)}
            {this.renderConvoDeleteDialog(testset.id)}
            {this.renderConvoCloneDialog(testset.id)}
            {this.renderConvoCopyDialog(testset.id)}
            {this.renderConvoMoveDialog(testset.id)}
            {this.renderScriptBulkDeleteDialog(testset.id)}
            {this.renderScriptBulkCloneDialog(testset.id)}
            {this.renderScriptBulkCopyDialog(testset.id)}
            {this.renderScriptBulkMoveDialog(testset.id)}
            {this.renderImportFromZipDialog(testset.id)}
            {this.renderScriptDeleteDialog(testset.id)}
            {this.renderScriptCopyDialog(testset.id)}
            {this.renderScriptMoveDialog(testset.id)}
            {this.renderExcelDeleteDialog(testset.id)}
            {this.renderExcelCopyDialog(testset.id)}
            {this.renderExcelMoveDialog(testset.id)}
            {/* this.renderExportToGitDialog(testset.id) */}
            {this.renderExportToFolderDialog(testset.id)}
          </>}
          <CustomTabs
            headerColor="info"
            name={`tabTestSet_${testset.id}`}
            tabs={[
              {
                tabName: 'Overview',
                tabIcon: <ShowIcon icon="folder-open" />,
                tabContent: this.renderStats(testset.id),
                locationPrefix: `/testsets/view/${testset.id}/dashboard`,
                dataUnique: 'tabTestSetDashboard'
              },
              this.hasReadPermission() && {
                tabName: 'Insights',
                tabIcon: <ShowIcon icon="chart-pie" />,
                tabContent: <TestSetInsights testSetId={testset.id} />,
                locationPrefix: `/testsets/view/${testset.id}/insights`,
                dataUnique: 'tabTestSetInsights'
              },
              this.hasReadPermission() && {
                tabName: 'Test Cases',
                tabIcon: <ShowIcon icon="list-ul" />,
                tabContent: this.renderTestCasesTab(testset.id, testset.name),
                locationPrefix: `/testsets/view/${testset.id}/testcases`,
                dataUnique: 'tabTestSetTestCases'
              },
              this.hasReadPermission() && testset.mediaBaseDir && {
                tabName: 'File Browser',
                tabIcon: <ShowIcon icon="folder-open" />,
                tabContent: <FileBrowser currentPath={testset.mediaBaseDir} basePath={testset.mediaBaseDir} />,
                locationPrefix: `/testsets/view/${testset.id}/filebrowser`,
                dataUnique: 'tabTestSetFileBrowser'
              },
              this.hasReadPermission() && hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE', 'TESTSETS_DELETE']) && {
                tabName: 'Configuration',
                tabRight: true,
                tabIcon: <ShowIcon icon="wrench" />,
                tabContent: this.renderForm(testset),
                locationPrefix: `/testsets/view/${testset.id}/settings`,
                dataUnique: 'tabTestSetSettings'
              }
            ].filter(t => t)}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

const TestSetComponent = withRouter(compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, namespace: state.namespace, features: state.settings.features, settings: state.settings, testcasefilter: state.testcasefilter }),
    { getTablePaginationVariables, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry, setTestCaseFilter },
  ),
  graphql(TESTSETSOURCES_SCRIPTS_QUERY, {
    skip: (props) => !props.match.params || !props.match.params.id || !props.hasReadPermissions,
    options: (props) => ({
      variables: {
        testSetId: props.match.params.id
      },
    }),
    props: ({ data }) => ({
      testsetscriptsData: data,
    }),
  }),
  graphql(TESTSETSOURCES_EXCELS_QUERY, {
    skip: (props) => !props.match.params || !props.match.params.id || !props.license.excel || !props.hasReadPermissions,
    options: (props) => ({
      variables: {
        testSetId: props.match.params.id
      },
    }),
    props: ({ data }) => ({
      testsetexcelsData: data,
    }),
  }),
  graphql(TESTSETSOURCES_REMOTE_QUERY, {
    skip: (props) => !props.match.params || !props.match.params.id || !props.license.gitIntegration || !props.hasReadPermissions,
    options: (props) => ({
      variables: {
        testSetId: props.match.params.id
      },
    }),
    props: ({ data }) => ({
      testsetremoteData: data,
    }),
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data,
    }),
  })
)(TestSet))

export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.id && (
          <Query query={TESTSET_QUERY} variables={{ id: match.params.id }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testset" renderLoading={() => <RenderSkeletonProjectMenu />}>{(data) =>
              <TestSetComponent
                match={match}
                testset={data.testset}
                hasReadPermissions={hasPermission(user, 'TESTSETS_SELECT') && canReadNamespace(user, user.namespacePermissions, data.testset.namespace)}
                hasWritePermissions={hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, data.testset.namespace)}
                {...rest} />
            }</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})



