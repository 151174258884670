const _ = require('lodash')

const permissionTypes = {
  AGENTS_SELECT: 'AGENTS_SELECT',
  AGENTS_CREATE: 'AGENTS_CREATE',
  AGENTS_UPDATE: 'AGENTS_UPDATE',
  AGENTS_DELETE: 'AGENTS_DELETE',
  CHATBOTS_SELECT: 'CHATBOTS_SELECT',
  CHATBOTS_CREATE: 'CHATBOTS_CREATE',
  CHATBOTS_UPDATE: 'CHATBOTS_UPDATE',
  CHATBOTS_DELETE: 'CHATBOTS_DELETE',
  CHATBOTS_LIVECHAT: 'CHATBOTS_LIVECHAT',
  DEVICESETS_SELECT: 'DEVICESETS_SELECT',
  DEVICESETS_CREATE: 'DEVICESETS_CREATE',
  DEVICESETS_UPDATE: 'DEVICESETS_UPDATE',
  DEVICESETS_DELETE: 'DEVICESETS_DELETE',
  TESTPROJECTS_SELECT: 'TESTPROJECTS_SELECT',
  TESTPROJECTS_CREATE: 'TESTPROJECTS_CREATE',
  TESTPROJECTS_UPDATE: 'TESTPROJECTS_UPDATE',
  TESTPROJECTS_DELETE: 'TESTPROJECTS_DELETE',
  CRAWLERPROJECTS_SELECT: 'CRAWLERPROJECTS_SELECT',
  CRAWLERPROJECTS_CREATE: 'CRAWLERPROJECTS_CREATE',
  CRAWLERPROJECTS_UPDATE: 'CRAWLERPROJECTS_UPDATE',
  CRAWLERPROJECTS_DELETE: 'CRAWLERPROJECTS_DELETE',
  TESTSETS_SELECT: 'TESTSETS_SELECT',
  TESTSETS_CREATE: 'TESTSETS_CREATE',
  TESTSETS_UPDATE: 'TESTSETS_UPDATE',
  TESTSETS_DELETE: 'TESTSETS_DELETE',
  TESTSETS_UPLOAD: 'TESTSETS_UPLOAD',
  TESTSETS_DOWNLOAD: 'TESTSETS_DOWNLOAD',
  TESTSESSIONS_SELECT: 'TESTSESSIONS_SELECT',
  TESTSESSIONS_CREATE: 'TESTSESSIONS_CREATE',
  TESTSESSIONS_DELETE: 'TESTSESSIONS_DELETE',
  TESTSESSIONS_REPORTS: 'TESTSESSIONS_REPORTS',
  PERFORMANCETESTSESSIONS_SELECT: 'PERFORMANCETESTSESSIONS_SELECT',
  PERFORMANCETESTSESSIONS_CREATE: 'PERFORMANCETESTSESSIONS_CREATE',
  PERFORMANCETESTSESSIONS_DELETE: 'PERFORMANCETESTSESSIONS_DELETE',
  PERFORMANCETESTSESSIONS_REPORTS: 'PERFORMANCETESTSESSIONS_REPORTS',
  CRAWLERSESSIONS_SELECT: 'CRAWLERSESSIONS_SELECT',
  CRAWLERSESSIONS_CREATE: 'CRAWLERSESSIONS_CREATE',
  CRAWLERSESSIONS_DELETE: 'CRAWLERSESSIONS_DELETE',
  CRAWLERSESSIONS_REPORTS: 'CRAWLERSESSIONS_REPORTS',
  TRAINERSESSIONS_SELECT: 'TRAINERSESSIONS_SELECT',
  APIKEYS_SELECT: 'APIKEYS_SELECT',
  APIKEYS_MANAGE: 'APIKEYS_MANAGE',
  DEVICEPROVIDERS_SELECT: 'DEVICEPROVIDERS_SELECT',
  DEVICEPROVIDERS_MANAGE: 'DEVICEPROVIDERS_MANAGE',
  REGISTEREDCOMPONENTS_SELECT: 'REGISTEREDCOMPONENTS_SELECT',
  REGISTEREDCOMPONENTS_MANAGE: 'REGISTEREDCOMPONENTS_MANAGE',
  REPORTS: 'REPORTS',
  USERS_MANAGE: 'USERS_MANAGE',
  SYSTEMSETTINGS_MANAGE: 'SYSTEMSETTINGS_MANAGE',
  IMPORT_PRISMA: 'IMPORT_PRISMA',
  FILESYSTEM_READ: 'FILESYSTEM_READ',
  FILESYSTEM_WRITE: 'FILESYSTEM_WRITE'
}

const hasPermission = (user, checkPermission) => {
  if (!user) return false
  if (!user.roles && !user.permissions) return false

  if (user.name !== 'admin' && user.name !== 'support' && process && process.env && process.env.BOTIUMBOX_DISABLE_PERMISSIONS) {
    if (process.env.BOTIUMBOX_DISABLE_PERMISSIONS.indexOf(checkPermission) >= 0) return false
  }

  const permissions = []
  if (user.roles) {
    for (const role of user.roles) {
      if (!role.permissions) continue
      role.permissions.forEach(p => p.value ? permissions.push(p.value) : permissions.push(p))
    }
  }
  if (user.permissions) {
    user.permissions.forEach(p => p.value ? permissions.push(p.value) : permissions.push(p))
  }

  for (const permission of permissions) {
    if (permission === '*') {
      return true
    } else if (permission.endsWith('*')) {
      if (checkPermission.startsWith(permission.substr(0, permission.length - 1))) {
        return true
      }
    } else if (permission.startsWith('*')) {
      if (checkPermission.endsWith(permission.substr(1))) {
        return true
      }
    } else if (permission === checkPermission) {
      return true
    }
  }
  return false
}

const hasAnyPermission = (user, checkPermissions) => {
  for (const checkPermission of checkPermissions) {
    if (hasPermission(user, checkPermission)) {
      return true
    }
  }
  return false
}

const hasAllPermissions = (user, checkPermissions) => {
  for (const checkPermission of checkPermissions) {
    if (!hasPermission(user, checkPermission)) {
      return false
    }
  }
  return true
}

const canReadNamespace = (user, namespacePermissions, namespace) => {
  if (!namespace || namespace.length === 0) return true
  if (!user) return false
  if (user.isApiKey || user.name === 'support' || user.name === 'admin') return true
  if ((user.roles || []).find(r => r.name === 'ADMIN')) return true
  if (namespacePermissions && namespacePermissions.find(n => n.namespace === '*' && (n.canRead || n.canWrite))) return true

  const matchingNs = (namespacePermissions || []).filter(ns => (namespace === ns.namespace || namespace.startsWith(ns.namespace + '.')))
  if (matchingNs.length > 0) {
    const longestMatchingNs = _.orderBy(matchingNs, ns => ns.namespace.length, ['desc'])[0]
    return longestMatchingNs.canRead || longestMatchingNs.canWrite
  }
  return false
}

const canWriteNamespace = (user, namespacePermissions, namespace) => {
  if (!namespace || namespace.length === 0) return true
  if (!user) return false
  if (user.isApiKey || user.name === 'support' || user.name === 'admin') return true
  if ((user.roles || []).find(r => r.name === 'ADMIN')) return true
  if (namespacePermissions && namespacePermissions.find(n => n.namespace === '*' && n.canWrite)) return true

  const matchingNs = (namespacePermissions || []).filter(ns => (namespace === ns.namespace || namespace.startsWith(ns.namespace + '.')))
  if (matchingNs.length > 0) {
    const longestMatchingNs = _.orderBy(matchingNs, ns => ns.namespace.length, ['desc'])[0]
    return longestMatchingNs.canWrite
  }
  return false
}

const canReadBotiumFolder = (user, dirPathArr) => {
  if (!dirPathArr || dirPathArr.length === 0) return false
  if (hasPermission(user, 'FILESYSTEM_READ')) return true
  if (hasPermission(user, 'TESTSETS_SELECT') && dirPathArr[0] === 'testsets') return true
  return false
}

const canWriteBotiumFolder = (user, dirPathArr) => {
  if (!dirPathArr || dirPathArr.length === 0) return false
  if (dirPathArr[0] === 'resources' && dirPathArr[1] === 'scripts' && user.name !== 'support') return false
  if (hasPermission(user, 'FILESYSTEM_WRITE')) return true
  if (hasAnyPermission(user, ['TESTSETS_SELECT', 'TESTSETS_CREATE', 'TESTSETS_UPDATE']) && dirPathArr[0] === 'testsets') return true
  return false
}

module.exports = {
  permissionTypes,
  hasPermission,
  hasAnyPermission,
  hasAllPermissions,
  canReadNamespace,
  canWriteNamespace,
  canReadBotiumFolder,
  canWriteBotiumFolder
}
