import React from 'react'
import { NavLink } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'

import Tooltip from 'components/Tooltip/Tooltip'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Text from 'components/Typography/Text.jsx'

import TestSessionProgress from './TestSessionProgress.jsx'

class FactCheckerSessionProgressIcon extends React.Component {

  render() {
    const { testSession, variables, withoutNavLink, classes } = this.props

    return (
      <TestSessionProgress {...(this.props)} key={`fcicon_${testSession && testSession.id}_${variables && JSON.stringify(variables)}`}>
        {({ testSessionProgress }) => {
          let dataStatus = 'RUNNING'
          let resultIcon = <Text warningBg bgradius iconRotation icon="circle-notch">RUNNING</Text>

          if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            dataStatus = 'CANCELLED'
            resultIcon = <Text dangerBg bgradius data-status={testSessionProgress.status} icon="ban">CANCELLED</Text>
          } else if (testSessionProgress && testSessionProgress.status === 'FAILED') {
            dataStatus = 'FAILED'
            resultIcon = <Text dangerBg bgradius marginRight displayInline icon="triangle-exclamation">FAILED</Text>
          } else if (testSessionProgress && testSessionProgress.status === 'READY') {
            const dangerCount = (testSessionProgress.factCheckerSession && (testSessionProgress.factCheckerSession.failedCount + testSessionProgress.factCheckerSession.incorrectCount)) || 0
            if (dangerCount > 0) {
              dataStatus = 'FAILED'
              resultIcon = <Tooltip title={`Incorrect ${dangerCount > 1 ? 'statements' : 'statement'}`}>
                <Text dangerBg bgradius icon="triangle-exclamation">FAILED</Text>
              </Tooltip>
            } else {
              dataStatus = 'READY'
              resultIcon =  ''
            }
          }

          if(withoutNavLink) {
            return <div className={classes.resultIconPositionNavLink} data-unique={`btnFactcheckerSessionProgressIcon_${testSessionProgress.id}`} data-status={dataStatus}>
              {resultIcon}
            </div>
          } else {
            return <NavLink tabIndex={-1} to={`/testsessions/fcview/${testSessionProgress.id}`} data-unique={`btnFactcheckerSessionProgressIcon_${testSessionProgress.id}`} data-status={dataStatus}>
              {resultIcon}
            </NavLink>
          }
        }}
      </TestSessionProgress>
    )
  }
}

export default withStyles(testsessionsStyle)(FactCheckerSessionProgressIcon)
