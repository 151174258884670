
const skeletonStyle = theme => ({
// skeleton
  skeleton: {
    opacity: `${theme.colors.skeletonOpacity}`,
    animation: 'skeleton-loading 1s infinite alternate',
  },
  '@keyframes skeleton-loading': {
    '0%': {
      backgroundColor: 'hsl(200, 20%, 70%)',
    },
    '100%': {
      backgroundColor: 'hsl(200, 20%, 95%)',
    }
  },
  skeletonPurple: {
    opacity: `${theme.colors.skeletonOpacity}`,
    animation: 'skeletonPurple-loading 1s infinite alternate',
  },
  '@keyframes skeletonPurple-loading': {
    '0%': {
      backgroundColor: 'hsl(251, 81%, 70%)',
    },
    '100%': {
      backgroundColor: 'hsl(251, 81%, 95%)',
    }
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  skeletonContainerLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  skeletonContainerBox: {
    height: 70, 
    width: '100%',
  },
  skeletonContainerBoxLow: {
    height: 30, 
    width: '100%',
  },
  // skeletonTable
  skeletonContainerFullwidth: {
    justifyContent: 'space-between',
    width: '100%',
  },
  skeletonTableHeader: {
    width: 100,
    height: 11,
    borderRadius: 6,
    display: 'inline-block',
  },
  skeletonMarginRightBig: {
    marginRight: '10rem',
  },
  skeletonMarginRightSmall: {
    marginRight: '3rem',
  },
  skeletonTableFilter: {
    marginTop: 25,
    marginRight: 10,
    width: 45,
    height: 45,
    borderRadius: 6,
    display: 'inline-block',
    marginBottom: 40,
  },
  skeletonTabelRowHigh: {
    width: '100%',
    height: '5rem',
    marginBottom: 17,
    borderRadius: 6,
  },
  skeletonTabelRowLow: {
    width: '100%',
    height: '2rem',
    marginBottom: 17,
    borderRadius: 6,
  },
  skeletonCheck: {
    width: 20,
    height: 20,
    borderRadius: 6,
    display: 'inline-block',
    margin: '0 150px 0 20px',
  },

    // skeletonNews
    skeletonParentNews: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '20px',
    },
    '@media (max-width: 1200px)': {
        skeletonParentNews: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        }
    },
    skeletonNews: {
        
    },
    skeletonNewsImage: {
        width: '100%',
        height: 232,
        borderRadius: 6,
        marginBottom: 15,
    },
    skeletonNewsTitle: {
        width: '100%',
        '& div': {
            height: 20,
        },
        '& div:last-child': {
            width: '25%',
        }
    },
    skeletonNewsContent: {
        width: '100%',
    },
    skeletonNewsText: {
        width: '100%',
        height: 12,
        borderRadius: 6,
        marginBottom: 10,
        '&:last-child': {
            width: '80%',
        },
    },
    skeletonNewsSmallbutton: {
        width: 100,
        height: 12,
        borderRadius: 6,
    },
    // skeleton charts
    skeletonRightDropdown: {
        marginTop: 25,
        marginRight: 10,
        width: 140,
        height: 45,
        borderRadius: 6,
        display: 'inline-block',
        marginBottom: 40,
        float: 'right',
    },
    skeletonLeftDropdown: {
        marginTop: 25,
        marginRight: 10,
        width: 440,
        height: 45,
        borderRadius: 6,
        display: 'inline-block',
        marginBottom: 40,
        float: 'left',
    },
    skeletonChartLine: {
        width: '100%',
        height: 2,
        borderRadius: 6,
        marginBottom: 40,
    },
    skeletonChartStackedLine: {
        width: '100%',
        height: 2,
        borderRadius: 6,
    },
    skeletonDotBar: {
        width: 20,
        height: 20,
        borderRadius: 50,
    },
    skeletonBar: {
        width: 40,
        height: 188,
        borderRadius: 0,
    },
    skeletonBarLine: {
        width: 40,
        height: 66,
        borderRadius: 0,
    },
    skeletonStackedBar: {
        width: 40,
        height: 90,
        borderRadius: 0,
    },
    skeletonOpacity: {
        opacity: 0.4,
    },
    barAlignItems: {
        display: 'flex',               
        justifyContent: 'center',       
        alignItems: 'center',           
        gap: '20%',                    
        width: '100%',                   
    },
    paddingTopBottom: {
        paddingTop: 120,
        paddingBottom: 40,
    },
    skeletonLine: {
        opacity: 0.6,
        stroke: 'hsl(200, 20%, 50%)',
        animation: 'skeleton-loading2 1s infinite alternate',
    },
    // skeleton header title
    skeletonHeaderTitle: {
        width: '30%',
        height: 24,
        borderRadius: 6,
        marginBottom: 10,
    },
    skeletonTesttype: {
        width: '3%',
        height: 24,
        borderRadius: 6,
    },
    // skeleton top menu
    skeletonTopMenu: {
        width: '100%',
        height: 56,
        borderRadius: 6,
        marginTop: 5,
    },
    // skeleton NLP
    skeletonChartBox: {
        width: '100%',
        height: 400,
        borderRadius: 6,
        marginBottom: 20,
    },
    skeletonNLPSuggestionLow: {
        width: '100%',
        height: 125,
        borderRadius: 6,
        marginBottom: 20,
    },
    skeletonNLPSuggestionHigh: {
        width: '100%',
        height: 250,
        borderRadius: 6,
        marginBottom: 20,
    },
    skeletonTabelBoxHigh: {
        width: '100%',
        height: 300,
        borderRadius: 6,
        marginBottom: 20,
    },
    skeletonFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: '20px',
    },
    marginTop: {
        marginTop: 30,
    },
    marginTopsm: {
      marginTop: 10,
  },
    skeletonTopMenuRight: {
        width: '8%',
        height: 10,
        borderRadius: 6,
        marginBottom: 25,
        marginLeft: 18,
        float: 'right',
        '&:last-child': {
          width: '5%',
          float: 'left',
          marginLeft: 0,
        }
    },
    skeletonSwitchButton: {
        width: 250,
        height: 30,
        borderRadius: 6,
        marginBottom: 25,
        float: 'right',
    },
    skeletonConvoName: {
      width: '15%',
      float: 'left',
      marginTop: 10,
      height: 10,
    },
    skeletonTextShort: {
      width: '10%',
      marginTop: 10,
      height: 10,
      borderRadius: 6,
    },
    skeletonTextLong: {
      width: '30%',
      marginTop: 20,
      borderRadius: 6,
      height: 10,
    },
    skeletonChipShort: {
      width: '15%',
      marginTop: 10,
      marginRight: 10,
      height: 29,
      borderRadius: 20,
      float: 'left',
    },
    skeletonChipLong: {
      width: '20%',
      marginTop: 10,
      marginRight: 10,
      borderRadius: 20,
      height: 29,
      float: 'left',
    },

      '@keyframes skeleton-loading2': {
        '0%': {
            stroke: 'hsl(200, 20%, 70%)',
        },
        '100%': {
            stroke: 'hsl(200, 20%, 95%)',
        }
      }
})

export default skeletonStyle
