const logicHookSettings = {
  PAUSE: { name: 'Pause', sender: ['begin', 'me', 'bot', 'end'] },
  WAITFORBOT: { name: 'Wait for Bot', sender: ['bot'] },
  SET_SCRIPTING_MEMORY: { name: 'Set Scripting Variable', sender: ['begin', 'me', 'bot'] },
  CLEAR_SCRIPTING_MEMORY: { name: 'Clear Scripting Variable', sender: ['begin', 'me', 'bot'] },
  ASSIGN_SCRIPTING_MEMORY: { name: 'Assign Scripting Variable', sender: ['bot'] },
  INCLUDE: { name: 'Partial Convo', sender: ['begin', 'me', 'bot', 'end'] },
  SKIP_BOT_UNCONSUMED: { name: 'Skip Unconsumed Bot Replies', sender: ['begin', 'bot', 'end'] },
  VOIP_IGNORE_SILENCE_DURATION: { name: 'VOIP - Ignore Silence Duration', sender: ['bot'] },
  VOIP_JOIN_SILENCE_DURATION: { name: 'VOIP - JOIN by Silence Duration', sender: ['bot'] },
  VOIP_CONFIDENCE_THRESHOLD: { name: 'VOIP - Confidence Threshold', sender: ['bot'] },
  CONDITIONAL_STEP_TIME_BASED: { name: 'Conditional Time Based', sender: ['bot'], jsonExample: { start: '8:00', end: '16:30', timeZone: 'Europe/Vienna' } },
  CONDITIONAL_STEP_BUSINESS_HOURS: { name: 'Conditional Business Hours', sender: ['bot'], jsonExample: { days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'], start: '8:00', end: '16:30', timeZone: 'Europe/Vienna' } },
  CONDITIONAL_STEP_CAPABILITY_VALUE_BASED: { name: 'Conditional Capability Value Based', sender: ['bot'], jsonExample: { capabilityName: 'CAP_NAME', value: 'value', jsonPath: '$.optionally.define.jsonpath' } },
  CONDITIONAL_STEP_JSON_PATH_BASED: { name: 'Conditional JSON Path Based', sender: ['bot'], jsonExample: { jsonPath: '$.jsonpath.on.botMsg', value: 'value' } },
  SETFROMHTTPGET: { name: 'Inject HTTP GET Response', sender: ['me', 'bot'] },
  SETFROMHTTPPOST: { name: 'Inject HTTP POST Response', sender: ['me', 'bot'] },
  SETPARAMETERSTOREFROMHTTPGET: { name: 'Inject HTTP GET Response into Parameter Store', sender: ['begin', 'me', 'bot', 'end'] },
  SETPARAMETERSTOREFROMHTTPPOST: { name: 'Inject HTTP POST Response into Parameter Store', sender: ['begin', 'me', 'bot', 'end'] },
  HTTPGET: { name: 'Make HTTP GET Request', sender: ['begin', 'me', 'bot', 'end'] },
  HTTPPOST: { name: 'Make HTTP POST Request', sender: ['begin', 'me', 'bot', 'end'] },
  ORDERED_LIST_TO_BUTTON: { name: 'Extract Number as Button from Ordered List', sender: ['bot'] }
}

const userInputSettings = {
  MEDIA: { name: 'Media/Voice', argsLabel: 'Pathes/Urls to media files' },
  BUTTON: { name: 'Button/Quick-Response' },
  FORM: { name: 'Form Entry' }
}

const asserterSettings = {
  MEDIA: { name: 'Media Attachment', argsLabel: 'Expected media file urls' },
  MEDIA_COUNT: { name: 'Media Attachment Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  MEDIA_COUNT_REC: { name: 'Media Attachment Nested Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  BUTTONS: { name: 'Buttons/Quick-Response', argsLabel: 'Expected button labels or payloads' },
  BUTTONS_COUNT: { name: 'Buttons/Quick-Response Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  BUTTONS_COUNT_REC: { name: 'Buttons/Quick-Response Nested Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  CARDS: { name: 'Cards/Carousel', argsLabel: 'Expected card names or content' },
  CARDS_COUNT: { name: 'Cards/Carousel Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  CARDS_COUNT_REC: { name: 'Cards/Carousel Nested Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' },
  INTENT: { name: 'NLP Intent' },
  INTENT_CONFIDENCE: { name: 'NLP Intent Confidence' },
  ENTITIES: { name: 'NLP Entity', argsLabel: 'Expected NLP entity name' },
  ENTITY_VALUES: { name: 'NLP Entity Values', argsLabel: 'Expected NLP entity values' },
  ENTITY_CONTENT: { name: 'NLP Entity Content' },
  JSON_PATH: { name: 'Generic JSONPath' },
  JSON_PATH_COUNT: { name: 'Generic JSONPath Count', argsLabel: 'JSON-Path and expected count (f.e. =1, >2, <=3)' },
  FORMS: { name: 'Form Fields', argsLabel: 'Expected form field names' },
  RESPONSE_LENGTH: { name: 'Response Length' },
  TEXT_CONTAINS_ANY: { name: 'Text (is any matching)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT_CONTAINS_ANY_IC: { name: 'Text (is any matching - case ignored)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT_CONTAINS_ALL: { name: 'Text (are all matching)', argsLabel: 'All of the texts expected to match', translateArgs: true },
  TEXT_CONTAINS_ALL_IC: { name: 'Text (are all matching - case ignored)', argsLabel: 'All of the texts expected to match', translateArgs: true },
  TEXT_WILDCARD_ANY: { name: 'Text (is any wildcard matching)', argsLabel: 'One of the texts expected to match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARD_ANY_IC: { name: 'Text (is any wildcard matching - case ignored)', argsLabel: 'One of the texts expected to match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARD_ALL: { name: 'Text (are all wildcard matching)', argsLabel: 'All of the texts expected to match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARD_ALL_IC: { name: 'Text (are all wildcard matching - case ignored)', argsLabel: 'All of the texts expected to match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARDEXACT_ANY: { name: 'Text (is any wildcard exactly matching)', argsLabel: 'One of the texts expected to exactly match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARDEXACT_ANY_IC: { name: 'Text (is any wildcard exactly matching - case ignored)', argsLabel: 'One of the texts expected to exactly match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARDEXACT_ALL: { name: 'Text (are all wildcard exactly matching)', argsLabel: 'All of the texts expected to exactly match (* as wildcard)', translateArgs: true },
  TEXT_WILDCARDEXACT_ALL_IC: { name: 'Text (are all wildcard exactly matching - case ignored)', argsLabel: 'All of the texts expected to exactly match (* as wildcard)', translateArgs: true },
  TEXT_REGEXP_ANY: { name: 'Text (is any regexp matching)', argsLabel: 'One of the regexps expected to match', translateArgs: true },
  TEXT_REGEXP_ANY_IC: { name: 'Text (is any regexp matching - case ignored)', argsLabel: 'One of the regexps expected to match', translateArgs: true },
  TEXT_REGEXP_ALL: { name: 'Text (are all regexp matching)', argsLabel: 'All of the regexps expected to match', translateArgs: true },
  TEXT_REGEXP_ALL_IC: { name: 'Text (are all regexp matching - case ignored)', argsLabel: 'All of the regexps expected to match', translateArgs: true },
  TEXT_EQUALS: { name: 'Text (is any exact matching)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT_EQUALS_IC: { name: 'Text (is any exact matching - case ignored)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT: { name: 'Text (is any exact matching)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT_IC: { name: 'Text (is any exact matching - case ignored)', argsLabel: 'One of the texts expected to match', translateArgs: true },
  TEXT_WER: { name: 'Word Error Rate', argsLabel: 'Word Error Rate (0 = no errors, 1 = 100% mismatch)' },
  BOT_CONSUMED: { name: 'Bot Reply Consumed' },
  BOT_UNCONSUMED_COUNT: { name: 'Unconsumed Bot Reply Count', argsLabel: 'Expected count (f.e. =1, >2, <=3)' }
}

module.exports = {
  logicHookSettings,
  userInputSettings,
  asserterSettings
}
