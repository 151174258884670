import React from 'react'
import { connect } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { withRouter } from 'react-router-dom'
import { withApollo, compose, graphql } from 'react-apollo'

import _ from 'lodash'
import unescapeJs from 'unescape-js'

import TestSetMatchingModeTypeToCore from 'botium-box-shared/utils/TestSetMatchingModeType'
import TestSetMatchingModeTypeFromCore from 'botium-box-shared/utils/TestSetMatchingModeTypeCore'
import { userInputSettings, asserterSettings, logicHookSettings} from 'botium-box-shared/utils/components'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import SettingsIcon from '@material-ui/icons/Settings'
import AddIcon from '@material-ui/icons/Add'

// core components
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from 'components/Button/Button'
import Field from 'components/Form/OptionalField'
import DropdownButton from 'components/Button/DropdownButton'
import LinkButton from 'components/Button/LinkButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import MediaSelectionDialog from 'components/Dialog/MediaSelectionDialog.jsx'
import {
  renderTextField,
  renderCheckbox,
  renderAutoSuggest,
  renderIntField,
  renderSlider,
  required,
  parseInteger,
  jsonpath,
  composeValidators,
  CustomSelect,
  CustomTextArea,
  maxValue,
  minValue,
  CustomCodeArea,
  json,
  url,
  renderCodeArea,
  oneLinePrintJson,
  renderSelect, Condition, renderTextArea, mustBeNumber, prettyPrintJson
} from 'components/Form/Form'
import ComponentChip, { renderComponentAvatar } from 'components/Convo/ComponentChip.jsx'
import ConvoEditor, { MATCHING_MODE_DEFAULT } from 'components/Convo/ConvoEditor.jsx'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'
import { CustomTextField } from 'components/Form/Form'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import Divider from 'components/Divider/Divider'
import { CapabilityDropdownData } from 'components/Capability/CapabilitiesEdit'
import FileSelectorField from 'components/Form/FileSelectorField'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'

import {
  TESTSET_QUERY,
  TESTSET_VALIDUTTERANCES_QUERY,
  TESTSET_VALIDPARTIALCONVOS_QUERY,
  TESTSET_EDITABLE_CONVO_QUERY
} from './gql'
import {REGISTEREDCOMPONENTS_QUERY, SYSTEMSETTINGS_CLIENT_INFO_QUERY} from '../Settings/gql'
import { MATCHING_MODES } from './helper'

const asserterSections = {
  MEDIA: 'Media Asserters',
  BUTTONS: 'Button Asserters',
  CARDS: 'Card Asserters',
  INTENT: 'Intent Asserters',
  ENTIT: 'Entity Asserters',
  JSON: 'JSON Asserters',
  FORMS: 'Form Asserters',
  RESPONSE_LENGTH: 'Length Asserters',
  TEXT: 'Text Asserters',
  BOT: 'Reply Asserters'
}

const LOGICHOOK_FOR_CONVO_STEP_PARAMETERS = 'CONVO_STEP_PARAMETERS'
const CONVO_STEP_PARAMETERS_RETRY_TIMEOUT_DEFAULT = 10000

const filterAsserterBySection = (as, asserters) => {
  const found = Object.keys(asserterSettings).filter(a => a.startsWith(as))
  return _.intersection(asserters, found)
}

const getOtherAsserters = (asserters) => {
  let foundAsserters = []
  Object.keys(asserterSections).forEach(section => {
    foundAsserters = [...foundAsserters, ...filterAsserterBySection(section, asserters)]
  })
  return _.difference(asserters, foundAsserters)
}

const ASSERTER_WITH_CUSTOM_EDITOR = ['CHECKSMS', 'CHECKINBOX', 'RESPONSE_LENGTH', 'JSON_PATH', 'ENTITY_CONTENT', 'INTENT_CONFIDENCE', 'INTENT', 'TEXT_WER', 'HTTP', 'MISUSE', 'OBJECTIVE', 'LANGUAGE']
const LOGICHOOK_WITH_CUSTOM_EDITOR = ['PAUSE', 'WAITFORBOT', 'SET_SCRIPTING_MEMORY', 'CLEAR_SCRIPTING_MEMORY', 'ASSIGN_SCRIPTING_MEMORY', 'INCLUDE', 'SETFROMHTTPGET', 'SETFROMHTTPPOST', 'SETPARAMETERSTOREFROMHTTPGET', 'SETPARAMETERSTOREFROMHTTPPOST', 'HTTPGET', 'HTTPPOST', 'VOIP_IGNORE_SILENCE_DURATION', 'VOIP_JOIN_SILENCE_DURATION', 'VOIP_CONFIDENCE_THRESHOLD', 'CONDITIONAL_STEP_TIME_BASED', 'CONDITIONAL_STEP_BUSINESS_HOURS', 'CONDITIONAL_STEP_CAPABILITY_VALUE_BASED', 'CONDITIONAL_STEP_JSON_PATH_BASED', 'ORDERED_LIST_TO_BUTTON', 'CONNECTORPROPS']
const USERINPUT_WITH_CUSTOM_EDITOR = ['MEDIA', 'BUTTON', 'FORM']

const isComponentDisabled = (componentName, license, features) => {
  if (features.componentsWhitelist && features.componentsWhitelist.indexOf(componentName) >= 0) return false
  if (features.componentsBlacklist && features.componentsBlacklist.indexOf(componentName) >= 0) return true
  if (license.components && license.components.indexOf(componentName) < 0) return true
}

const getLicensedLogicHooksSettings = (license, features, customRegisteredComponents) => {
  if (customRegisteredComponents) {
    for (const customRegisteredComponent of customRegisteredComponents) {
      if (customRegisteredComponent.type === 'LOGICHOOK') {
        logicHookSettings[customRegisteredComponent.ref] = customRegisteredComponent
      }
    }
  }
  const result = Object.assign({}, logicHookSettings)

  if (isComponentDisabled('botium-logichook-http/SETFROMHTTPGET', license, features)) delete result.SETFROMHTTPGET
  if (isComponentDisabled('botium-logichook-http/SETFROMHTTPPOST', license, features)) delete result.SETFROMHTTPPOST
  if (isComponentDisabled('botium-logichook-http/SETPARAMETERSTOREFROMHTTPGET', license, features)) delete result.SETPARAMETERSTOREFROMHTTPGET
  if (isComponentDisabled('botium-logichook-http/SETPARAMETERSTOREFROMHTTPPOST', license, features)) delete result.SETPARAMETERSTOREFROMHTTPPOST
  if (isComponentDisabled('botium-logichook-http/HTTPGET', license, features)) delete result.HTTPGET
  if (isComponentDisabled('botium-logichook-http/HTTPPOST', license, features)) delete result.HTTPPOST
  if (isComponentDisabled('botium-logichook-connectorprops', license, features)) delete result.CONNECTORPROPS

  return result
}

const getLicensedUserInputSettings = (license, features, customRegisteredComponents) => {
  if (customRegisteredComponents) {
    for (const customRegisteredComponent of customRegisteredComponents) {
      if (customRegisteredComponent.type === 'USERINPUT') {
        userInputSettings[customRegisteredComponent.ref] = customRegisteredComponent
      }
    }
  }

  return Object.assign({}, userInputSettings)
}

const getLicensedAsserterSettings = (license, features, customRegisteredComponents) => {
  if (customRegisteredComponents) {
    for (const customRegisteredComponent of customRegisteredComponents) {
      if (customRegisteredComponent.type === 'ASSERTER') {
        asserterSettings[customRegisteredComponent.ref] = customRegisteredComponent
      }
    }
  }
  const result = Object.assign({}, asserterSettings)

  if (isComponentDisabled('botium-asserter-mssql', license, features)) delete result.MSSQL
  if (isComponentDisabled('botium-asserter-mysql', license, features)) delete result.MYSQL
  if (isComponentDisabled('botium-asserter-oracledb', license, features)) delete result.ORACLEDB
  if (isComponentDisabled('botium-asserter-postgres', license, features)) delete result.POSTGRES
  if (isComponentDisabled('botium-asserter-http', license, features)) delete result.HTTP
  if (isComponentDisabled('botium-asserter-basiclink', license, features)) delete result.HASLINK
  if (isComponentDisabled('botium-asserter-sms', license, features)) delete result.CHECKSMS
  if (isComponentDisabled('botium-asserter-email/CHECKINBOX', license, features)) delete result.CHECKINBOX
  if (isComponentDisabled('botium-connector-twilio-ivr/CHECKTWILIOSMS', license, features)) delete result.CHECKTWILIOSMS
  if (isComponentDisabled('botium-connector-liveperson/CHECKLIVEPERSONSKILL', license, features)) delete result.CHECKLIVEPERSONSKILL
  if (isComponentDisabled('botium-asserter-factcheck', license, features)) delete result.FACTCHECK
  if(!features || !features.factCheckerEnabled) delete result.FACTCHECK
  if (isComponentDisabled('botium-asserter-misuse', license, features)) delete result.MISUSE
  if(!features || !features.misuseCheckerEnabled) delete result.MISUSE
  if (isComponentDisabled('botium-asserter-objective', license, features)) delete result.OBJECTIVE
  if (isComponentDisabled('botium-asserter-language', license, features)) delete result.LANGUAGE

  Object.keys(result).forEach(as => { result[as].sender = result[as].sender && result[as].sender.length ? result[as].sender : ['bot'] })
  return result
}

export const dbConvoToForm = (convo) => {
  const updateLogicHook = (lh) => {
    if (
      (lh.name === 'SETFROMHTTPGET') ||
      (lh.name === 'SETFROMHTTPPOST') ||
      (lh.name === 'SETPARAMETERSTOREFROMHTTPGET') ||
      (lh.name === 'SETPARAMETERSTOREFROMHTTPPOST') ||
      (lh.name === 'HTTPGET') ||
      (lh.name === 'HTTPPOST')
    ) {
      const i = (lh.args || []).findIndex(a => (a || '').startsWith('ARG-HEADERS-') )
      if (i >= 0) {
        lh.args[i] = JSON.parse(lh.args[i].substring('ARG-HEADERS-'.length))
      }
    }
    if (lh.name === 'CONNECTORPROPS' && lh.args && lh.args.length > 0) {
      lh.args[0] = JSON.parse(lh.args[0])
    }

    return lh
  }
  const extractConvoStepParameters = (step) => {
    let convoStepParameters = null
    step.logicHooks = step.logicHooks && step.logicHooks.filter(l => {
      if (l.name === LOGICHOOK_FOR_CONVO_STEP_PARAMETERS) {
        try {
          convoStepParameters = JSON.parse(l.args?.[0])
        } catch (err) {}
        return false
      }

      return true
    })

    step.useMatchingMode = (convoStepParameters?.matchingMode && TestSetMatchingModeTypeFromCore[convoStepParameters.matchingMode] ) ? TestSetMatchingModeTypeFromCore[convoStepParameters.matchingMode] : MATCHING_MODE_DEFAULT
    step.useMatchingModeWer = convoStepParameters?.matchingModeWer || 50
    if (convoStepParameters?.ignoreNotMatchedBotResponses) {
      step.retryMainAsserter = convoStepParameters.ignoreNotMatchedBotResponses.mainAsserter
      step.retryAllAsserters = convoStepParameters.ignoreNotMatchedBotResponses.allAsserters
      step.retryTimeout = convoStepParameters.ignoreNotMatchedBotResponses.timeout
    }
    step.stepTimeout = convoStepParameters?.stepTimeout
  }
  convo.begin && extractConvoStepParameters(convo.begin)
  for (const lh of convo?.begin?.logicHooks || []) {
    updateLogicHook(lh)
  }
  if (convo.steps) {
    for (const step of convo.steps) {
      (step.logicHooks || []).forEach(updateLogicHook)
      extractConvoStepParameters(step)
    }
  }

  return convo
}

export const formToUpsertConvo = (values, convo) => {
  const addForConvoStepParameters = (logicHooks, step) => {
    const convoStepParameters = {}
    if (step.useMatchingMode && step.useMatchingMode !== MATCHING_MODE_DEFAULT && TestSetMatchingModeTypeToCore[step.useMatchingMode]) {
      convoStepParameters.matchingMode = TestSetMatchingModeTypeToCore[step.useMatchingMode]
    }
    if (step.useMatchingMode && step.useMatchingMode === 'MATCHING_MODE_WER') {
      convoStepParameters.matchingModeWer = step.useMatchingModeWer
    }
    if (step.retryMainAsserter || step.retryAllAsserters) {
      convoStepParameters.ignoreNotMatchedBotResponses = {
        mainAsserter: step.retryMainAsserter,
        allAsserters: step.retryAllAsserters,
        timeout: (step.retryTimeout || CONVO_STEP_PARAMETERS_RETRY_TIMEOUT_DEFAULT)
      }
    }
    if(step.stepTimeout) {
      convoStepParameters.stepTimeout = step.stepTimeout
    }

    if (Object.keys(convoStepParameters).length > 0) {
      return [...(logicHooks || []), {
        name: LOGICHOOK_FOR_CONVO_STEP_PARAMETERS, args: [JSON.stringify(convoStepParameters)]
      }]
    }
    delete step.matchingMode
    delete step.retryMainAsserter
    delete step.retryAllAsserters
    delete step.retryTimeout
    delete step.stepTimeout

    return logicHooks
  }

  const convertLogicHook = (lh) => {
    return {
      name: lh.name,
      args: lh.args && lh.args.map((a, index) => {
        if (_.isNil(a)) return a
        if (
          (lh.name === 'SETFROMHTTPGET' && index === 3) ||
          (lh.name === 'SETFROMHTTPPOST' && index === 4) ||
          (lh.name === 'SETPARAMETERSTOREFROMHTTPGET' && index === 3) ||
          (lh.name === 'SETPARAMETERSTOREFROMHTTPPOST' && index === 4) ||
          (lh.name === 'HTTPGET' && index === 1) ||
          (lh.name === 'HTTPPOST' && index === 2)
          ) {
          // ARG-HEADERS-: its position is dynamic. We use a prefix to identify it
          return `ARG-HEADERS-${JSON.stringify(a)}`
        }
        if (lh.name === 'CONNECTORPROPS' && index === 0) {
          return JSON.stringify(a)
        }
        return `${a}`
      }).filter(a => !_.isNil(a))
    }
  }

  const result = {
    name: values.name || convo.name,
    description: values.description || convo.description,
    skip: !!values.skip,
    partial: !!values.partial,
    begin: (values.begin && {
      sender: 'begin',
      messageText: values.begin.messageText,
      not: !!values.begin.not,
      logicHooks: addForConvoStepParameters(values.begin.logicHooks && values.begin.logicHooks.map(convertLogicHook), values.begin),
    }) || null,
    steps: values.steps && values.steps.map(step => ({
        sender: step.sender,
        channel: step.channel,
        messageText: step.messageText,
        sourceData: step.sourceData,
        optional: !!step.optional,
        not: !!step.not,
        asserters: step.asserters && step.asserters.map(a => ({ name: a.name, args: a.args && a.args.filter(a => !_.isNil(a)).map(a => `${a}`), not: a.not, optional: !!step.optional })),
        logicHooks: step.logicHooks && addForConvoStepParameters(step.logicHooks.map(convertLogicHook), step),
        userInputs: step.userInputs && step.userInputs.map(a => ({ name: a.name, args: a.args && a.args.filter(a => !_.isNil(a)).map(a => `${a}`) }))
      })
    ),
    end: (values.end && {
      sender: 'end',
      messageText: values.end.messageText,
      not: !!values.end.not,
      asserters: values.end.asserters && values.end.asserters.map(a => ({ name: a.name, args: a.args && a.args.filter(a => !_.isNil(a)).map(a => `${a}`), not: a.not })),
      logicHooks: values.end.logicHooks && values.end.logicHooks.map(a => ({ name: a.name, args: a.args && a.args.filter(a => !_.isNil(a)).map(a => `${a}`) })),
    }) || null,
  }
  return result
}


class TestSetConvoEditorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editStep: null,
      editStepName: null,
      editStepIndex: null,
      asserterExpanded: null,
      filter: {
        filterText: '',
      },
      messageTextChangedByUttSelector: false,
      textUserInputExpanded: true,
      selectTextOrUtterance: 'text',
      tempStepText: '',
      tempStepUtterance: '',
      utteranceSelectorLoaded: false,
    }
    this.setStateFromConvoEditor = this.setStateFromConvoEditor.bind(this)
    this.userInputRef = React.createRef()
  }


  scrollLeftSide() {
    const scroller = this.userInputRef.current
    if (scroller && this.state.asserterExpanded) {
      const index = parseInt(this.state.asserterExpanded.split('-')[1])
      const type = this.state.asserterExpanded.split('-')[0]
      let scrollToPosition = 0
      if (type === 'UI') {
        scrollToPosition = (!this.state.utteranceSelectorLoaded ? 0 : 300) + 87 * index
      } else if (type === 'LH') {
        scrollToPosition = (!this.state.utteranceSelectorLoaded ? 0 : 300) + 87 * (this.state.editStep.userInputs?.length || 0) + 87 * (this.state.editStep.asserters?.length || 0) + 87 * index
      } else if (type === 'A') {
        scrollToPosition = (!this.state.utteranceSelectorLoaded ? 0 : 300) + 87 * index
      }
      if (scroller.scrollTo) {
        scroller.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        })
      } else {
        scroller.scrollTop = scroller.scrollHeight
      }
    }
  }

  mapValuesToConvo = (values) => {
    const stepIndex = this.state.editStepIndex
    const editStep = values.steps[stepIndex]
    if (editStep.logicHooks && editStep.logicHooks.some(lh => lh.name.startsWith('CONDITIONAL_STEP'))) {
      const isSameConditionalGroup = (step, groupId) => {
        if (!step || step.sender !== 'bot' || !step.logicHooks || !step.logicHooks.some(lh => lh.name.toUpperCase().startsWith('CONDITIONAL_STEP'))) return false

        const conditionalLogicHook = step.logicHooks.find(lh => lh.name.startsWith('CONDITIONAL_STEP'))
        return conditionalLogicHook.args[1] === groupId
      }

      for (let i = stepIndex + 1; i < values.steps.length; i++) {
        if(isSameConditionalGroup(values.steps[i])) {
          values.steps[i].optional = editStep.optional
        } else {
          break
        }
      }
      for (let i = stepIndex - 1; i >= 0; i--) {
        if(isSameConditionalGroup(values.steps[i])) {
          values.steps[i].optional = editStep.optional
        } else {
          break
        }
      }
    }
    if(editStep.asserters && editStep.asserters.some(a => a.name === 'MISUSE')) {
      for (const asserter of editStep.asserters) {

        if (asserter.name === 'MISUSE') {
          const args = []
          args.push(asserter.domains ? `Domains: ${asserter.domains.join(';')}` : 'domains: ')
          args.push(asserter.ignoredSentences ? `IgnoredSentences: ${asserter.ignoredSentences.join(';')}` : 'IgnoredSentences: ')
          args.push(asserter.forbiddenTopics ? `ForbiddenTopics: ${asserter.forbiddenTopics.join(';')}` : 'ForbiddenTopics: ')
          args.push(asserter.approvedTopics ? `ApprovedTopics: ${asserter.approvedTopics.join(';')}` : 'ApprovedTopics: ')
          asserter.args = args
        }
      }
    }
  }

  mapValuesToForm = (values) => {
    for(const step of values.steps) {
      if(step.asserters && step.asserters.some(a => a.name === 'MISUSE')) {
        for (const asserter of step.asserters) {
          if (asserter.name === 'MISUSE') {
            const args = asserter.args
            const domains = []
            if(args[0].startsWith('Domains: ')) {
              const domainsArg = args[0].substring('Domains: '.length)
              if (!_.isEmpty(domainsArg)) {
                domains.push(...domainsArg.split(';'))
              }
            }
            asserter.domains = domains

            const ignoredSentences = []
            if(args[1].startsWith('IgnoredSentences: ')) {
              const ignoredSentencesArgs = args[1].substring('IgnoredSentences: '.length)
              if (!_.isEmpty(ignoredSentencesArgs)) {
                ignoredSentences.push(...ignoredSentencesArgs.split(';'))
              }
            }
            asserter.ignoredSentences = ignoredSentences

            const forbiddenTopics = []
            if(args[2].startsWith('ForbiddenTopics: ')) {
              const forbiddenTopicsArgs = args[2].substring('ForbiddenTopics: '.length)
              if (!_.isEmpty(forbiddenTopicsArgs)) {
                forbiddenTopics.push(...forbiddenTopicsArgs.split(';'))
              }
            }
            asserter.forbiddenTopics = forbiddenTopics

            const approvedTopics = []
            if(args[3].startsWith('ApprovedTopics: ')) {
              const approvedTopicsArgs = args[3].substring('ApprovedTopics: '.length)
              if (!_.isEmpty(approvedTopicsArgs)) {
                approvedTopics.push(...approvedTopicsArgs.split(';'))
              }
            }
            asserter.approvedTopics = approvedTopics
          }
        }
      }
    }
    return values
  }


  setStateFromConvoEditor(state) {
    this.setState(state)
  }

  render() {
    const { disabled, hideName, warnings, hidePartialConvo, license, features, registeredComponentsData, classes, setTestSetState, match } = this.props

    const { filter } = this.state
    const allowedUserInputs = Object.keys(getLicensedUserInputSettings(license, features, registeredComponentsData.registeredcomponents))
    const allowedLogicHooks = Object.keys(getLicensedLogicHooksSettings(license, features, registeredComponentsData.registeredcomponents))
    const allowedAsserters = Object.keys(getLicensedAsserterSettings(license, features, registeredComponentsData.registeredcomponents))
    const allowedLogicHooksBot = allowedLogicHooks.filter(lh => logicHookSettings[lh].sender.includes('bot'))
    const allowedLogicHooksBegin = allowedLogicHooks.filter(lh => logicHookSettings[lh].sender.includes('begin'))
    const allowedLogicHooksEnd = allowedLogicHooks.filter(lh => logicHookSettings[lh].sender.includes('end'))
    const allowedAssertersBot = allowedAsserters.filter(as => asserterSettings[as].sender.includes('bot'))
    const allowedAssertersEnd = allowedAsserters.filter(as => asserterSettings[as].sender.includes('end'))
    const showCustomAsserter = (!filter.filterText || 'Custom Asserter'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0)
    const showOtherAsserter = (!filter.filterText || getOtherAsserters(allowedAssertersBot)
    .filter((as) => !filter.filterText || asserterSettings[as].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).length > 0)
    const checkBotiumAsserterVisibility = (sectionKey) => (!filter.filterText || filterAsserterBySection(sectionKey, allowedAssertersBot)
    .filter((as) => !filter.filterText || asserterSettings[as].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).length > 0)
    const showBotiumAsserter = (!filter.filterText || !!Object.keys(asserterSections).find(sectionKey => checkBotiumAsserterVisibility(sectionKey)))
    const showLogicHook = (!filter.filterText || 'Custom Logic Hook'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0 ||
    allowedLogicHooksBot.filter((lh) => !filter.filterText || lh.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).length > 0)
    const showAnyAsserter = showCustomAsserter || showOtherAsserter || showBotiumAsserter
    const showAnyLogicHook = showLogicHook

    const showAddContent = (!filter.filterText || 'Custom User Input'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0 ||
    allowedUserInputs.filter((ui) => !filter.filterText || userInputSettings[ui].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).length > 0)
    const showAnyContent = showAddContent
    const showUserLogicHook = (!filter.filterText || 'Custom Logic Hook'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0 ||
    allowedLogicHooks.filter((lh) => !filter.filterText || lh.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).length > 0)
    const showAnyUserLogicHook = showUserLogicHook


    return (<FormSpy subscriptions={{ values: true, form: true }} render={({ values, form: { change, getState } }) => {
      return <React.Fragment>
      <ConvoEditor
        disabled={disabled}
        hideName={hideName}
        warnings={warnings}
        hidePartialConvo={hidePartialConvo}
        values={values}
        change={change}
        testSetId={match.params.testSetId}
        testSetScriptId={match.params.testSetScriptId}
        setParentState={this.setStateFromConvoEditor}
        setTestSetState={setTestSetState}
        queryEditableConvo={TESTSET_EDITABLE_CONVO_QUERY}
        dbConvoToForm={dbConvoToForm}
        asserterSettings={asserterSettings}
        logicHookSettings={logicHookSettings}
      />
      <Form initialValues={values} onSubmit={async (values, form) => {
        change(this.state.editStep, values)
      }} mutators={{ ...arrayMutators }}>
        {({ values: v, form, invalid, dirty }) => {
          return <ConfirmationDialog
            maxWidth="lg"
            disablePerfectScrollbar
            okText="Apply changes"
            okDisabled={invalid}
            onOk={() => {
              form.submit()
              this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            onCancel={() => {
              if (dirty && window.confirm('Are you sure you want to leave without saving?')) {
                form.reset()
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              } else {
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              }
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            open={!!(this.state.editStep && this.state.editStep.sender === 'begin')}
            title="Edit Conversation Begin Step">
            {this.state.editStep && this.state.editStep.sender === 'begin' &&
              <GridContainer >
                <GridItem xs={7}>
                  <div className={classes.convoStepEditorScrollableContainer} ref={this.userInputRef} id="userInputRef">
                  <GridContainer >
                    <FieldArray name="begin.logicHooks">
                      {({ fields }) => (<React.Fragment>
                        {fields.map((name, logicHookIndex) => (
                          <GridItem xs={12} key={name}>
                            {this.renderLogicHook(form.change, name, v.begin, logicHookIndex, () => fields.remove(logicHookIndex), classes)}
                          </GridItem>
                        ))}
                      </React.Fragment>)}
                    </FieldArray>
                  </GridContainer>
                  </div>
                </GridItem>
                <GridItem xs={5} borderLeft >
                  <GridContainer classes={{ grid: classes.convoStepEditorScrollableContainer }}>
                    <FieldArray name="begin.logicHooks">
                      {({ fields }) => (<React.Fragment>
                        <GridItem xs={12}>
                          <Text header >Add Logic Hook</Text>
                          {allowedLogicHooksBegin.map(lh => <Button key={lh} className={classes.convoStepEditorCardButton} card onClick={() => {
                            this.scrollLeftSide()
                            const newLogicHookIndex = fields.length || 0
                            fields.push({ name: lh, args: [] })
                            this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                          }}>
                            {renderComponentAvatar(lh, null, 'logichook')}
                            {logicHookSettings[lh].name}
                          </Button>)}
                          {
                            <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                              this.scrollLeftSide()
                              const newLogicHookIndex = fields.length || 0
                              fields.push({ name: 'MY_LOGIC_HOOK', args: [] })
                              this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                            }}>
                              {renderComponentAvatar('CUSTOM', null, 'logichook')}
                              Custom Logic Hook
                            </Button>
                          }
                        </GridItem>
                      </React.Fragment>)}
                    </FieldArray>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            }
          </ConfirmationDialog>
        }}
      </Form>
      <Form initialValues={values} onSubmit={async (values, form) => { change(this.state.editStep, values) }} mutators={{ ...arrayMutators }}>
        {({ values: v, form, invalid, dirty }) => (
          <ConfirmationDialog
            okText="Apply changes"
            disablePerfectScrollbar
            okDisabled={invalid}
            open={!!(this.state.editStep && this.state.editStep.sender === 'include')}
            onOk={() => {
              form.submit()
              this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            onCancel={() => {
              if (dirty && window.confirm('Are you sure you want to leave without saving?')) {
                form.reset()
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              } else {
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              }
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            title={`Including Partial Convo at Step #${this.state.editStepIndex + 1}`}>
            {this.state.editStep && this.state.editStep.sender === 'include' &&
              <GridContainer>
                <GridItem xs={12}>
                  {this.renderPartialSelector(form.change, v, this.state.editStepName, this.state.editStepIndex)}
                </GridItem>
              </GridContainer>
            }
          </ConfirmationDialog>
        )}
      </Form>
      <Form initialValues={values} onSubmit={async (values, form) => { change(this.state.editStep, values) }} mutators={{ ...arrayMutators }}>
        {({ values: v, form, invalid, dirty }) => {
          return <ConfirmationDialog
            maxWidth="lg"
            disablePerfectScrollbar
            okText="Apply changes"
            okDisabled={invalid}
            open={!!(this.state.editStep && this.state.editStep.sender === 'me')}
            onOk={() => {
              form.submit()
              this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            onCancel={() => {
              if (dirty && window.confirm('Are you sure you want to leave without saving?')) {
                form.reset()
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              } else {
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              }
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            title={`Edit User Conversation Step #${this.state.editStepIndex + 1}`}>
            {this.state.editStep && this.state.editStep.sender === 'me' &&
              <GridContainer>
                <GridItem xs={7}>
                  <div className={classes.convoStepEditorScrollableContainer} ref={this.userInputRef} id="userInputRef">
                    <GridContainer >
                      <GridItem xs={11}>
                        <ExpansionPanel data-unique={`expTestSetConvoEditor_Text`} expanded={this.state.textUserInputExpanded} onChange={() => this.setState({ textUserInputExpanded: !this.state.textUserInputExpanded })}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <ShowIcon custom icon="text" /> Text / Utterance
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <GridContainer nounset>
                              <GridItem xs={12}>
                                {this.renderUtteranceSelector(form.change, v, this.state.editStepName, this.state.editStepIndex, false)}
                              </GridItem>
                            </GridContainer>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </GridItem>

                      <FieldArray name={`${this.state.editStepName}.userInputs`}>
                        {({ fields }) => (<React.Fragment>
                          {fields.map((name, userInputIndex) => (
                            <GridItem xs={12} key={name}>
                              {this.renderUserInput(form.change, name, v.steps[this.state.editStepIndex], userInputIndex, () => fields.remove(userInputIndex), classes)}
                            </GridItem>
                          ))}
                        </React.Fragment>)}
                      </FieldArray>

                      <FieldArray name={`${this.state.editStepName}.logicHooks`}>
                        {({ fields }) => (<React.Fragment>
                          {fields.map((name, logicHookIndex) => (
                            <GridItem xs={12} key={name}>
                              {this.renderLogicHook(form.change, name, v.steps[this.state.editStepIndex], logicHookIndex, () => fields.remove(logicHookIndex), classes)}
                            </GridItem>
                          ))}
                        </React.Fragment>)}
                      </FieldArray>
                    </GridContainer>
                  </div>
                </GridItem>
                <GridItem xs={5} borderLeft smallPaddingLeft >
                <GridContainer classes={{ grid: classes.convoStepEditorFilterContainer }}>
                    <GridItem>
                      <CustomTextField
                        input={{
                          onChange: e =>
                            this.setState({
                              filter: { ...filter, filterText: e.target.value },
                            }),
                          value: filter.filterText,
                        }}
                        label="Filter"
                        data-unique="ctfTestSetConvoEditorFilter"
                        deleteValue
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer classes={{ grid: classes.convoStepEditorScrollableContainer }}>
                    <FieldArray name={`${this.state.editStepName}.userInputs`}>
                      {({ fields }) => (<React.Fragment>
                        <GridItem xs={12}>
                          <Text header >Add Content</Text>
                          {!showAnyContent &&
                          <Text>No Content found</Text>
                          }
                          {allowedUserInputs.filter((ui) => !filter.filterText || userInputSettings[ui].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).map((ui, i) => <Button key={i} className={classes.convoStepEditorCardButton} card onClick={() => {
                            this.scrollLeftSide()
                            const newUserInputIndex = fields.length || 0
                            fields.push({ name: ui, args: [] })
                            this.setState({ asserterExpanded: `UI-${newUserInputIndex}` })
                          }}>
                            {renderComponentAvatar(ui)}
                            {userInputSettings[ui].name}
                          </Button>)}
                          {(!filter.filterText || 'Custom User Input'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) &&
                            <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                              this.scrollLeftSide()
                              const newUserInputIndex = fields.length || 0
                              fields.push({ name: 'MY_USER_INPUT', args: [] })
                              this.setState({ asserterExpanded: `UI-${newUserInputIndex}` })
                            }}>
                              <ShowIcon custom icon="customUserInput" />
                              Custom User Input

                            </Button>
                          }
                        </GridItem>

                      </React.Fragment>)}
                    </FieldArray>
                    <FieldArray name={`${this.state.editStepName}.logicHooks`}>
                      {({ fields }) => (<React.Fragment>

                        <GridItem xs={12} classes={{ root: classes.headertopmargin }}>
                          <Text header>Add Logic Hook</Text>
                          {!showAnyUserLogicHook &&
                          <Text>No Logic Hook found</Text>
                          }
                          {allowedLogicHooks.filter(lh => logicHookSettings[lh].sender.find(s => s === this.state.editStep.sender)).filter((lh) => !filter.filterText || lh.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).map((lh, i) => <Button key={i} className={classes.convoStepEditorCardButton} card onClick={() => {
                            this.scrollLeftSide()
                            const newLogicHookIndex = fields.length || 0
                            fields.push({ name: lh, args: [] })
                            this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                          }}>
                            {renderComponentAvatar(lh, null, 'logichook')}
                            {logicHookSettings[lh].name}
                          </Button>)}
                          {(!filter.filterText || 'Custom Logic Hook'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) &&
                            <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                              this.scrollLeftSide()
                              const newLogicHookIndex = fields.length || 0
                              fields.push({ name: 'MY_LOGIC_HOOK', args: [] })
                              this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                            }}>
                              {renderComponentAvatar('CUSTOM', null, 'logichook')}
                              Custom Logic Hook
                            </Button>
                          }
                        </GridItem>

                      </React.Fragment>)}
                    </FieldArray>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            }
          </ConfirmationDialog>
        }}
      </Form>
      <Form
        initialValues={this.mapValuesToForm(values)}
        onSubmit={async (values, form) => {
          this.mapValuesToConvo(values)
          change(this.state.editStep, values)
        }}
        mutators={{ ...arrayMutators }}>
        {({ values: v, form, invalid, dirty }) => {
          return <ConfirmationDialog
            maxWidth="lg"
            disablePerfectScrollbar
            okText="Apply changes"
            okDisabled={invalid}
            onOk={() => {
              form.submit()
              this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            onCancel={() => {
              if (dirty && window.confirm('Are you sure you want to leave without saving?')) {
                form.reset()
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              } else {
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              }
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            open={!!(this.state.editStep && this.state.editStep.sender === 'bot')}
            title={`Edit Bot Conversation Step #${this.state.editStepIndex + 1}`}>
            {this.state.editStep && this.state.editStep.sender === 'bot' &&
              <GridContainer>
                <GridItem xs={7}>
                  <div className={classes.convoStepEditorScrollableContainer} ref={this.userInputRef} id="userInputRef">
                    <GridContainer>
                        <GridItem xs={11}>
                          <GridContainer>
                            <GridItem xs={12}>
                              <ExpansionPanel data-unique={`expTestSetConvoEditor_Text`} expanded={this.state.textUserInputExpanded} onChange={() => this.setState({ textUserInputExpanded: !this.state.textUserInputExpanded })}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                  <ShowIcon custom icon="text" /> Text / Utterance
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <GridContainer nounset>
                                    <GridItem xs={12}>
                                      {this.renderUtteranceSelector(form.change, v, this.state.editStepName, this.state.editStepIndex, true)}
                                    </GridItem>
                                  </GridContainer>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                      <FieldArray name={`${this.state.editStepName}.asserters`}>
                        {({ fields }) => (<React.Fragment>
                          {fields.map((name, asserterIndex) => (
                            <GridItem xs={12} key={name}>
                              {this.renderAsserter(name, v.steps[this.state.editStepIndex], asserterIndex, () => fields.remove(asserterIndex), classes)}
                            </GridItem>
                          ))}
                        </React.Fragment>)}
                      </FieldArray>
                      <FieldArray name={`${this.state.editStepName}.logicHooks`}>
                        {({ fields }) => (<React.Fragment>
                          {fields.map((name, logicHookIndex) => (
                            <GridItem xs={12} key={name}>
                              {this.renderLogicHook(form.change, name, v.steps[this.state.editStepIndex], logicHookIndex, () => fields.remove(logicHookIndex), classes)}
                            </GridItem>
                          ))}
                        </React.Fragment>)}
                      </FieldArray>
                    </GridContainer>
                  </div>
                </GridItem>
                <GridItem xs={5} borderLeft>
                  <CustomTabsSecondary
                    headerColor="info"
                    name="tabTestSetConvoEditorRight"
                    tabs={[
                      {
                        tabName: 'Components',
                        tabContent: <>
                          <GridContainer classes={{ grid: classes.convoStepEditorFilterContainer }}>
                            <GridItem>
                              <CustomTextField
                                input={{
                                  onChange: e =>
                                    this.setState({
                                      filter: { ...filter, filterText: e.target.value },
                                    }),
                                  value: filter.filterText,
                                }}
                                label="Filter"
                                data-unique="ctfTestSetConvoEditorFilter"
                                deleteValue
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer classes={{ grid: classes.convoStepEditorScrollableContainerComponents }}>
                            <GridItem xs={12}>
                              <FieldArray name={`${this.state.editStepName}.asserters`}>
                                {({ fields }) => (<React.Fragment>
                                  <Text header>Add Asserter</Text>
                                  {!showAnyAsserter &&
                                    <Text>No Asserter found</Text>
                                  }
                                  {
                                    Object.keys(asserterSections).map(sectionKey =>
                                      <div key={sectionKey}>
                                        { checkBotiumAsserterVisibility(sectionKey) &&
                                          <Text bold>{asserterSections[sectionKey]}</Text>
                                        }
                                        {filterAsserterBySection(sectionKey, allowedAssertersBot).filter((as) => !filter.filterText || asserterSettings[as].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).map(as => <Button key={as} className={classes.convoStepEditorCardButton} card onClick={() => {
                                          this.scrollLeftSide()
                                          const newAsserterIndex = fields.length || 0
                                          fields.push({ name: as, args: [] })

                                          this.setState({ asserterExpanded: `A-${newAsserterIndex}` })
                                        }}>
                                          {renderComponentAvatar(as, null, 'asserter')}
                                          {asserterSettings[as].name}
                                        </Button>)}
                                      </div>
                                    )
                                  }
                                  <React.Fragment>
                                    { showOtherAsserter &&
                                      <Text bold>Other Asserters</Text>
                                    }
                                    {/*fact check asserter is just a marker, we dont allow it to add twice. Should this marker flag come from some from some config?*/}
                                    {getOtherAsserters(allowedAssertersBot).filter((as) => (!filter.filterText || asserterSettings[as].name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) && (as !== 'FACTCHECK' || !fields.value || !fields.value.find(f => f.name === 'FACTCHECK'))).map(as => <Button key={as} className={classes.convoStepEditorCardButton} card onClick={() => {
                                      this.scrollLeftSide()
                                      const newAsserterIndex = fields.length || 0
                                      fields.push({ name: as, args: [] })
                                      this.setState({ asserterExpanded: `A-${newAsserterIndex}` })
                                    }}>
                                      {renderComponentAvatar(as, null, 'asserter')}
                                      {asserterSettings[as].name}

                                    </Button>)}

                                  </React.Fragment>
                                  {showCustomAsserter &&
                                    <Text bold>Custom Asserter</Text>
                                  }
                                  {(!filter.filterText || 'Custom Asserter'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) &&
                                    <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                                      this.scrollLeftSide()
                                      const newAsserterIndex = fields.length || 0
                                      fields.push({ name: 'MY_ASSERTER', args: [] })
                                      this.setState({ asserterExpanded: `A-${newAsserterIndex}` })
                                    }}>
                                      {renderComponentAvatar('CUSTOM', null, 'asserter')}
                                      Custom Asserter
                                    </Button>
                                  }
                                </React.Fragment>)}
                              </FieldArray>
                            </GridItem>


                            <GridItem xs={12} classes={{ root: classes.headertopmargin }}>
                              <FieldArray name={`${this.state.editStepName}.logicHooks`}>
                                {({ fields }) => (<React.Fragment>
                                  <Text header>Add Logic Hook</Text>
                                  {!showAnyLogicHook &&
                                    <Text>No Logic Hook found</Text>
                                  }
                                  {allowedLogicHooksBot.filter((lh) => !filter.filterText || lh.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0).map(lh => <Button key={lh} className={classes.convoStepEditorCardButton} card onClick={() => {
                                    this.scrollLeftSide()
                                    const newLogicHookIndex = fields.length || 0
                                    fields.push({ name: lh, args: [] })
                                    this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                                  }}>
                                    {renderComponentAvatar(lh, null, 'logichook')}
                                    {logicHookSettings[lh].name}
                                  </Button>)}
                                  {(!filter.filterText || 'Custom Logic Hook'.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) &&
                                    <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                                      this.scrollLeftSide()
                                      const newLogicHookIndex = fields.length || 0
                                      fields.push({ name: 'MY_LOGIC_HOOK', args: [] })
                                      this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                                    }}>
                                      {renderComponentAvatar('CUSTOM', null, 'logichook')}
                                      Custom Logic Hook
                                    </Button>
                                  }
                                </React.Fragment>)}
                              </FieldArray>
                            </GridItem>

                          </GridContainer>
                        </>,
                        dataUnique: 'tabTestSetConvoEditorRightEdit'
                      },
                      {
                        tabName: 'Configuration',
                        tabContent: <GridContainer classes={{ grid: classes.convoStepEditorScrollableContainerConfiguration}}>
                          <GridItem xs={12}>
                            <Field
                              name={`${this.state.editStepName}.useMatchingMode`}
                              component={renderSelect}
                              label="Text Matching Mode"
                              data-unique={`chkMatchingMode${this.state.editStepName}`}
                              items={[{ key: MATCHING_MODE_DEFAULT, label: 'Default (from Test Set)' }, ...MATCHING_MODES]}
                            />
                          </GridItem>
                          {_.get(v, this.state.editStepName)?.useMatchingMode === 'MATCHING_MODE_WER' && <GridItem xs={12}>
                            <Field
                            name={`${this.state.editStepName}.useMatchingModeWer`}
                            component={renderSlider}
                            label="Acceptable Word Error Rate"
                            helperText="Range between 0% (=exact match) and 100% (= total mismatch)"
                            parse={parseInteger}
                            min={0}
                            max={100}
                            validate={composeValidators(required, minValue(0), maxValue(100))}
                            step={1}
                            form={form}
                            showInputField
                            data-unique={`selMatchingMode${this.state.editStepName}Wer`}
                           />
                          </GridItem>}
                          <GridItem xs={12}><Divider noMarginBottom /></GridItem>
                          <GridItem xs={6}>
                            <Field
                              name={`${this.state.editStepName}.retryMainAsserter`}
                              component={renderCheckbox}
                              label="Retry if text check fails"
                              helperText="Ignore incoming bot message when the text check fails"
                              type="checkbox"
                              data-unique={`chkRetryMainAsserter_${this.state.editStepName}`}
                            />
                          </GridItem>
                          <GridItem xs={6}>
                            <Field
                              name={`${this.state.editStepName}.retryAllAsserters`}
                              component={renderCheckbox}
                              label="Retry if any of the asserters fails"
                              helperText="Ignore incoming bot message when the an asserter check fails"
                              type="checkbox"
                              data-unique={`chkRetryAllAsserters_${this.state.editStepName}`}
                            />
                          </GridItem>
                          {/*FinalFormField is a workaround. With Optional field the 2nd param of the validate function is not the values, but a function*/}
                          <GridItem xs={12}>
                            <Field
                              name={`${this.state.editStepName}.retryTimeout`}
                              component={renderIntField}
                              label={`Retry timeout in ms (default: ${CONVO_STEP_PARAMETERS_RETRY_TIMEOUT_DEFAULT}ms)`}
                              helperText="The incoming bot message will be ignored until timeout is over"
                              parse={parseInteger}
                              disabled={!_.get(v, this.state.editStepName).retryMainAsserter && !_.get(v, this.state.editStepName).retryAllAsserters}
                              data-unique={`chkRetryTimeout_${this.state.editStepName}`}
                            />
                          </GridItem>
                          <GridItem xs={12}><Divider noMarginTop noMarginBottom /></GridItem>
                          <GridItem xs={12}>
                            <Field
                              name={`${this.state.editStepName}.optional`}
                              component={renderCheckbox}
                              label="Optional Step"
                              type="checkbox"
                              data-unique={`chkOptional_${this.state.editStepName}`}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              name={`${this.state.editStepName}.stepTimeout`}
                              component={renderIntField}
                              label="Override Bot Response Timeout(ms)"
                              helperText="The overall timeout for bot responses can be adjusted in the chatbot configuration, but for this particular test step, you can override that setting and give the bot more or less time to answer."
                              parse={parseInteger}
                              data-unique={`intStepTimeout_${this.state.editStepName}`}
                            />
                          </GridItem>
                        </GridContainer>,
                        dataUnique: 'tabTestSetConvoEditorRightConfiguration'
                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>}
          </ConfirmationDialog>
        }}
      </Form>
      <Form initialValues={values} onSubmit={async (values, form) => { change(this.state.editStep, values) }} mutators={{ ...arrayMutators }}>
        {({ values: v, form, invalid, dirty }) => {
          return <ConfirmationDialog
            maxWidth="lg"
            disablePerfectScrollbar
            okText="Apply changes"
            okDisabled={invalid}
            onOk={() => {
              form.submit()
              this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            onCancel={() => {
              if (dirty && window.confirm('Are you sure you want to leave without saving?')) {
                form.reset()
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              } else {
                this.setState({ utteranceSelectorLoaded: false, editStep: null, editStepName: null, editStepIndex: null, asserterExpanded: null })
              }
              setTimeout(() => {
                const simpleBarContent = document.querySelector('.simplebar-content-wrapper')
                if (simpleBarContent) {
                  simpleBarContent.style.overflow = 'auto'
                }
              }, 100)
              filter.filterText = ''
            }}
            open={!!(this.state.editStep && this.state.editStep.sender === 'end')}
            title="Edit Conversation End Step">
            {this.state.editStep && this.state.editStep.sender === 'end' &&
              <GridContainer>
                <GridItem xs={7}>
                  <div className={classes.convoStepEditorScrollableContainer} ref={this.userInputRef} id="userInputRef">
                  <GridContainer>
                    <FieldArray name="end.asserters">
                      {({ fields }) => (<React.Fragment>
                        {fields.map((name, asserterIndex) => (
                          <GridItem xs={12} key={name}>
                            {this.renderAsserter(name, v.end, asserterIndex, () => fields.remove(asserterIndex), classes)}
                          </GridItem>
                        ))}
                      </React.Fragment>)}
                    </FieldArray>
                    <FieldArray name="end.logicHooks">
                      {({ fields }) => (<React.Fragment>
                        {fields.map((name, logicHookIndex) => (
                          <GridItem xs={12} key={name}>
                            {this.renderLogicHook(form.change, name, v.end, logicHookIndex, () => fields.remove(logicHookIndex), classes)}
                          </GridItem>
                        ))}
                      </React.Fragment>)}
                    </FieldArray>
                  </GridContainer>
                  </div>
                </GridItem>
                <GridItem xs={5} borderLeft >
                  <GridContainer classes={{ grid: classes.convoStepEditorScrollableContainer }}>
                    <GridItem xs={12}>
                      <FieldArray name="end.asserters">
                        {({ fields }) => (<React.Fragment>
                          <Text header>Add Asserter</Text>
                          {allowedAssertersEnd.map(as => <Button key={as} className={classes.convoStepEditorCardButton} card onClick={() => {
                            this.scrollLeftSide()
                            const newAsserterIndex = fields.length || 0
                            fields.push({ name: as, args: [] })
                            this.setState({ asserterExpanded: `A-${newAsserterIndex}` })
                          }}>
                            {renderComponentAvatar(as, null, 'asserter')}
                            {asserterSettings[as].name}
                          </Button>)}
                          {
                            <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                              this.scrollLeftSide()
                              const newAsserterIndex = fields.length || 0
                              fields.push({ name: 'MY_ASSERTER', args: [] })
                              this.setState({ asserterExpanded: `A-${newAsserterIndex}` })
                            }}>
                              {renderComponentAvatar('CUSTOM', null, 'asserter')}
                              Custom Asserter
                            </Button>
                          }
                        </React.Fragment>)}
                      </FieldArray>
                    </GridItem>
                    <GridItem xs={12}>
                      <FieldArray name="end.logicHooks">
                        {({ fields }) => (<React.Fragment>
                          <Text header  >Add Logic Hook</Text>
                          {allowedLogicHooksEnd.map(lh => <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                            this.scrollLeftSide()
                            const newLogicHookIndex = fields.length || 0
                            fields.push({ name: lh, args: [] })
                            this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                          }}>
                            {renderComponentAvatar(lh, null, 'logichook')}
                            {logicHookSettings[lh].name}
                          </Button>)}
                          {
                            <Button className={classes.convoStepEditorCardButton} card onClick={() => {
                              this.scrollLeftSide()
                              const newLogicHookIndex = fields.length || 0
                              fields.push({ name: 'MY_LOGIC_HOOK', args: [] })
                              this.setState({ asserterExpanded: `LH-${newLogicHookIndex}` })
                            }}>
                              {renderComponentAvatar('CUSTOM', null, 'logichook')}
                              Custom Logic Hook
                            </Button>
                          }
                        </React.Fragment>)}
                      </FieldArray>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>}
          </ConfirmationDialog>
        }}
      </Form>
    </React.Fragment>}} />)
  }

  renderPartialSelector(change, values, name, stepIndex) {
    const { testSetId, validPartialConvosData, history, classes } = this.props

    const msg = values.steps[stepIndex]

    const renderChip = (key, partialConvoName, onDelete) => {
      const compiledConvo = validPartialConvosData && validPartialConvosData.testsetvalidpartialconvos && validPartialConvosData.testsetvalidpartialconvos.find(c => c.name === partialConvoName)
      let compiledConvoLink = null
      if (compiledConvo && compiledConvo.script) {
        if (compiledConvo.script.scriptType === 'SCRIPTING_TYPE_PCONVO') {
          compiledConvoLink = `/testsets/view/${compiledConvo.testSet.id}/testcases/viewpconvo/${compiledConvo.script.id}/${encodeURIComponent(compiledConvo.script.name)}`
        } else {
          compiledConvoLink = `/testsets/view/${compiledConvo.testSet.id}/testcases/viewscript/${compiledConvo.script.id}`
        }
      }
      return <div className={classes.possitionChip}><ComponentChip key={key} component={{ name: 'INCLUDE', args: [partialConvoName] }}
        onDelete={onDelete}
        onClick={compiledConvoLink ? () => history.push(compiledConvoLink) : null}
      /></div>
    }

    return <GridContainer>
      {testSetId &&
        <GridItem xs={12}>
          <CustomSelect
            data-unique="ddbtnTestSetConvoEditorSelectPartialConvo"
            filterable
            label="Select Partial Convo(s)"
            fullWidth
            input={{
              name: 'selTestSetCopy',
              onChange: (e) => {
                const orig = (msg && msg.messageText) ? msg.messageText.split('\n') : []
                orig.push(e.target.value.name)
                const partialConvos = (msg && msg.partialConvos) ? msg.partialConvos : []
                partialConvos.push({
                  id: e.target.value.id,
                  name: e.target.value.name
                })
                change(`${name}.partialConvos`, partialConvos)
                change(`${name}.messageText`, orig.filter(s => s).join('\n'))

              }
            }}

            items={(validPartialConvosData && validPartialConvosData.testsetvalidpartialconvos && validPartialConvosData.testsetvalidpartialconvos.map(a => {
              return {
                key: {id: a.script.id, name: a.name},
                label: `${a.name} (${a.stepCount} conversation steps)`,
                onClick: () => {

                }
              }
            })) || []}
          />
        </GridItem>
      }
      <GridItem xs={12}>
        {msg && msg.channel && renderChip('channel', msg.channel, () => {
          change(`${name}.channel`, null)
        })}
        {msg && msg.messageText && msg.messageText.split('\n').map((a, ai) => a && renderChip(ai, a, () => {
          const pConvoDeleteIndex = msg.partialConvos.findIndex(p => p.name === a)
          if(pConvoDeleteIndex >= 0) {
            const partialConvos = msg.partialConvos
            partialConvos.splice(pConvoDeleteIndex, 1)
            change(`${name}.partialConvos`, partialConvos)
          }
          const orig = msg.messageText.split('\n')
          orig.splice(ai, 1)
          change(`${name}.messageText`, orig.filter(s => s).join('\n'))
        }))}
      </GridItem>
    </GridContainer>
  }

  renderUtteranceSelector(change, values, name, index, showNotFlag) {
    const { testSetId, validUtterancesData } = this.props
    const { selectTextOrUtterance, tempStepText, tempStepUtterance, utteranceSelectorLoaded } = this.state

    const _hasUtterances = validUtterancesData && validUtterancesData.testsetvalidutterances && validUtterancesData.testsetvalidutterances.length > 0
    const _getUttCount = (uttName) => validUtterancesData && validUtterancesData.testsetvalidutterances && (validUtterancesData.testsetvalidutterances.find(a => a.name === uttName) || { uttCount: 0 }).uttCount

    if (!utteranceSelectorLoaded) {
      if (validUtterancesData && validUtterancesData.testsetvalidutterances && validUtterancesData && validUtterancesData.testsetvalidutterances.map(a => a.name).includes(values.steps[index].messageText)) {
        this.setState({ selectTextOrUtterance: 'utterance', utteranceSelectorLoaded: true, tempStepUtterance: values.steps[index].messageText })
      } else {
        if (values.steps[index].sourceData) {
          this.setState({ selectTextOrUtterance: 'json', utteranceSelectorLoaded: true, tempStepText: values.steps[index].sourceData })
        } else {
          this.setState({ selectTextOrUtterance: 'text', utteranceSelectorLoaded: true, tempStepText: values.steps[index].messageText })
        }
      }
    }

    const selectorItems = [{ key: 'text', label: 'Text' }, { key: 'json', label: 'JSON Payload' }]
    if (_hasUtterances) {
      selectorItems.push({ key: 'utterance', label: 'Utterance' })
    }

    return (<GridContainer>
      {testSetId &&
        <GridItem xs={4}>
          <CustomSelect
            data-unique={`ddbtnTestSetUtteranceListCopy_${name}`}
            label="Type"
            filterable={false}
            input={{
              name: 'selTestSetCopy',
              value: selectTextOrUtterance,
              onChange: e => {
                this.setState({ selectTextOrUtterance: e.target.value })
                if (e.target.value === 'text') {
                  change(`${name}.messageText`, tempStepText)
                  change(`${name}.sourceData`, null)
                  change(`${name}.utteranceSet`, false)
                  change(`${name}.utteranceCount`, null)
                } else if (e.target.value === 'utterance') {
                  change(`${name}.messageText`, tempStepUtterance)
                  change(`${name}.sourceData`, null)
                  change(`${name}.utteranceSet`, true)
                  change(`${name}.utteranceCount`, _getUttCount(tempStepUtterance))
                } else {
                  change(`${name}.messageText`, null)
                  change(`${name}.sourceData`, tempStepText)
                  change(`${name}.utteranceSet`, false)
                  change(`${name}.utteranceCount`, null)
                }
              }
            }}
            items={selectorItems}
          />
        </GridItem>
      }
      <GridItem xs={8} className="ConvoEditor">
        {selectTextOrUtterance === 'text' &&
          <CustomTextArea
            input={{
              name: 'txtMessageText',
              value: tempStepText || undefined,
              resise: 'vertical',
              onChange: e => {
                this.setState({ tempStepText: e.target.value })
                change(`${name}.messageText`, e.target.value)
                change(`${name}.utteranceCount`, null)
                change(`${name}.utteranceSamples`, null)
              }
            }}
            label="Enter Text"
            data-unique={`txtMessageText_${name}`}
          />
        }
        {selectTextOrUtterance === 'json' &&
          <CustomCodeArea
            options={{ mode: 'application/json' }}
            helperText={<Text danger>{json(tempStepText)}</Text>}
            input={{
              name: 'txtMessageJson',
              value: tempStepText || undefined,
              onChange: e => {
                this.setState({ tempStepText: e })
                change(`${name}.sourceData`, e)
                change(`${name}.messageText`, null)
                change(`${name}.utteranceCount`, null)
                change(`${name}.utteranceSamples`, null)
              },
            }}
            label="Enter JSON Payload"
            data-unique={`txtMessageJson_${name}`}
          />
        }
        {selectTextOrUtterance === 'utterance' && _hasUtterances &&
          <CustomSelect
            data-unique={`ddbtnUtteranceList_${name}`}
            label="Select Utterance"
            filterable
            input={{
              name: 'selUtteranceList',
              value: tempStepUtterance,
              onChange: e => {
                this.setState({ tempStepUtterance: e.target.value })
                change(`${name}.messageText`, e.target.value)
                change(`${name}.utteranceSet`, true)
                change(`${name}.utteranceCount`,  _getUttCount(e.target.value))
              }
            }}
            items={(validUtterancesData && validUtterancesData.testsetvalidutterances && validUtterancesData.testsetvalidutterances.map(a => ({
              key: a.name,
              label: `${a.name} (${a.uttCount} user examples)`,
            }))) || []}
          />}
        {selectTextOrUtterance === 'utterance' && !_hasUtterances &&
          <Text muted>No utterances in this Test Set</Text>
        }
        {selectTextOrUtterance === 'utterance' && tempStepUtterance && <>

          {validUtterancesData.testsetvalidutterances.find(a => a.name === tempStepUtterance).uttSamples.map((a, index) => (
            <Text regular key={index}>{a}</Text>
          ))}
          <Text>{_getUttCount(tempStepUtterance)} user examples</Text></>
        }
      </GridItem>
      <FormSpy subscription={{ form: true }} render={({ form: { change } }) => (
        <OnChange name={`${name}.messageText`}>
          {() => {
            this.setState({ messageTextChangedByUttSelector: false })
          }}
        </OnChange>
      )} />
      {showNotFlag &&
        <GridItem xs={12}>
          <Field
            name={`${name}.not`}
            component={renderCheckbox}
            label="Negative Match"
            type="checkbox"
            data-unique={`chkNegativeMatch_${name}`}
            dense
          />

        </GridItem>
      }
    </GridContainer>)
  }

  renderArgsField(fieldName, label) {
    return <Field
      name={`${fieldName}.args`}
      component={renderAutoSuggest}
      label={label}
      helperText="Hit Enter to add multiple values"
      data-unique={`asTestSetConvoEditorArgSelection_${fieldName}`}
    />
  }

  renderSimpleLogicFields(fieldName, recordId, avatar, settings, remove, classes) {
    if (!settings) return null
    return this.renderListLogicFields(fieldName, recordId, avatar, settings.name, settings.argsLabel ? () => <GridItem xs={12}>{this.renderArgsField(fieldName, settings.argsLabel)}</GridItem> : null, remove, classes)
  }

  renderAsserterSimpleLogicFields(fieldName, recordId, avatar, settings, remove, classes, showNotFlag) {
    if (!settings) return null
    return this.renderAsserterListLogicFields(fieldName, recordId, avatar, settings.name, settings.argsLabel ? () => <GridItem xs={12}>{this.renderArgsField(fieldName, settings.argsLabel)}</GridItem> : null, remove, classes, showNotFlag)
  }

  renderAsserterListLogicFields(fieldName, recordId, avatar, name, renderArgs, remove, classes, showNotFlag) {
    return this.renderListLogicFields(fieldName, recordId, avatar, name,
      () => (<React.Fragment>
        {renderArgs && renderArgs()}
        {!renderArgs && <GridItem xs={12}>No arguments required</GridItem>}
        {showNotFlag && <GridItem xs={12}>
          <Field
            name={`${fieldName}.not`}
            component={renderCheckbox}
            label="Negative Match"
            type="checkbox"
            data-unique={`chkTestSetConvoEditorAsserterListLogicFieldsNot_${fieldName}`}
            dense
          />
        </GridItem>}
      </React.Fragment>), remove, classes)
  }

  renderListLogicFields(fieldName, recordId, avatar, header, renderArgs, remove, classes) {

    return (<React.Fragment>
      <GridItem xs={11}>
        <ExpansionPanel data-unique={`expTestSetConvoEditor_${fieldName}`} expanded={this.state.asserterExpanded === recordId} onChange={() => this.setState({ asserterExpanded: this.state.asserterExpanded === recordId ? null : recordId })}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {avatar} {header}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <GridContainer nounset>
              {renderArgs && renderArgs()}
              {!renderArgs && <GridItem xs={12}>No arguments required</GridItem>}
            </GridContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </GridItem>
      <GridItem xs={1}>
        <div className={classes.testSetConvoEditorRemoveButton}>
          <Button justIcon round aria-label="Remove" onClick={() => remove()}>
            <ShowIcon icon="trash" />
          </Button>
        </div>
      </GridItem>
    </React.Fragment>)
  }

  renderAsserter(name, step, asserterIndex, remove, classes) {
    const { license, features, registeredComponentsData, systemSettingsData } = this.props
    if (!step || !step.asserters || !step.asserters[asserterIndex]) return null

    const llmProvider = systemSettingsData && systemSettingsData.systemsettingsclientinfo && systemSettingsData.systemsettingsclientinfo.llmProvider
    const a = step.asserters[asserterIndex]
    const ac = renderComponentAvatar(a.name, a.args, 'asserter')

    const licensedAsserterSettings = getLicensedAsserterSettings(license, features, registeredComponentsData.registeredcomponents)
    const assertersWithEditor = Object.keys(licensedAsserterSettings)

    if (assertersWithEditor.includes(a.name)) {
      if (!ASSERTER_WITH_CUSTOM_EDITOR.includes(a.name)) {
        return (<GridContainer>
          {this.renderAsserterSimpleLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings[a.name], remove, classes)}
        </GridContainer>)
      } else if (a.name === 'INTENT') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.INTENT.name, () => (
            <GridItem xs={12}>
              <Field
                name={`${name}.args[0]`}
                component={renderTextField}
                label="Expected Intent Name"
                validate={required}
                data-unique="txtTestSetConvoEditorExpectedIntentName"
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'INTENT_CONFIDENCE') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.INTENT_CONFIDENCE.name, () => (
            <GridItem xs={12}>
              <Field
                name={`${name}.args[0]`}
                component={renderIntField}
                label="Minimum Intent Confidence (between 0 and 100)"
                parse={parseInteger}
                validate={required}
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'ENTITY_CONTENT') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.ENTITY_CONTENT.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Expected Entity Name"
                  validate={required}
                  data-unique="txtTestSetConvoEditorExpectedEntityName"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Expected Entity Value"
                  validate={required}
                  data-unique="txtTestSetConvoEditorExpectedEntityValue"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'JSON_PATH') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.JSON_PATH.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="JSONPath Expression"
                  validate={composeValidators(required, jsonpath)}
                  data-unique="txtTestSetConvoEditorJsonPathExpression"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Expected Value"
                  data-unique="txtTestSetConvoEditorExpectedValue"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'RESPONSE_LENGTH') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.RESPONSE_LENGTH.name, () => (
            <GridItem xs={12}>
              <Field
                name={`${name}.args[0]`}
                component={renderIntField}
                label="Maximum response length (characters)"
                parse={parseInteger}
                validate={required}
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'CHECKINBOX') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.CHECKINBOX.name, () => (
            <React.Fragment>
              <GridItem xs={12} sm={3}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Mailbox Name"
                  validate={required}
                  helperText="As configured in the Registered Components Settings"
                />
              </GridItem>
              <GridItem xs={12} sm={8}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Assert for text"
                  helperText="Text will be searched in sender, subject and body"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'CHECKSMS') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.CHECKSMS.name, () => (
            <React.Fragment>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="SMS Inbox Name"
                  validate={required}
                  helperText="As configured in the Registered Components Settings"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Assert for text"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="Assert for Sender Number"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      }else if (a.name === 'HTTP') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.HTTP.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Base URI"
                  validate={composeValidators(required, url)}
                  data-unique="txtTestSetConvoEditorHttpBaseUri"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="HTTP Response Body - Expected Value"
                  data-unique="txtTestSetConvoEditorHttpExpectedValue"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="JSONPath Expression"
                  validate={jsonpath}
                  data-unique="txtTestSetConvoEditorHttpJsonPathExpression"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'TEXT_WER') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.TEXT_WER.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Expected Bot Message"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <FormSpy subscription={{ form: true }} render={({ form }) => (
                  <Field
                    name={`${name}.args[1]`}
                    component={renderSlider}
                    label="Acceptable Word Error Rate"
                    helperText="Range between 0% (=exact match) and 100% (= total mismatch)"
                    parse={parseInteger}
                    min={0}
                    max={100}
                    validate={composeValidators(required, minValue(0), maxValue(100))}
                    step={1}
                    form={form}
                    showInputField
                  />
                )} />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'MISUSE') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.MISUSE.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                {llmProvider && <Text bold>Used LLM Provider: {llmProvider}</Text>}
                {!llmProvider && <Text bold danger>No LLM Provider is set! Please contact with your system administrator</Text>}
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.domains`}
                  component={renderAutoSuggest}
                  label="Chatbot Domains"
                  validate={required}
                  helperText="Defines the primary domain(s) in which the Target Bot is expected to operate, ensuring it maintains focus within its designated areas."
                  data-unique="txtMisuseCheckerAsserterAllowedDomains"
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.ignoredSentences`}
                  component={renderAutoSuggest}
                  label="Ignored Sentences"
                  helperText="A list of specific sentences or phrases to be disregarded during analysis."
                  data-unique="txtMisuseCheckerAsserterIgnoredSentences"
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.forbiddenTopics`}
                  component={renderAutoSuggest}
                  label="Forbidden Topics"
                  helperText="A list of topics that the bot is strictly prohibited from discussing, even if they fall within its designated domain."
                  data-unique="txtMisuseCheckerAsserterForbiddenTopics"
                />
              </GridItem>
              <GridItem md={12}>
                <Field
                  name={`${name}.approvedTopics`}
                  component={renderAutoSuggest}
                  label="Approved Topics"
                  helperText="A list of acceptable off-domain topics that the bot is permitted to discuss without being flagged as violations. "
                  data-unique="txtMisuseCheckerAsserterApprovedTopics"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'OBJECTIVE') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.OBJECTIVE.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                {llmProvider && <Text bold>Used LLM Provider: {llmProvider}</Text>}
                {!llmProvider && <Text bold danger>No LLM Provider is set! Please contact with your system administrator</Text>}
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Objective"
                  helperText="Defines what the expected bot message should achieve."
                  validate={required}
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (a.name === 'LANGUAGE') {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`, ac, licensedAsserterSettings.LANGUAGE.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                {llmProvider && <Text bold>Used LLM Provider: {llmProvider}</Text>}
                {!llmProvider && <Text bold danger>No LLM Provider is set! Please contact with your system administrator</Text>}
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderSelect}
                  defaultValueInit={'AutoDetect'}
                  defaultValue={'AutoDetect'}
                  label="Language"
                  helperText="Defines the language in which the bot message should be delivered. Leave it on 'Auto Detect' to let the system determine the language automatically."
                  items={[
                    { key: 'AutoDetect', label: '--- Auto Detect ---' },
                    { key: 'english', label: 'English' },
                    { key: 'spanish', label: 'Spanish' },
                    { key: 'french', label: 'French' },
                    { key: 'german', label: 'German' },
                    { key: 'italian', label: 'Italian' },
                    { key: 'portuguese', label: 'Portuguese' },
                    { key: 'dutch', label: 'Dutch' },
                    { key: 'russian', label: 'Russian' },
                    { key: 'chinese', label: 'Chinese' },
                    { key: 'japanese', label: 'Japanese' },
                    { key: 'korean', label: 'Korean' },
                    { key: 'arabic', label: 'Arabic' }
                  ]}
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
      </GridContainer>)
    } else {
        return (<GridContainer>
          {this.renderAsserterListLogicFields(name, `A-${asserterIndex}`,
            renderComponentAvatar('CUSTOM', a.args, 'asserter'), 'Custom Asserter', () => (<React.Fragment>
              <GridItem xs={12} sm={3}>
                <Field
                  name={`${name}.name`}
                  component={renderTextField}
                  label="Asserter Name"
                  validate={required}
                  data-unique="txtTestSetConvoEditorAsserterName"
                />
              </GridItem>
              <GridItem xs={12} sm={8}>
                {this.renderArgsField(name, 'Asserter Arguments')}
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      }
    }
  }

  renderUserInput(change, name, step, userInputIndex, remove, classes) {
    const { testSet, license, features, registeredComponentsData } = this.props
    if (!step || !step.userInputs || !step.userInputs[userInputIndex]) return null

    const u = step.userInputs[userInputIndex]
    const uc = renderComponentAvatar(u.name, u.args)
    const licensedUserInputSettings = getLicensedUserInputSettings(license, features, registeredComponentsData.registeredcomponents)
    const userInputsWithEditor = Object.keys(licensedUserInputSettings)

    const mediaBaseDirInitial = testSet && testSet.mediaBaseDir && testSet.mediaBaseDir.split('/').filter(s => s)
    const mediaBaseDir = testSet && testSet.mediaBaseDir

    if (userInputsWithEditor.includes(u.name)) {
      if (!USERINPUT_WITH_CUSTOM_EDITOR.includes(u.name)) {
        return (<GridContainer>
          {this.renderSimpleLogicFields(name, `A-${userInputIndex}`, uc, licensedUserInputSettings[u.name], remove, classes)}
        </GridContainer>)
      } else if (u.name === 'MEDIA') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `UI-${userInputIndex}`, uc, licensedUserInputSettings.MEDIA.name, () => (<React.Fragment>
            <GridItem xs={12}>{this.renderArgsField(name, licensedUserInputSettings.MEDIA.argsLabel)}</GridItem>
            <GridItem xs={12}>
              {mediaBaseDir && <React.Fragment>
                <LinkButton data-unique={`btnTestSetOpenFileSelectionDialog_${name}`} href="#" onClick={() => this.setState({ [`showMediaFileDialog_${name}`]: true })}>
                  File/Folder Selection
                </LinkButton>
                <MediaSelectionDialog multiple allowFileSelection allowFolderSelection
                  restrictPath
                  initialPath={mediaBaseDirInitial}
                  open={!!this.state[`showMediaFileDialog_${name}`]}
                  onCancel={() => this.setState({ [`showMediaFileDialog_${name}`]: false })}
                  onOk={async ({ selectedFiles, selectedFolders }) => {
                    const args = step.userInputs[userInputIndex].args || []
                    selectedFiles.forEach(m => args.push(m.slice(mediaBaseDirInitial.length).join('/')))
                    selectedFolders.forEach(m => args.push([...m.slice(mediaBaseDirInitial.length), '*'].join('/')))
                    change(`${name}.args`, _.uniq(args))
                    this.setState({ [`showMediaFileDialog_${name}`]: false })
                  }}
                  title="Select Files"
                />
              </React.Fragment>}
            </GridItem>
            <GridItem xs={12}>
              {!mediaBaseDir && <Text warning>Base folder for attachments not set (set in Media Resources Settings)</Text>}
            </GridItem>
          </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (u.name === 'BUTTON') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `UI-${userInputIndex}`, uc, licensedUserInputSettings.BUTTON.name, () => (<React.Fragment>
            <GridItem xs={6}>
              <Field
                name={`${name}.args[0]`}
                component={renderTextField}
                label="Button Payload"
                validate={required}
                data-unique="txtTestSetConvoEditorUserButtonPayload"
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name={`${name}.args[1]`}
                component={renderTextField}
                label="Button Text"
                data-unique="txtTestSetConvoEditorUserButtonText"
              />
            </GridItem>
          </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (u.name === 'FORM') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `UI-${userInputIndex}`, uc, licensedUserInputSettings.FORM.name, () => (<React.Fragment>
            <GridItem xs={6}>
              <Field
                name={`${name}.args[0]`}
                component={renderTextField}
                label="Field Name"
                validate={required}
                data-unique="txtTestSetConvoEditorUserFieldName"
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name={`${name}.args[1]`}
                component={renderTextField}
                label="Field Value"
                data-unique="txtTestSetConvoEditorUserFieldValue"
              />
            </GridItem>
          </React.Fragment>), remove, classes)}
        </GridContainer>)
      }
    } else {
      return (<GridContainer>
        {this.renderListLogicFields(name, `UI-${userInputIndex}`,
          <SettingsIcon />, 'Other User Input', () => (<React.Fragment>
            <GridItem xs={12} sm={5}>
              <Field
                name={`${name}.name`}
                component={renderTextField}
                label="User Input Name"
                validate={required}
                data-unique="txtTestSetConvoEditorUserInputName"
              />
            </GridItem>
            <GridItem xs={12} sm={7}>
              {this.renderArgsField(name, 'User Input Arguments')}
            </GridItem>
          </React.Fragment>), remove, classes)}
      </GridContainer>)
    }
  }

  renderLogicHook(change, name, step, logicHookIndex, remove, classes) {
    const { testSetId, validPartialConvosData, license, features, registeredComponentsData } = this.props

    if (!step || !step.logicHooks || !step.logicHooks[logicHookIndex]) return null

    const l = step.logicHooks[logicHookIndex]

    const lc = renderComponentAvatar(l.name, l.args, 'logichook')
    const licensedLogicHooksSettings = getLicensedLogicHooksSettings(license, features, registeredComponentsData.registeredcomponents)
    const logicHooksWithEditor = Object.keys(licensedLogicHooksSettings).filter(ls => licensedLogicHooksSettings[ls].sender.includes(step.sender))

    const renderKeyValueEditor = (name, addButtonLabel) => {
      return <FieldArray name={name}>
      {({ fields }) => <>
        {fields.map((fieldname, index) => (
          <GridItem xs={12} key={index}>
            <GridContainer>
              <GridItem sm={5}>
                <Field
                  name={`${fieldname}.name`}
                  component={renderTextField}
                  label={`Key #${index + 1}`}
                  validate={required}
                  data-unique={`txtDialogFlowCxHeaderKey_${index}`}
                />
              </GridItem>
              <GridItem sm={5}>
                <Field
                  name={`${fieldname}.value`}
                  component={renderTextField}
                  label={`Value #${index + 1}`}
                  data-unique={`txtDialogFlowCxHeaderValue_${index}`}
                />
              </GridItem>
              <GridItem sm={1}>
                <Button onClick={() => fields.remove(index)} largeMarginTop justIcon data-unique={`btnDialogFlowCxHeaderRemove_${index}`}>
                  <ShowIcon icon="trash"/>
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        ))}
        <GridItem xs={12}>
          <Button onClick={() => fields.push({ name: '', value: '' })} data-unique="btnDialogFlowCxHeaderAdd">
            <AddIcon /> {addButtonLabel || 'Add New HTTP Header'}
          </Button>
        </GridItem>
      </>}
    </FieldArray>
    }
    const renderCapabilityEditor = (name) => {
      return <FieldArray name={name}>
        {({ fields }) => <>
          {fields.map((fieldname, index) => (
            <GridItem xs={12} key={index}>
              <GridContainer>
                <GridItem sm={7}>
                  <Field
                    name={`${fieldname}.name`}
                    component={renderTextField}
                    label={`Capability #${index + 1}`}
                    validate={required}
                    data-unique={`txtTestSetConvoEditorCapabilityEditorName_${index}`}
                  />
                </GridItem>
                <GridItem sm={4}>
                  <Field
                    name={`${fieldname}.type`}
                    component={renderSelect}
                    label="Type"
                    validate={required}
                    defaultValueInit={'STRING'}
                    data-unique={`selTestSetConvoEditorCapabilityEditorType_${index}`}
                    items={CapabilityDropdownData}
                  />
                </GridItem>
                <GridItem sm={1}>
                  <Button onClick={() => fields.remove(index)} largeMarginTop justIcon data-unique={`btnTestSetConvoEditorCapabilityEditorRemove_${index}`}>
                    <ShowIcon icon="trash"/>
                  </Button>
                </GridItem>
                <GridItem sm={11}>
                  <Condition when={`${fieldname}.type`} is="STRING">
                    <Field
                      name={`${fieldname}.stringValue`}
                      component={renderTextField}
                      label={`Capability Value`}

                      data-unique={`txtTestSetConvoEditorCapabilityEditorStringValue_${index}`}
                    />
                  </Condition>
                  <Condition when={`${fieldname}.type`} is="TEXT">
                    <Field
                      name={`${fieldname}.stringValue`}
                      component={renderTextArea}
                      rows={5}
                      label={`Capability Value`}
                      parse={value => value && unescapeJs(value)}

                      data-unique={`txtTestSetConvoEditorCapabilityEditorStringValue_${index}`}
                    />
                  </Condition>
                  <Condition when={`${fieldname}.type`} is="INT">
                    <Field
                      name={`${fieldname}.intValue`}
                      component={renderIntField}
                      label={`Capability Value`}
                      parse={parseInteger}
                      validate={composeValidators(mustBeNumber)}

                      data-unique={`intTestSetConvoEditorCapabilityEditorIntValue_${index}`}
                    />
                  </Condition>
                  <Condition when={`${fieldname}.type`} is="BOOLEAN">
                    <Field
                      name={`${fieldname}.booleanValue`}
                      component={renderCheckbox}
                      label={`Capability Value`}
                      type="checkbox"

                      data-unique={`chkTestSetConvoEditorCapabilityEditorBoolean_${index}`}
                    />
                  </Condition>
                  <Condition when={`${fieldname}.type`} is="JSON">
                    <Field
                      className="Capabilities"
                      name={`${fieldname}.jsonValue`}
                      component={renderCodeArea}
                      options={{ mode: 'application/json' }}
                      label={`Capability Value`}
                      codeFormat={prettyPrintJson}
                      validate={json}

                      data-unique={`codeTestSetConvoEditorCapabilityEditorJsonValue_${index}`}
                    />
                  </Condition>
                  <Condition when={`${fieldname}.type`} is="JS">
                    <FileSelectorField
                      name={`${fieldname}.stringValue`}
                      change={change}
                      label={`Capability Value`}

                      data-unique={`codeTestSetConvoEditorCapabilityEditorStringValue_${index}`}
                      initialPath="resources/scripts"
                      extensionFilter={['.js']}
                      restrictPath
                    />
                  </Condition>
                  <OnChange name={`${fieldname}.type`}>
                    {(value, previous) => {
                      if (value !== 'STRING' && value !== 'TEXT' && value !== 'JS') change(`${fieldname}.stringValue`, undefined)
                      if (value !== 'INT') change(`${fieldname}.intValue`, undefined)
                      if (value !== 'BOOLEAN') change(`${fieldname}.booleanValue`, undefined)
                      if (value !== 'JSON') change(`${fieldname}.jsonValue`, undefined)
                    }}
                  </OnChange>
                </GridItem>

              </GridContainer>
            </GridItem>
          ))}
          <GridItem xs={12}>
            <Button onClick={() => fields.push({ type: 'STRING' })} data-unique="btnTestSetConvoEditorCapabilityEditorAdd">
              <AddIcon /> {'Register new Capability'}
            </Button>
          </GridItem>
        </>}
      </FieldArray>
    }

    if (logicHooksWithEditor.includes(l.name)) {
      if (!LOGICHOOK_WITH_CUSTOM_EDITOR.includes(l.name) && !l.name.startsWith('CONDITIONAL_STEP')) {
        return (<GridContainer>
          {this.renderSimpleLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings[l.name], remove, classes)}
        </GridContainer>)
      } else if (l.name === 'PAUSE') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.PAUSE.name, () => (
            <GridItem xs={12}>
              <Field
                name={`${name}.args[0]`}
                component={renderIntField}
                label="Pause execution (in milliseconds)"
                parse={parseInteger}
                helperText={(step.sender === 'me' || step.sender === 'bot') ? 'Execution will be paused at the end of the conversation step' : ''}
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'WAITFORBOT') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.WAITFORBOT.name, () => (
            <GridItem xs={12}>
              <Field
                name={`${name}.args[0]`}
                component={renderIntField}
                label="Maximum wait time for bot response (in milliseconds)"
                parse={parseInteger}
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (l.name.startsWith('CONDITIONAL_STEP')) {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings[l.name].name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Condition Group ID"
                  data-unique={`txtConditionalStepGroupId`}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderCodeArea}
                  options={{ mode: 'application/json' }}
                  label={licensedLogicHooksSettings[l.name].argsLabel || 'Params as JSON Object'}
                  codeFormat={oneLinePrintJson}
                  validate={composeValidators(required, json)}
                  data-unique="codeConditionalStepParams"
                />
                {licensedLogicHooksSettings[l.name].jsonExample && <Button data-unique="btnOracleDigitalAssistantEditProfile" link
                        onClick={() => change(`${name}.args[0]`, JSON.stringify(licensedLogicHooksSettings[l.name].jsonExample))}>Insert sample</Button>}
              </GridItem>
            </React.Fragment>
          ), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'SET_SCRIPTING_MEMORY') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.SET_SCRIPTING_MEMORY.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Variable Name"
                  validate={required}
                  data-unique="txtTestSetConvoEditorLogicHookVariableName"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Value"
                  validate={required}
                  data-unique="txtTestSetConvoEditorLogicHookVariableValue"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'CLEAR_SCRIPTING_MEMORY') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.CLEAR_SCRIPTING_MEMORY.name, () => (
            <GridItem xs={6}>
              <Field
                name={`${name}.args[0]`}
                component={renderTextField}
                label="Variable Name"
                validate={required}
                data-unique="txtTestSetConvoEditorLogicHookVariableName"
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'ASSIGN_SCRIPTING_MEMORY') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.ASSIGN_SCRIPTING_MEMORY.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Variable Name"
                  validate={required}
                  data-unique="txtTestSetConvoEditorLogicHookVariableName"
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="JSONPath Expression"
                  validate={composeValidators(required, jsonpath)}
                  data-unique="txtTestSetConvoEditorLogicHookJsonExpression"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'INCLUDE') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.INCLUDE.name, () => (
            <React.Fragment>
              <GridItem xs={6}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Partial Convo Name"
                  validate={required}
                  data-unique="txtTestSetConvoEditorLogicHookPartialConvoName"
                />
              </GridItem>
              {testSetId &&
                <GridItem xs={6}>
                  <DropdownButton
                    data-unique="ddbtnTestSetConvoEditorSelectPartialConvo"
                    showFilter
                    fullWidth
                    items={(validPartialConvosData && validPartialConvosData.testsetvalidpartialconvos && validPartialConvosData.testsetvalidpartialconvos.map(a => ({
                      id: a.name,
                      name: `${a.testSet.name}/${a.name} (${a.stepCount} conversation steps)`,
                      onClick: () => {
                        change(`${name}.args[0]`, a.name)
                      }
                    }))) || []}
                  >
                    Select partial convo
                  </DropdownButton>
                </GridItem>
              }
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'SETFROMHTTPGET') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.SETFROMHTTPGET.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/get-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="JSON-Path extractor"
                  helperText="For extracting the data item out of the HTTP GET JSON response (example: $.body)"
                  validate={jsonpath}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="Attribute to set"
                  helperText="Setting Botium Core field (example: messageText)"
                  validate={required}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[3]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'SETFROMHTTPPOST') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.SETFROMHTTPPOST.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/post-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Request Body"
                  helperText={'Can contain scripting memory variables - example: { "title": "$msg($.messageText)" }'}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="JSON-Path extractor"
                  helperText="For extracting the data item out of the HTTP POST JSON response (example: $.body)"
                  validate={jsonpath}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[3]`}
                  component={renderTextField}
                  label="Attribute to set"
                  helperText="Setting Botium Core field (example: messageText)"
                  validate={required}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[4]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'SETPARAMETERSTOREFROMHTTPGET') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.SETPARAMETERSTOREFROMHTTPGET.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/get-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="JSON-Path extractor"
                  helperText="For extracting the data item out of the HTTP GET JSON response (example: $.body)"
                  validate={jsonpath}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="Parameter store variable"
                  helperText="Name of the Parameter store variable to set/update (example: $username)"
                  validate={required}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[3]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'SETPARAMETERSTOREFROMHTTPPOST') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.SETPARAMETERSTOREFROMHTTPPOST.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/post-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Request Body"
                  helperText={'Can contain scripting memory variables - example: { "title": "$msg($.messageText)" }'}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[2]`}
                  component={renderTextField}
                  label="JSON-Path extractor"
                  helperText="For extracting the data item out of the HTTP POST JSON response (example: $.body)"
                  validate={jsonpath}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={`${name}.args[3]`}
                  component={renderTextField}
                  label="Parameter store variable"
                  helperText="Name of the Parameter store variable to set/update (example: $username)"
                  validate={required}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[4]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'HTTPGET') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.HTTPGET.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/get-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[1]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'HTTPPOST') {
        return (<GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.HTTPPOST.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="URL to call"
                  helperText="Can contain scripting memory variables - example: https://my-custom-api/post-response/$msg($.messageText)"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[1]`}
                  component={renderTextField}
                  label="Request Body"
                  helperText={'Can contain scripting memory variables - example: { "title": "$msg($.messageText)" }'}
                />
              </GridItem>
              {renderKeyValueEditor(`${name}.args[2]`)}
            </React.Fragment>), remove, classes)}
        </GridContainer>)
      } else if (l.name === 'VOIP_IGNORE_SILENCE_DURATION') {
        return <GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.VOIP_IGNORE_SILENCE_DURATION.name, () => (
          <GridItem xs={12}>
            <Text>Nothing to configure</Text>
            </GridItem>
          ), remove, classes)}
          </GridContainer>
      } else if (l.name === 'VOIP_JOIN_SILENCE_DURATION') {
        return <GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.VOIP_JOIN_SILENCE_DURATION.name, () => (
            <GridItem xs={6}>
              <Field
                name={`${name}.args[0]`}
                component={renderIntField}
                label="Silence Timeout (PSST)"
                helperText="in ms"
                validate={required}
              />
            </GridItem>
          ), remove, classes)}
        </GridContainer>
      } else if (l.name === 'VOIP_CONFIDENCE_THRESHOLD') {
        return <GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.VOIP_CONFIDENCE_THRESHOLD.name, () => (
            <GridItem xs={6}>
            <Field
              name={`${name}.args[0]`}
              component={renderIntField}
              label="Confidence Threshold"
              helperText="between 0 and 1"
              validate={required}
            />
          </GridItem>
          ), remove, classes)}
        </GridContainer>
      } else if (l.name === 'ORDERED_LIST_TO_BUTTON') {
        return <GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.ORDERED_LIST_TO_BUTTON.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                <Field
                  name={`${name}.args[0]`}
                  component={renderTextField}
                  label="Regexp"
                  helperText="Regexp to seach for buttons. The default behavior is to use line starting number using regex '^\s*(\d+)\.'"
                />
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>
      } else if (l.name === 'CONNECTORPROPS') {
        return <GridContainer>
          {this.renderListLogicFields(name, `LH-${logicHookIndex}`, lc, licensedLogicHooksSettings.CONNECTORPROPS.name, () => (
            <React.Fragment>
              <GridItem xs={12}>
                {renderCapabilityEditor(`${name}.args[0]`)}
              </GridItem>
            </React.Fragment>), remove, classes)}
        </GridContainer>
      }
    } else {
      return (<GridContainer>
        {this.renderListLogicFields(name, `LH-${logicHookIndex}`,
          renderComponentAvatar('CUSTOM', null, 'logichook'), 'Custom Logic Hook', () => (<React.Fragment>
            <GridItem xs={12} sm={5}>
              <Field
                name={`${name}.name`}
                component={renderTextField}
                label="Logic Hook Name"
                validate={required}
                data-unique="txtTestSetConvoEditorLogicHookName"
              />
            </GridItem>
            <GridItem xs={12} sm={7}>
              {this.renderArgsField(name, 'Logic Hook Arguments')}
            </GridItem>
          </React.Fragment>), remove, classes)}
      </GridContainer>)
    }
  }
}

export const TestSetConvoEditor = compose(
  connect(
    state => ({ license: state.settings.license, features: state.settings.features })
  ),
  withStyles(
    (theme) => ({
      ...testsetsStyle(theme),
      ...convoStyle(theme)
    }),
    { withTheme: true },
  ),
  graphql(TESTSET_QUERY, {
    props: ({ data }) => ({
      testSet: data.testset,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.testSetId
        }
      }
    },
    skip: (props) => (!props.testSetId)
  }),
  graphql(TESTSET_VALIDUTTERANCES_QUERY, {
    props: ({ data }) => ({
      validUtterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSetId: props.testSetId
        }
      }
    },
    skip: (props) => (!props.testSetId)
  }),
  graphql(TESTSET_VALIDPARTIALCONVOS_QUERY, {
    props: ({ data }) => ({
      validPartialConvosData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSetId: props.testSetId
        }
      }
    },
    skip: (props) => !props.testSetId
  }),
  graphql(REGISTEREDCOMPONENTS_QUERY, {
    props: ({ data }) => ({
      registeredComponentsData: data,
    }),
  }),
  graphql(SYSTEMSETTINGS_CLIENT_INFO_QUERY, {
    props: ({ data }) => ({
      systemSettingsData: data,
    })
  }),
  withRouter,
  withApollo
)(TestSetConvoEditorComponent)

