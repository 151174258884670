import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import {OnChange} from 'react-final-form-listeners'
// apollo
import { Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Field from 'components/Form/OptionalField'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import ShowIcon from 'components/Icon/ShowIcon'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text'
import StepperSlider from 'components/Form/StepperSlider'

import {
  renderTextField,
  renderSelect,
  required,
  FormActionsToolbar, renderNamespaceField, renderAutoSuggest, composeValidators,
  renderSelectSuggestion
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import {
  CREATE_MISUSECHECKERPROJECT,
  DeleteMisuseCheckerListsFromCache,
  GENERATE_MISUSE_DISTRACTION_TOPICS,
  RefetchMisuseCheckerProjectQueries
} from './gql'
import {CHATBOTS_DROPDOWN_QUERY} from '../Chatbots/gql'
import { RefetchTestSetQueries } from '../TestSets/gql'
import { safeGetNamespaceFilteredList } from '../helper.jsx'

import { hasPermission } from 'botium-box-shared/security/permissions'
import GeneralTestProjectsEmbeddedTable from '../TestProjects/GeneralTestProjectsEmbeddedTable.jsx'
import {validateMisuseCheckerProjectNameUnique} from './validators'
import {RenderSkeletonChipsGenerate} from 'components/Skeleton/skeletonHelper'

class MisuseCheckerProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newProjectExpanded: false,
      emptyMisuseCheckerProject: {
        name: 'New Misuse Project',
        namespace: props.namespace.selected ? props.namespace.selected.name : null,
        chatbot: null,
        distractionTopics: ['Violence', 'Drugs'],
        numberOfCycles: 2
      },
      generatedDistractionTopics: [],
      distractionTopicsGenerating: false
    }
    this.generationIndex = 0
  }

  renderStartForm() {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, chatbotsData, client } = this.props
    const { generatedDistractionTopics, distractionTopicsGenerating } = this.state
    let startProject = true

    return (
      <Mutation
        mutation={CREATE_MISUSECHECKERPROJECT}
        refetchQueries={[
          ...RefetchMisuseCheckerProjectQueries(),
          ...RefetchTestSetQueries()
        ]}
        update={DeleteMisuseCheckerListsFromCache}
      >
        {(createMisuseCheckerProject, { loading, error }) => {
          return <Form
            onSubmit={async (values, form) => {
              try {
                const res = await createMisuseCheckerProject({
                  variables: {
                    input: {
                      name: values.name,
                      namespace: values.namespace,
                      chatbotId: values.chatbotId,
                      configuration: {
                        allowedDomains: { set: values.allowedDomains || [] },
                        approvedTopics: { set: values.approvedTopics || [] },
                        confusedSentences: { set: values.confusedSentences || [] },
                        forbiddenTopics: { set: values.forbiddenTopics || [] },
                        ignoredSentences: { set: values.ignoredSentences || [] },
                        distractionTopics: { set: values.distractionTopics || []},
                        numberOfCycles: values.numberOfCycles || 1,
                      }
                    },
                    start: startProject
                  },
                })
                form.initialize(this.state.emptyMisuseCheckerProject)
                setAlertSuccessMessage('Misuse Project registered')
                history.push(`/misusecheck/projects/view/${res.data.createMisuseCheckerProject.id}/content`)
              } catch (error) {
                setAlertErrorMessage(`Misuse Project registration failed`, error)
              }
            }}
            initialValues={this.state.emptyMisuseCheckerProject}
            render={({
                       handleSubmit,
                       submitting,
                       invalid,
                       values,
                       form: { change }
                     }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer container justify="center" spacing={8}>
                  <GridItem item md={4}>
                    <Field
                        name="name"
                        component={renderTextField}
                        label="Misuse Project Name"
                        validate={composeValidators(required, async (value) => value ? validateMisuseCheckerProjectNameUnique(this.props.client, null, value) : null)}
                        data-unique="txtMisuseCheckerProjectRegisterName"
                      />
                  </GridItem>
                  <GridItem item md={4}>
                    <Field
                      name="namespace"
                      component={renderNamespaceField}
                      forWrite
                      label="Namespace"
                      data-unique="txtMisuseCheckerProjectNamespace"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer container justify="center" spacing={8}>
                  <GridItem item md={4}>
                    <Field
                        name="chatbotId"
                        component={renderSelect}
                        label="Select a Chatbot"
                        data-unique={`selMisuseCheckerProjectRegisterChatbot`}
                        validate={required}
                        items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => {
                          return {
                            key: c.id,
                            chatbot: c
                          }
                        })
                        }
                      />
                  </GridItem>
                  <GridItem item md={4}>
                    <Field
                      name="allowedDomains"
                      component={renderAutoSuggest}
                      label="Chatbot Domains"
                      validate={required}
                      helperText="Defines the primary domain(s) in which the Target Bot is expected to operate, ensuring it maintains focus within its designated areas."
                      data-unique="txtMisuseCheckerProjectAllowedDomains"
                    />
                  </GridItem>
                  <OnChange name="allowedDomains">
                    {async (value, previous) => {
                      if(value.length > 0) {
                        try {
                          this.generationIndex++
                          this.setState({generatedDistractionTopics: [], distractionTopicsGenerating: true})
                          const {data} = await client.query({
                            query: GENERATE_MISUSE_DISTRACTION_TOPICS,
                            variables: {domains: value},
                            fetchPolicy: 'network-only'
                          })
                          const generateMisuseDistractionTopics = data.generateMisuseDistractionTopics || []
                          if(generateMisuseDistractionTopics.length === 0) {
                            generateMisuseDistractionTopics.push('Violence', 'Drugs')
                          }
                          this.generationIndex++
                          this.setState({generatedDistractionTopics: generateMisuseDistractionTopics, distractionTopicsGenerating: false})
                          change('distractionTopics', [generateMisuseDistractionTopics[0]])
                        } catch (e) {
                          console.error(e)
                          this.generationIndex++
                          this.setState({generatedDistractionTopics: ['Violence', 'Drugs'], distractionTopicsGenerating: false})
                          change('distractionTopics', ['Violence'])
                        }
                      } else {
                        this.setState({generatedDistractionTopics: []})
                        change('distractionTopics', [])
                        this.generationIndex++
                      }
                    }}
                  </OnChange>
                </GridContainer>
                <GridContainer container justify="center" spacing={8}>
                  <GridItem item md={8} >
                    <Card misuse>
                      <CardBody>
                        <GridContainer>
                          <GridItem item md={12} largeMargin>
                            <Text header misuse><ShowIcon custom icon="AI" />Distraction Topics</Text>
                            <Text subheader>{values.allowedDomains && values.allowedDomains.length > 0 
                              ? (generatedDistractionTopics.length > 0 
                                  ? 'The AI has identified ' + generatedDistractionTopics.length + ' distraction topics' 
                                  : 'Generation in progress...')
                              : 'Enter your Chatbot domains so we can generate distraction topics.'
                            }</Text>
                          </GridItem>
                          <GridItem item md={12} largeMargin>
                            <Field
                              key={'index' + this.generationIndex}
                              name="distractionTopics"
                              component={renderSelectSuggestion}
                              allvalue={generatedDistractionTopics}
                              validate={required}
                              data-unique="txtMisuseCheckerProjectDistrationTopics"
                              disableTooltip={true}
                            />
                            {distractionTopicsGenerating && <RenderSkeletonChipsGenerate />}

                          </GridItem>
                          <GridItem item md={12} center>
                            {values.allowedDomains && values.allowedDomains.length > 0 &&
                            <Button link misuse onClick={async () => {
                              try {
                                this.setState({distractionTopicsGenerating: true})
                                const {data} = await client.query({
                                  query: GENERATE_MISUSE_DISTRACTION_TOPICS,
                                  variables: {
                                    domains: values.allowedDomains,
                                    distractionTopicsToIgnore: generatedDistractionTopics
                                  },
                                  fetchPolicy: 'network-only'
                                })
                                const generateMisuseDistractionTopics = data.generateMisuseDistractionTopics || []
                                this.generationIndex++
                                this.setState({generatedDistractionTopics: [...generatedDistractionTopics, ...generateMisuseDistractionTopics], distractionTopicsGenerating: false})
                              } catch (e) {
                                console.error(e)
                                this.setState({distractionTopicsGenerating: false})
                              }
                            }}>Generate more</Button>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer container justify="center" spacing={8}>
                  <GridItem item md={8} >
                    <Card noPadding noBorder>
                      <CardBody>
                        <Text header >Misuse Attempts</Text>
                          <StepperSlider
                            key={values?.numberOfCycles}
                            color="#5B29ED"
                            value={values?.numberOfCycles ?? 2}
                            onChange={(newValue) => {
                              if (newValue !== undefined) {
                                change('numberOfCycles', newValue)
                              }
                            }}
                          />
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <FormActionsToolbar
                      rightButtons={<>
                        <Button
                          secondary
                          disabled={submitting}
                          onClick={() => this.setState({ newProjectExpanded: false })}
                          data-unique="btnTestProjectRegisterCancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          data-unique="btnMisuseCheckerProjectRegisterSave"
                          onClick={() => {
                            startProject = false
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                        >
                          {submitting && <LoadingIndicator alt />}
                          Save
                        </Button>
                        <Button
                          disabled={submitting || invalid}
                          data-unique="btnMisuseCheckerProjectRegisterSaveAndStart"
                          onClick={() => {
                            startProject = true
                            handleSubmit()
                            this.setState({ newProjectExpanded: false })
                          }}
                        >
                          {submitting && <LoadingIndicator alt />}
                          Start
                        </Button>
                      </>}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
          }
        }
      </Mutation>
    )
  }

  render() {
    if (this.state.newProjectExpanded) {
      return this.renderStartForm()
    }

    return this.renderTableMode()
  }

  renderTableMode() {
    const { user, classes } = this.props
    return (
      <GridContainer noPadding>
        <GridItem xs={12} right>
          <Button noMargin
                  onClick={() => this.setState({ newProjectExpanded: true })}
                  data-unique="btnQuickstartGoToTestProjects"
                  disabled={!hasPermission(user, 'TESTPROJECTS_CREATE')}
          >
            <ShowIcon icon="plus" />
            Create Test
          </Button>
        </GridItem>
        <GridItem xs={12} className={classes.projectsListEmbeddedTable}>
          <GeneralTestProjectsEmbeddedTable projectType="misusechecker" name="MisuseCheckerProjects" disableFooter={false}  />
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testprojectsStyle),
  connect(
    state => ({ user: state.token.user, settings: state.settings, license: state.settings.license, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  withApollo
)(MisuseCheckerProjects)
