import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import dividerStyle from 'assets/jss/material-dashboard-react/components/dividerStyle.jsx'
import classNames from 'classnames'

function Divider({ ...props }) {
  const { classes, orientation, dense, text, secondary, dividermdnone, dividerlgnone, small, noMarginTop, noMarginBottom, dividerXSmall} = props

  return <div className={classNames({
      [classes.divider]: true, 
      [classes.dividerVertical]: orientation === 'vertical',
      [classes.dividerDense]: dense,
      [classes.dividermdnone]: dividermdnone,
      [classes.dividerlgnone]: dividerlgnone,
      [classes.dividerSmall]: small,
      [classes.noMarginTop]: noMarginTop,
      [classes.noMarginBottom]: noMarginBottom,
      [classes.dividerXSmall]: dividerXSmall
  })}>
      {text &&<div  className={classNames({
                [classes.dividerTextContainer]: true,
                [classes.dividerTextContainerVertical]: orientation === 'vertical'
            })}>
            <div className={classNames({
                [classes.dividerText]: true,
                [classes.dividerTextSecondary]: secondary
            })}>{text}</div>
      </div>}
      
  </div>
}

export default withStyles(dividerStyle)(Divider)