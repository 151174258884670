//import { nonExecutableDefinitionMessage } from "graphql/validation/rules/ExecutableDefinitions"

const dividerStyle = theme => ({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.tableRow,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 25,
    marginBottom: 25
  },
  dividerVertical: {
    width: 1,
    height: '100%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 25,
    marginRight: 25
  },
  dividerDense: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
  dividerSmall: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 0,
    marginRight: 0
  },
  dividerXSmall: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0
  },
  noMarginTop: {
    marginTop: 0
  },
  noMarginBottom: {
    marginBottom: 0
  },
  dividermdnone: {
    '@media screen and (min-width: 1280px)' :{
      display: 'none',
    }
  },
  dividerlgnone: {
    '@media screen and (max-width: 1279px)' :{
      display: 'none',
    }
  },
  dividerTextContainer: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    top: -12
  },
  dividerTextContainerVertical: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: 18
  },
  dividerText: {
    backgroundColor: theme.colors.dividerOrbg,
    display: 'inline',
    padding: '0px 10px',
    color: theme.colors.primary
  },
  dividerTextSecondary: {
    backgroundColor: `${theme.colors.dividerOrbgsecoundary} !important`,
  },
})

export default dividerStyle
