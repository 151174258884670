import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle.jsx'
import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'
import dashboardStyle from './dashboardStyle'
import { whiteColor } from 'assets/jss/material-dashboard-react'

const testsessionsStyle = theme => {
  return {
    ...convoStyle(theme),
    ...tableStyle,
    ...cardTitleStyle,
    ...typographyStyle,
    testcaselistitem: {
      padding: 0,
      margin: 0,
    },
    testcaselistnested: {
      paddingLeft: theme.spacing.unit,
    },
    testtypefilter: {
      marginLeft: 9,
      marginBottom: 0,
      marginTop: 0,
      '& label':{
        padding: '0 10px 0 0'
      },
      '& span':{
        padding: 2
      }
    },
    statustypefilter: {
      marginLeft: 9,
      marginBottom: 0,
      marginTop: 0,
      '& label':{
        marginTop: 0,
        padding: '0 10px 0 0'
      },
      '& span':{
        padding: 2
      }
    },
    testtypefilterall: {
      borderRight: `1px solid ${theme.colors.primary}`
    },
    filterswitch: {
      color: `${theme.colors.primary} !Important`,
      fontWeight: 400,
      '& span': {
        color: `${theme.colors.primary} !Important`,
      }
    },
    regressiontext: {
      display: 'inline-block',
      color: `${theme.colors.common.primary}`,
      backgroundColor: theme.colors.common.primarydark,
      padding: '5px 10px',
      borderRadius: 5,
      fontSize: 12,
      '& span': {
        color: `${theme.colors.common.primary}`,
      }
    },

    e2etext: {
      display: 'inline-block',
      color: whiteColor,
      backgroundColor: theme.colors.e2etext,
      padding: '5px 10px',
      borderRadius: 5,
      fontSize: 12,
      '& span': {
        color: whiteColor,
      }
    },
    nlptext: {
      display: 'inline-block',
      color: whiteColor,
      backgroundColor: theme.colors.nlptext,
      padding: '5px 10px',
      borderRadius: 5,
      fontSize: 12,
      '& span': {
        color: whiteColor,
      }
    },
    performancetext: {
      display: 'inline-block',
      color: theme.colors.common.primary,
      backgroundColor: theme.colors.performancetext,
      padding: '5px 10px',
      borderRadius: 5,
      fontSize: 12,
      '& span': {
        color: theme.colors.common.primary,
      }
    },
    securitytext: {
      display: 'inline-block',
      color: whiteColor,
      backgroundColor: theme.colors.securetext,
      padding: '5px 10px',
      borderRadius: 5,
      fontSize: 12,
      '& span': {
        color: whiteColor,
      }
    },
    gdprtext: {
      display: 'inline-block',
      color: whiteColor,
      backgroundColor: theme.colors.gdprtext,
      padding: '5px 10px',
      borderRadius: 5,
      fontWeight: 400,
      fontSize: 12,
      '& span': {
        color: whiteColor,
      }
    },
    factcheckertext: {
      display: 'inline-block',
      color: `${theme.colors.common.primary}`,
      backgroundColor: theme.colors.factcheckertext,
      padding: '5px 10px',
      borderRadius: 5,
      fontWeight: 400,
      fontSize: 12,
      whiteSpace: 'nowrap',
      '& span': {
        color: `${theme.colors.common.primary}`,
      }
    },
    misusecheckertext: {
      display: 'inline-block',
      color: '#fff',
      backgroundColor: theme.colors.misusecheckertext,
      padding: '5px 10px',
      borderRadius: 5,
      fontWeight: 400,
      fontSize: 12,
      '& span': {
        color: `${theme.colors.common.primary}`,
      }
    },
    logmonitoringtext: {
      display: 'inline-block',
      color: `${theme.colors.common.primary}`,
      backgroundColor: theme.colors.logmonitoringtext,
      padding: '4px 10px',
      borderRadius: 5,
      fontWeight: 400,
      marginLeft: 8,
      '& span': {
        color: `${theme.colors.common.primary}`,
      },
      '& svg': {
        height: '1.1em'
      }
    },
    scheduledTypeFlag: {
      display: 'inline-block',
      color: `${theme.colors.common.primary}`,
      backgroundColor: theme.colors.logmonitoringtext,
      padding: '4px 10px',
      borderRadius: 5,
      fontWeight: 400,
      '& span': {
        color: `${theme.colors.common.primary}`,
      },
      '& svg': {
        height: '1.1em'
      }
    },
    scheduledType: {
      margin: '0 8px 5px 0',
    },
    resultListTestType: {
      display: 'inline-block',
      alignItems: 'center',
      margin: '0 5px 5px 0',
    },
    
    justifyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 6
    },
    overviewCard: {
      position: 'absolute',
      right: 15,
      top: 15,
    },
    cardLink: {
      cursor: 'pointer!Important',
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}`,
        borderRadius: 6,
      },
    },
    pieposition: {
      paddingBottom: '10px!Important',
      '&  svg': {
        marginTop: '-17px'
      }
    },
    trendnumberpos: {
      fontSize: 16,
      fontWeight: 500,
      position: 'relative',
      left: 5,
      textDecoration: 'none!Important',
      color: `${theme.colors.primary}!Important`
    },
    rootwidth: {
      width: '100%!Important',
    },
    rootwidthshort: {
      width: '65%!Important',
    },
    primaryText: {
      '& span':{
        color: theme.colors.primary,
      },
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}`,
        background: 'transparent',
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: 6
      },
    },
    barheight: {
      height: 15,
      borderRadius: 50
    },
    barbgheight: {
      height: 15,
      borderRadius: 50
    },
    valuebuble: {
      backgroundColor: theme.colors.common.secondary,
      color: theme.colors.common.primary,
      fontWeight: 'bold',
      marginLeft: 14,
      marginRight: 4,
      borderRadius: 15,
      padding: '2px 8px 2px 8px'
    },
    valuebubleempty: {
      backgroundColor: 'transparent',
      color: 'transparent',
      fontWeight: 'bold',
      marginLeft: 14,
      marginRight: 4,
      borderRadius: 15,
      padding: '2px 8px 2px 8px'
    },
    dropdownButtonPosition: {
      position: 'relative',
      top: 16,
    },
    tablePosition: {
      position: 'relative',
    },
    clearButton: {
      position: 'relative',
      top: '-8px',
      left: '-15px'
    },
    navlinkPosition: {
      marginBottom: 2
    },
    namespace: {
      border: `1px solid ${theme.colors.borderColorhover} !important`,
      borderRadius: 5,
      padding: '3px 10px',
      position: 'relative',
      display: 'inline-block'
    },
    namespaceempty: {
      display: 'none',
    },
    tableNamespace: {
      border: `1px solid ${theme.colors.borderColorhover} !important`,
      borderRadius: 5,
      padding: '3px 10px',
      position: 'relative',
      marginTop: 20
    },
    tableDate: {
      display: 'block',
      marginTop: 3
    },
    tableAction: {
      position: 'relative',
      top: 10
    },
    resultname: {
      
    },
    projectlink: {
      position: 'relative',
      top: -7,
      fontSize: 12,
      display: 'table-caption',
      color: theme.colors.borderColorhover,
      fontWeight: 'uppercase',
      borderRadius: 6,
      '& span':{
        borderRadius: 5,
        padding: '5px 10px',
        backgroundColor: theme.colors.borderColorhover,
        color: `${theme.colors.primaryInverse} !Important`,
        marginLeft: 30,
      },
      '& svg': {
        position: 'relative',
        top: 17
      },
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}!Important`,
        borderRadius: 6,
      },
    },
    resultListLink: {
      display: 'flex',
      textDecoration: 'underline',
      '&:hover':{
        textDecoration: 'none',
      }
    },
    resultListContainer: {
      width: '-webkit-fill-available!Important'
    },
    embeddedTable: {
      '&.table': {
        '& td': {
          padding: '0',
        },
      },
      '& td': {
        //verticalAlign: 'top!Important',
        '&:last-child': {
          paddingRight: '1px!Important',
        },
      },
    },
    cardLinkConnections: {
      display: 'flex',
      '&:focus-visible': {
        border: `2px solid ${theme.colors.visibleborderColor}!Important`,
        borderRadius: 6
      }
    },
    statusIcon: {
      textAlign: 'center',
      paddingTop: 15,
    },
    textLeftBorderGreen: {
      borderLeft: `2px solid ${theme.colors.common.success}`
    },
    textLeftBorderRed: {
      borderLeft: `2px solid ${theme.colors.common.danger}`
    },
    textLeftBorderYellow: {
      borderLeft: `2px solid ${theme.colors.common.warning}`
    },
    textLeftBorderLightGreen: {
      borderLeft: `2px solid ${theme.colors.common.lowRisk}`
    },
    textLeftBorderOrange: {
      borderLeft: `2px solid ${theme.colors.common.secondary}`
    },
    textLeftBorderBlue: {
      borderLeft: `2px solid ${theme.colors.factcheckertext}`
    },
    textLeftBorderDefault: {
      borderLeft: `2px solid ${theme.colors.borderColor}`
    },
    textSmall: {
      fontSize: 13
    },
    projectLink: {
      textDecoration: 'underline'

    },
    projectLastChange: {
      display: 'inline-block'
    },
    testStatusButton: {
      top: 16,
      position: 'relative'
    },
    testStatusButtonPosition: {
      position: 'relative',
      top: 16,
    },
    filtersPosition: {
      margin: '31px -218px 0 0'
    },
    rightPadding: {
      paddingRight: 15
    },
    resultIconPositionNavLink: {
      display: 'inline-block',
      position: 'relative',
      top: '-8px',
      marginRight: 5
    },
    resultListResult: {
      paddingLeft: 15,
    },
    newResultIcon: {
      display: 'inline-block',
    },
    NewResultStatsTextLabel: {
      minWidth: 80,
    },
    resultsTable: {
      
    },
    // misuse
    percentPosition: {
      position: 'absolute', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      textAlign: 'center',
      cursor: 'pointer'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    showConversationText: {
      display: 'flex',
      alignItems: 'center',
    },
    '@media (max-width: 1920px)': {
      resultname: {
        inlineSize: '380px',
      }
    },
    '@media (max-width: 1536px)': {
      resultname: {
        inlineSize: '280px',
      }
    },
    '@media (max-width: 1366px)': {
      resultname: {
        inlineSize: '240px',
      }
    },
    '@media (max-width: 1280px)': {
      resultname: {
        inlineSize: '220px',
      },
      col1: {
        order: 2,
      },
      col2: {
        order: 1,
      }
    },
    '@media (max-width: 1195px)': {
      NewResultStatsTextLabel: {
        minWidth: 50,
      },
    },
    ...dashboardStyle(theme)
  }
}

export default testsessionsStyle
